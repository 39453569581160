import React, { useState } from "react";

//images
import web1 from "../../../../../../assets/images/webinars/web.jpg";
import styled from "styled-components";
import WebinarRegisterModal from "../../../../../modals/WebinarRegisterModal";

export default function UpcomingWebinars({ getData }) {
  const [modal, setmodal] = useState(false);
  const [selectedWebinar, setSelectedWebinar] = useState("");

  const handleModalOpen = (id) => {
    setSelectedWebinar(id);
    setmodal(true);
  };

  return (
    <Container>
      <TopBar>
        <Title>Upcoming Webinars</Title>
        <Arrows>
          <Arrow>
            <i class="ri-arrow-left-s-line"></i>
          </Arrow>
          <Arrow>
            <i class="ri-arrow-right-s-line"></i>
          </Arrow>
        </Arrows>
      </TopBar>
      <WebCards>
        {getData.map(
          (item, index) =>
            !item.is_completed && (
              <WebCard>
                <ImageContainer>
                  <img src={item.thumbnail} alt="" />
                </ImageContainer>
                <DetailSec>
                  <Text>{item.title}</Text>
                  <Bottom>
                    <Sec>
                      <Duration>
                        <Icon>
                          <i class="ri-time-line"></i>
                        </Icon>
                        <Label>8 hours</Label>
                      </Duration>
                      <Duration>
                        <Date>
                          <Icon>
                            <i class="ri-calendar-line"></i>
                          </Icon>
                          <Label>{item.date},</Label>
                        </Date>
                        <Label>{item.time}</Label>
                      </Duration>
                    </Sec>
                    <Sec>
                      <Duration>
                        <Icon className="circle">
                          <i class="ri-checkbox-blank-circle-fill"></i>
                        </Icon>
                        <Span>{item.required_for}</Span>
                      </Duration>
                      <Register onClick={() => handleModalOpen(item?.id)}>
                        Register Now
                      </Register>
                    </Sec>
                  </Bottom>
                </DetailSec>
              </WebCard>
            )
        )}
      </WebCards>
      <WebinarRegisterModal modal={modal} setModal={setmodal} id={selectedWebinar} />
    </Container>
  );
}

const Container = styled.div`
  margin-top: 50px;
`;
const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Title = styled.h3`
  color: #0a033c;
  font-size: 24px;
`;
const Arrows = styled.div`
  display: flex;
  gap: 10px;
`;
const Arrow = styled.div`
  width: 50px;
  height: 50px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  color: var(--blue);
  align-items: center;
  cursor: pointer;
  background: #fff;
`;
const WebCards = styled.div`
  margin-top: 50px;
  @media (max-width: 480px) {
    margin-top: 20px;
  }
`;
const WebCard = styled.div`
  display: flex;
  background-color: #fff;
  padding: 20px;
  gap: 20px;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;
const ImageContainer = styled.div`
  width: 20%;
  img {
    display: block;
    width: 100%;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;
const DetailSec = styled.div`
  width: 80%;
  @media (max-width: 480px) {
    width: 100%;
  }
`;
const Text = styled.div`
  color: #565757;
  margin-bottom: 20px;
  font-family: "ubuntu-medium";
`;
const Bottom = styled.div``;
const Sec = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #696984;
  padding-bottom: 10px;
  &:last-child {
    border-bottom: 1px solid transparent;
    padding-top: 10px;
  }
`;
const Duration = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;
const Icon = styled.div`
  color: #565757;
  &.circle {
    color: #ffb800;
    font-size: 10px;
  }
`;
const Date = styled.div`
  color: #565757;
  display: flex;
  align-items: center;
  gap: 5px;
`;
const Label = styled.div`
  color: #565757;
  font-size: 14px;
`;
const Register = styled.div`
  color: var(--blue);
  cursor: pointer;
`;
const Span = styled.div`
  font-size: 14px;
  color: #153e7eec;
`;
