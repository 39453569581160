import React, { useRef } from "react";

//packages
import Swal from "sweetalert2";
import styled from "styled-components";

//imports
import {
  entry_program_canada,
  requirements,
} from "../../../../../general/commonArrays";
import Title from "../canada/titles/Title";
import DescriptionText from "../canada/titles/DescriptionText";
import SubTitle from "../canada/titles/SubTitle";

//images
import bluepoint from "../../../../../../assets/images/migrate/bluepoint.png";
import { accountsConfig } from "../../../../../../axiosConfig";

export default function Overview() {
  const formRef = useRef();

  //sweet alert for success modal
  const showSuccessAlert = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Success",
      showConfirmButton: false,
      html: "<p>Submitted successfully</p>",
      timer: 2500,
      iconColor: "#1eb300",
    });
  };

  //sweet alert for error modal
  const showErrorAlert = () => {
    Swal.fire({
      position: "center",
      icon: "error",
      title: "Something went wrong",
      showConfirmButton: false,
      html: "<p>Something went wrong</p>",
      timer: 2500,
      iconColor: "#ff0000",
    });
  };

  //submit contact details
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current);
    // submiting the form
    accountsConfig.post("study/contact-us/", formData).then((res) => {
      const { data, StatusCode } = res.data.app_data;

      if (StatusCode === 6000) {
        e.target.reset();
        showSuccessAlert();
      } else {
        showErrorAlert();
      }
    });
  };

  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <Left>
            <Title label="Australia Visitor Visa Overview" />
            <SubTitle label="Introduction:" />
            <DescriptionText label="The Australian visitor visa subclass 600 is a temporary visa for tourism, family visits, and short-term activities. It can be applied for from abroad or within Australia, with no embassy interview required." />
            <SubTitle label="Permitted Activities:" />
            <Points>
              {requirements.map((obj, index) => (
                <CoverPoint key={index}>
                  <ICon>
                    <img src={bluepoint} alt="circle" />
                  </ICon>
                  <CoverTextPoint>
                    <Span>{obj.title} </Span>
                  </CoverTextPoint>
                </CoverPoint>
              ))}
            </Points>
            <SubTitle label="Restricted Activities:" />
            <Points>
              {requirements.slice(0, 3).map((obj, index) => (
                <CoverPoint key={index}>
                  <ICon>
                    <img src={bluepoint} alt="circle" />
                  </ICon>
                  <CoverTextPoint>
                    <Span>{obj.title} </Span>
                  </CoverTextPoint>
                </CoverPoint>
              ))}
            </Points>
            <SubTitle label="Eligibility:" />
            <DescriptionText label="Check eligibility online via Home Affairs, Australia. Some passport holders, like China (approved tour group), may apply." />
            <SubTitle label="Subtypes:" />
            <Points>
              {requirements.slice(0, 3).map((obj, index) => (
                <CoverPoint key={index}>
                  <ICon>
                    <img src={bluepoint} alt="circle" />
                  </ICon>
                  <CoverTextPoint>
                    <Span>{obj.title} </Span>
                  </CoverTextPoint>
                </CoverPoint>
              ))}
            </Points>
            <SubTitle label="Exemptions:" />
            <DescriptionText label="Some passport holders eligible for Electronic Travel Authority (ETA) don't need a visitor visa." />
            <SubTitle label="Visa Validity:" />
            <DescriptionText label="Valid for up to 3 years. Multiple entries allowed." />
            <SubTitle label="Documentation:" />
            <DescriptionText label="Valid passport, ID (if available), photo of the applicant. Additional documents on financial support, employment, and travel itinerary may be required." />
            <SubTitle label="Application Process (via iVisa):" />
            <Points>
              {requirements.slice(0, 3).map((obj, index) => (
                <CoverPoint key={index}>
                  <ICon>
                    <img src={bluepoint} alt="circle" />
                  </ICon>
                  <CoverTextPoint>
                    <Span>{obj.title} </Span>
                  </CoverTextPoint>
                </CoverPoint>
              ))}
            </Points>
            <SubTitle label="Processing Time:" />
            <DescriptionText label="Tourist Visa: 25% in less than a day, 90% in 37 days. Business Visa: 25% in less than a day, 90% in 25 days. Sponsored Family Visa: 25% in 28 days, 90% in 50 days" />
          </Left>
          <Right>
            <Title label="Australia PR (Permanent Residency) Overview" />
            <Points>
              {entry_program_canada.map((obj, index) => (
                <CoverPoint key={index}>
                  <ICon>
                    <img src={bluepoint} alt="circle" />
                  </ICon>
                  <CoverTextPoint>
                    <Span>{obj.title} </Span>
                  </CoverTextPoint>
                </CoverPoint>
              ))}
            </Points>
            <SubTitle label="Proof of Permanent Residence:" />
            <DescriptionText label="Use VEVO for status verification." />

            <SubTitle label="Travel Facility Expiry Reminder (TFER):" />
            <DescriptionText label="Receive TFER emails 60 days before expiry for specific visa sub-holders." />

            <SubTitle label="Visa Options:" />
            <DescriptionText label="Explore Family, Business/Investor, Workstream, Retirement, Former Resident, Global Talent, and Humanitarian visas." />

            <SubTitle label="Points Requirement:" />
            <DescriptionText label="Score at least 65 points on the Points Grid based on age, English proficiency, work and education experience, niche skills, regional study, state sponsorship, and more." />
            <DescriptionText label="Consider professional assistance for a smoother application process. Act now to secure your PR or delegate the process to ensure timely completion." />
            {/* <Form onSubmit={(e) => handleFormSubmit(e)} ref={formRef}>
              <SubTitle label="Send a message:" />
              <InputsDiv>
                <InputCover className="name">
                  <Label>Name</Label>
                  <Input
                    placeholder="Your name"
                    type="text"
                    name="name"
                    required
                  />
                </InputCover>

                <InputCover className="phone">
                  <Label>Phone Number</Label>
                  <Input
                    placeholder="Your number"
                    type="number"
                    name="phone"
                    required
                  />
                </InputCover>

                <InputCover className="email">
                  <Label htmlFor="email">Email</Label>
                  <Input
                    id="email"
                    placeholder="Email"
                    type="email"
                    required
                    name="email"
                  />
                </InputCover>

                <InputCover className="message">
                  <Label>Message</Label>
                  <TextFeild
                    placeholder="Message"
                    cols={2}
                    rows={5}
                    name="message"
                  />
                </InputCover>
              </InputsDiv>
              <SubmiBtn>Submit</SubmiBtn>
            </Form> */}
          </Right>
        </Content>
      </Wrapper>
    </Container>
  );
}
const Container = styled.div`
  padding-top: 80px;
  @media (max-width: 480px) {
    padding-top: 40px;
  }
`;
const Wrapper = styled.div``;
const Content = styled.div`
  display: flex;
  gap: 50px;
  @media (max-width: 1080px) {
    display: block;
  }
`;
const Right = styled.div`
  width: 50%;
  @media (max-width: 1080px) {
    width: 100%;
  }
`;
const Left = styled.div`
  width: 50%;
  @media (max-width: 1080px) {
    width: 100%;
  }
`;

const Points = styled.div`
  &.flex {
    display: flex;
    flex-wrap: wrap;
  }
  margin-bottom: 30px;
`;
const CoverPoint = styled.div`
  display: flex;
  gap: 5px;
  align-items: flex-start;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  &.flex {
    width: 50%;
  }
`;
const ICon = styled.div``;
const CoverTextPoint = styled.div`
  display: flex;
  gap: 5px;
`;
const TextPoint = styled.h4`
  font-size: 18px;
`;
const Span = styled.p`
  color: #2b2b2b;
  line-height: 26px;
`;
const Form = styled.form`
  /* padding: 30px; */
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 25px;
  @media screen and (max-width: 580px) {
    padding: 12px;
  }
`;

const InputsDiv = styled.div`
  display: grid;
  gap: 25px;
  grid-template-areas:
    " name name"
    "phone email"
    "message message";

  @media screen and (max-width: 580px) {
    grid-template-areas:
      "name name"
      "phone phone"
      "email email"
      "message message";
  }
`;

const InputCover = styled.div`
  border: 1.5px solid #cbcbcb;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;

  &.name {
    grid-area: name;
  }

  &.email {
    grid-area: email;
  }

  &.phone {
    grid-area: phone;
  }

  &.message {
    grid-area: message;
  }
`;

const Heading = styled.div`
  color: #565757;
  font-size: 21px;
  font-style: normal;
  line-height: normal;
  font-family: "ubuntu-medium";
`;

const Label = styled.label`
  color: #5e6366;
  font-size: 13px;
  font-style: normal;
  line-height: normal;
  position: relative;
  cursor: pointer;

  &.role-select {
    &::after {
      content: "";
      position: absolute;
      top: 5px;
      right: 0;
      background-size: contain;
      width: 25px;
      height: 30px;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }

  &.cv {
    &::after {
      content: "";
      position: absolute;
      top: 5px;
      right: 0;
      background-size: contain;
      background-repeat: no-repeat;
      width: 25px;
      height: 30px;
      cursor: pointer;
    }
  }

  &.cv-label {
    color: #abafb1;
    width: max-content;
    border-bottom: 1px solid #abafb1;
    cursor: pointer;
  }
`;

const Input = styled.input`
  border: none;
  outline: none;
  padding: 0;
  &::placeholder {
    color: #abafb1;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    font-family: "ubuntu-regular";
    padding: 0;
  }
`;

const SubmiBtn = styled.button`
  width: 40%;
  background-color: #00aec0;
  color: #fff;
  text-align: center;
  font-size: 17px;
  line-height: normal;
  border: none;
  outline: none;
  padding: 14px 10px;
  cursor: pointer;

  @media screen and (max-width: 580px) {
    width: 70%;
  }
`;
const TextFeild = styled.textarea`
  border: none;
  outline: none;
  padding: 0;
  resize: vertical;
  &::placeholder {
    color: #abafb1;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    font-family: "ubuntu-regular";
    padding: 0;
  }
`;
