import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Swal from "sweetalert2";

import OtpInput from "react-otp-input";
import { StoreContext } from "../../context/Store";
import { accountsConfig } from "../../../axiosConfig";
import ButtonLoader from "../../general/loader/ButtonLoader";
import { SET_USER_DATA, UPDATE_PHASE } from "../../context/types";

function OtpVerify({ Num, Code, type ,setType}) {
  const [otp, setOtp] = useState("");
  const [error, seterror] = useState("");
  const { state, dispatch } = useContext(StoreContext);
  const [loader, setloader] = useState(false);

  // geting values from context
  let phoneNum = state.apply_details.basic_info?.phone || Num;
  let code = state.apply_details.basic_info?.code || Code;
  let countryCode = state.apply_details.basic_info?.country;

  useEffect(() => {
    if (type === "Register" || type === "Login") {
      phoneNum = Num;
      code = Code;
    }
  }, [Num, Code]);

  const handleOtpSubmit = () => {
    if (otp && otp?.length === 4) {
      setloader(true);
      const form = {
        phone: phoneNum,
        country: code,
        otp: parseInt(otp),
      };

      if (type === "Login") {
        accountsConfig.post("accounts/verify-with-otp/", form).then((response) => {
          const { data, StatusCode } = response.data.app_data;
          if (StatusCode === 6000) {
            delete data.title;
            
            dispatch({ type: SET_USER_DATA, payload: data });
            loginSuccess();
            setType("Close")
          } else if (StatusCode === 6001) {
            if (data.message === "Invalid otp") {
              seterror("Invalid otp");
              resetError();
            } else {
              seterror("Invalid otp");
              resetError();
            }
          }
          setloader(false);
        });
      } else if (type === "Register") {
        accountsConfig.post("accounts/verify-user/", form).then((response) => {
          const { data, StatusCode } = response.data.app_data;
          if (StatusCode === 6000) {
            delete data.title;
            
            dispatch({ type: SET_USER_DATA, payload: data });
            registerSuccess();
            setType("Close")
          } else if (StatusCode === 6001) {
            if (data.message === "Invalid otp") {
              seterror("Invalid otp");
              resetError();
            } else {
              seterror("Invalid otp");
              resetError();
            }
          }
          setloader(false);
        });
      }else{
        accountsConfig.post("accounts/verify-user/", form).then((response) => {
          const { data, StatusCode } = response.data.app_data;
          if (StatusCode === 6000) {
            delete data.title;
            
            dispatch({ type: SET_USER_DATA, payload: data });
            dispatch({ type: UPDATE_PHASE, payload: 3 });
          } else if (StatusCode === 6001) {
            if (data.message === "Invalid otp") {
              seterror("Invalid otp");
              resetError();
            } else {
              seterror("Invalid otp");
              resetError();
            }
          }
          setloader(false);
        });
      }
    } else {
      seterror("Please Your Otp.");
      resetError();
    }
  };

  const resetError = () => {
    setTimeout(() => {
      seterror("");
    }, 3000);
  };

  const loginSuccess = () => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });
    Toast.fire({
      icon: "success",
      title: "Signed in successfully",
    });
  };

  const registerSuccess = () => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });
    Toast.fire({
      icon: "success",
      title: "Signed up successfully",
    });
  };

  return (
    <>
      <Wrapper>
        <Info>Verify phone number</Info>
        {error ? (
          <ErrorShow>{error}</ErrorShow>
        ) : (
          <Small>A 4 digit One-Time Password has been sent to {phoneNum}</Small>
        )}

        <OtpArea>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            renderSeparator={<span>&nbsp; &nbsp; </span>}
            renderInput={(props) => <CustomInput {...props} type="number" />}
          />
        </OtpArea>
        <Small>
          {/* Didn't receive the code? <span>Request again</span> in 00:49 */}
        </Small>
      </Wrapper>
      <Container>
        {loader ? (
          <SubmiBtn>
            <ButtonLoader />
          </SubmiBtn>
        ) : (
          <SubmiBtn onClick={handleOtpSubmit}>Verify</SubmiBtn>
        )}
      </Container>
    </>
  );
}

export default OtpVerify;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Info = styled.h1`
  font-weight: unset;
  margin: 0;
  padding: 0;
  color: rgba(86, 87, 87, 1);
  font-size: 24px;
  font-family: "ubuntu-medium";
`;

const SubmiBtn = styled.button`
  width: 20%;
  background-color: rgba(0, 174, 192, 1);
  color: #fff;
  text-align: center;
  font-size: 16px;
  line-height: normal;
  border: none;
  outline: none;
  padding: 14px 10px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 50%;
  }

  @media screen and (max-width: 375px) {
    width: 100%;
  }
`;

const BackBtn = styled.button`
  width: 20%;
  background-color: #fff;
  color: rgba(0, 174, 192, 1);
  text-align: center;
  font-size: 16px;
  line-height: normal;
  border: 1px solid rgba(0, 174, 192, 1);
  outline: none;
  padding: 14px 10px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 50%;
  }

  @media screen and (max-width: 375px) {
    width: 100%;
  }
`;

const Box = styled.div`
  width: 30%;
  border: 1.5px solid #cfd3d4;
  display: flex;
  gap: 5px;
  box-sizing: border-box;
  padding: 5px;

  img {
    width: 30px;
    object-fit: contain;
    height: auto;
  }

  select {
    width: 100%;
    border: none;
    outline: none;
  }
`;

const Container = styled.div`
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Top = styled.div`
  margin-top: 15px;
`;

const Bottom = styled.div``;

const InfoBox = styled.div`
  background-color: rgba(243, 243, 243, 0.57);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 8px 16px;
`;

const Div = styled.div`
  color: rgba(86, 87, 87, 1);
  font-size: 16px;
`;

const Small = styled.p`
  margin: 0;
  padding: 0;
  color: rgba(86, 87, 87, 1);
  font-size: 14.5px;

  span {
    color: rgba(0, 174, 192, 1);
  }
`;

const ErrorShow = styled.div`
  margin: 0;
  padding: 0;
  color: #df0000;
  font-size: 14.5px;
`;

const OtpArea = styled.div``;

const CustomInput = styled.input`
  background-color: rgba(243, 243, 243, 0.37);
  border: 1px solid rgba(207, 211, 212, 1);
  height: 40px;
  width: 40px !important;
  outline: none;
  color: rgba(0, 174, 192, 1);
  font-size: 16px;

  &:focus {
    outline: 1px solid rgba(0, 174, 192, 1);
  }
`;
