import React from "react";
import Lottie from "react-lottie";
import styled from "styled-components";
import loaderAnimation from "../pageNotFound/animation/animation.json";

export default function NotFoundLoader() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderAnimation,
    rendererSettings: {},
  };

  return (
    <Container>
      <Lottie options={defaultOptions} height={300} width={750} />
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: 580px) {
    div[title] {
      height: 250px !important;
    }
  }
`;
