import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";

import downArrow from "../../../assets/images/careers/icons/down_arrow.svg";
import pdf from "../../../assets/images/careers/icons/pdf.svg";
import { accountsConfig } from "../../../axiosConfig";
import ButtonLoader from "../../general/loader/ButtonLoader";

function LoginInner({ setType, setOtpData }) {
  const [countryList, setcountryList] = useState([]);
  const [country, setcountry] = useState(0);
  const [error, seterror] = useState(null);
  const [loader, setLoader] = useState(false);

  const formRef = useRef(null);

  //fetch countries to get the country phone code
  useEffect(() => {
    accountsConfig.get("study/get-countries/").then((response) => {
      const { data, StatusCode } = response.data.app_data;
      if (StatusCode === 6000) {
        setcountryList(data);
      } else {
        setcountryList([]);
      }
    });
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current);
    setLoader(true);
    accountsConfig
      .post("accounts/login-with-otp/", formData)
      .then((response) => {
        const { data, StatusCode } = response.data.app_data;
        setLoader(false);

        if (StatusCode === 6000) {
          setOtpData({
            number: formData.get("phone"),
            code: countryList[country]?.web_code,
            type:"Login"
          });
          setType("OTP");
        } else if (StatusCode === 6001) {
          seterror(data.message);
          setTimeout(() => {
            seterror(null);
          }, 3000);
        }
      });
  };

  return (
    <>
      <Small>You will receive a 4 digit code to verify next</Small>
      <InputsDiv onSubmit={(e) => handleLogin(e)} ref={formRef}>
        <InputWrapper className="phone">
          <Box>
            <img src={countryList[country]?.flag} alt="" />
            <select
              name="country"
              onChange={(e) => setcountry(e.target.selectedIndex)}
            >
              {countryList?.map((item, index) => {
                if (index === 0) {
                  return (
                    <Option selected value={item?.web_code}>
                      {item?.phone_code}
                    </Option>
                  );
                } else {
                  return (
                    <Option value={item?.web_code}>{item?.phone_code}</Option>
                  );
                }
              })}
            </select>
          </Box>
          <InputCover className="w-70">
            <Label>
              {error ? (
                <p className="error">{error}</p>
              ) : (
                <p>
                  Phone Number<span>*</span>
                </p>
              )}
            </Label>
            <Input
              placeholder="Your number"
              type="number"
              name="phone"
              required
            />
          </InputCover>
        </InputWrapper>
        <SubmiBtn type={loader ? "button" : "submit"}>
          {loader ? <ButtonLoader /> : "Submit"}
        </SubmiBtn>
      </InputsDiv>
      <Container>
        <Info>
          New to Medcity?
          <span onClick={() => setType("Register")}> Create an account.</span>
        </Info>
      </Container>
    </>
  );
}

export default LoginInner;

const InputsDiv = styled.form`
  margin-top: 20px;
  display: flex;
  gap: 24px;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 15px;
    justify-content: center;
  }
`;

const InputCover = styled.div`
  border: 1.5px solid #cfd3d4;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  background-color: #fff;

  &.w-70 {
    width: 70%;
  }

  &.name {
    grid-area: name;
  }

  &.email {
    grid-area: email;
  }

  &.phone {
    grid-area: phone;
  }

  &.gender {
    grid-area: gender;
  }

  &.city {
    grid-area: city;
  }

  &.courses {
    grid-area: course;
  }
`;

const Small = styled.p`
  margin: 0;
  padding: 0;
  color: rgba(86, 87, 87, 1);
  font-size: 15px;
  padding: 10px 0;

  span {
    color: rgba(0, 174, 192, 1);
  }
`;

const Heading = styled.div`
  color: #565757;
  font-size: 21px;
  font-style: normal;
  line-height: normal;
  font-family: "ubuntu-medium";
`;

const Label = styled.label`
  color: #00aec0;
  font-size: 13px;
  font-style: normal;
  line-height: normal;
  position: relative;
  cursor: pointer;

  p {
    &.error {
      color: red;
    }
  }

  span {
    color: red;
  }

  &.role-select {
    &::after {
      content: "";
      position: absolute;
      top: 5px;
      right: 0;
      background-image: url(${downArrow});
      background-size: contain;
      width: 25px;
      height: 30px;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }

  &.cv {
    &::after {
      content: "";
      position: absolute;
      top: 5px;
      right: 0;
      background-image: url(${pdf});
      background-size: contain;
      background-repeat: no-repeat;
      width: 25px;
      height: 30px;
      cursor: pointer;
    }
  }

  &.cv-label {
    color: #abafb1;
    width: max-content;
    border-bottom: 1px solid #abafb1;
    cursor: pointer;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  gap: 5px;
  width: 55%;

  &.phone {
    grid-area: phone;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Input = styled.input`
  border: none;
  outline: none;
  padding: 0;
  &::placeholder {
    color: #abafb1;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    font-family: "ubuntu-regular";
    padding: 0;
  }
`;

const Select = styled.select`
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #abafb1;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  font-family: "ubuntu-regular";
  position: absolute;
  left: 0;
  right: 0;
  padding-left: 15px;
  padding-top: 25px;
  background-color: transparent;

  option:hover {
    background-color: red;
  }
`;

const Option = styled.option`
  color: #000000;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  font-family: "ubuntu-regular";
  background-color: #ffffff;
  padding: 20px !important;
`;

const FileInput = styled.input`
  display: none !important;
`;

const SubmiBtn = styled.button`
  width: 20%;
  background-color: rgba(0, 174, 192, 1);
  color: #fff;
  text-align: center;
  font-size: 16px;
  line-height: normal;
  border: none;
  outline: none;
  padding: 14px 10px;
  cursor: pointer;
  /* align-self: stretch; */

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Box = styled.div`
  width: 30%;
  border: 1.5px solid #cfd3d4;
  display: flex;
  gap: 5px;
  box-sizing: border-box;
  padding: 5px;

  img {
    width: 30px;
    object-fit: contain;
    height: auto;
  }

  select {
    width: 100%;
    border: none;
    outline: none;
  }
`;

const Container = styled.div`
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Info = styled.p`
  font-size: 15px;
  color: #000;

  span {
    color: rgba(0, 174, 192, 1);
    cursor: pointer;
  }
`;
