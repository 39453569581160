import React from "react";
import choose from "../../../../assets/images/migrate/choose.png";
import course from "../../../../assets/images/migrate/course.png";
import abroad from "../../../../assets/images/migrate/abroad.png";
import map from "../../../../assets/videos/Map-gif.gif";
import { styled } from "styled-components";

function Migrate() {
  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <Text>
            Let's Migrate to the <br /> Best International Educational Institutions
          </Text>
          <MapSection>
            <img src={map} alt="gif" />
          </MapSection>
          {/* <Tabs>
            <CoverTab>
              <Image>
                <img src={course} alt="" />
              </Image>
              <Title>
                Find a <br />
                Course
              </Title>
            </CoverTab>
            <CoverTab>
              <Image>
                <img src={abroad} alt="" />
              </Image>
              <Title>
                Study
                <br />
                Abroad
              </Title>
            </CoverTab>
            <CoverTab>
              <Image>
                <img src={choose} alt="" />
              </Image>
              <Title>
                Why <br />
                Choose Us
              </Title>
            </CoverTab>
          </Tabs> */}
        </Content>
      </Wrapper>
    </Container>
  );
}

export default Migrate;

const Container = styled.div`
  padding: 80px 0;
  background: #fff;
  @media all and (max-width: 480px) {
    padding: 40px 0;
  }
`;
const Wrapper = styled.div``;
const Content = styled.div``;
const Text = styled.h1`
  font-size: 40px;
  /* color: #136e9e; */
  /* background: rgb(0, 152, 246); */
  text-align: center;
  color: var(--headerColor);
  /* background: linear-gradient(
    9deg,
    rgba(0, 152, 246, 1) 0%,
    rgba(0, 92, 185, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  @media all and (max-width: 768px) {
    font-size: 32px;
    width: 60%;
  }
  @media all and (max-width: 640px) {
    width: 100%;
    text-align: center;
  }
  br {
    @media all and (max-width: 768px) {
      display: none;
    }
  }
  @media all and (max-width: 580px) {
    font-size: 24px;
  }
`;
const MapSection = styled.div`
  width: 76%;
  margin: 0 auto;
  img {
    display: block;
    width: 100%;
  }
  @media all and (max-width: 768px) {
    width: 100%;
  }
`;
const CoverTab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 100px;
  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
  @media all and (max-width: 768px) {
    margin-right: 50px;
  }
  @media all and (max-width: 640px) {
    margin-right: 20px;
  }
  @media all and (max-width: 400px) {
    margin-bottom: 20px;
  }
`;
const Image = styled.div`
  position: relative;
  margin-right: 50px;
  &::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 80px;
    top: 0;
    right: -30px;
    background: linear-gradient(
      to right,
      #21a9c1 0%,
      26.470589637756348%,
      #109bb5 52.941179275512695%,
      76.47058963775635%,
      #1f93b0 100%
    );
    @media all and (max-width: 768px) {
      height: 65px;
    }
    @media all and (max-width: 640px) {
      display: none;
    }
  }
  img {
    display: block;
    width: 100%;
  }
  @media all and (max-width: 768px) {
    width: 60px;
  }
  @media all and (max-width: 640px) {
    margin-right: 20px;
  }
  @media all and (max-width: 480px) {
    width: 50px;
  }
`;
const Title = styled.div`
  font-family: "ubuntu-Regular";
  font-size: 22px;
  @media all and (max-width: 768px) {
    font-size: 18px;
  }
  @media all and (max-width: 640px) {
    font-size: 16px;
  }
`;
const Tabs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media all and (max-width: 400px) {
    flex-wrap: wrap;
  }
`;
