import React, { useEffect, useState } from "react";
import styled from "styled-components";

//images
import manQstn from "../../../../../../assets/images/studyAbroad/man.png";
import { accountsConfig } from "../../../../../../axiosConfig";

export default function WhyStudyAbroad() {
  const [upAndDown, setupAndDown] = useState();
  const [getData, setData] = useState([]);

  useEffect(() => {
    accountsConfig.get("study/list-why-study-abroad/").then((res) => {
      const data = res.data.app_data.data;
      if (res.data.app_data.StatusCode == 6000) {
        setData(data);
      } else {
        setData([]);
      }
    });
  }, []);
  const benefits = [];
  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <Title>Why Study Abroad?</Title>
          <Description>
            In this thriving world of opportunities and rapidly blooming
            business environments and a vast ocean of unexplored possibilities,
            challenges and dreams are ultimately proportional. The more you
            dream the more is the challenge you face. Exploring and winning over
            is what that matters! Knowledge is the central power and with hope
            for a better tomorrow for all of us, studying abroad comes with a
            huge and overwhelming eventualities, well suited to your quest of
            acquiring knowledge and learning new things in life the best way
            possible. Once you move forward in this direction, there are endless
            and monumental accolades waiting in your way. So what you need is
            determination and perseverance to grab it.
          </Description>
          <Section>
            <ImageContainer>
              <img src={manQstn} alt="image" />
            </ImageContainer>
            <Tabs>
              {getData?.map((obj, index) => (
                <TabCover key={index} onClick={() => setupAndDown(obj.id)}>
                  <CoverText>
                    <Text>{obj.title}</Text>
                    <Icon>
                      {upAndDown == obj.id ? (
                        <i class="ri-arrow-up-s-line"></i>
                      ) : (
                        <i class="ri-arrow-down-s-line"></i>
                      )}
                    </Icon>
                  </CoverText>
                  {upAndDown == obj.id && <Detail>{obj.description}</Detail>}
                </TabCover>
              ))}
            </Tabs>
          </Section>
        </Content>
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  background-color: #00aec0;
  color: #fff;
  padding-top: 120px;
  @media (max-width: 480px) {
    padding-top: 85px;
  }
`;
const Wrapper = styled.div``;
const Content = styled.div`
  margin-top: 80px;
  @media (max-width: 768px) {
    margin-top: 30px;
  }
`;
const Title = styled.h1`
  text-align: center;
  font-size: 45px;
  letter-spacing: 1px;
  margin-bottom: 10px;
  @media (max-width: 400px) {
    font-size: 30px;
  }
`;
const Description = styled.p`
  line-height: 24px;
  text-align: justify;
`;
const Section = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;
const ImageContainer = styled.div`
  width: 50%;
  img {
    display: block;
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 60%;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;
const Tabs = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const TabCover = styled.div`
  margin-bottom: 30px;
  cursor: pointer;
  border-bottom: 1px solid #fff;
  padding-bottom: 20px;
`;
const CoverText = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Icon = styled.div``;
const Text = styled.h3`
  margin-bottom: 10px;
`;
const Detail = styled.p`
  line-height: 26px;
  text-align: justify;
`;
