import React from "react";

//packages
import styled from "styled-components";

//imports
import HeaderEdit from "../../../includes/HeaderEdit";
import Introduction from "../canada/Introduction";
import EntryProgram from "../canada/EntryProgram";
import PnpOverview from "../canada/PnpOverview";
import StartupVisa from "../canada/StartupVisa";
import WorkPermits from "../canada/WorkPermits";
import IntroductionAus from "../austrailia/IntroductionAus";
import Overview from "../austrailia/Overview";
import Footer from "../../../includes/Footer";

//images
import bg from "../../../../../../assets/images/migrate/flight.svg";

export default function Migrate() {
  return (
    <Container>
      <HeaderEdit backgroundCl="#00AEC0" />

      {/* ------canada----- */}
      <Introduction />
      <EntryProgram />
      <PnpOverview />
      <StartupVisa />
      <WorkPermits />

      {/* ------Austrailia----- */}
      <IntroductionAus />
      <Overview />
      <Footer />
      {/* <Flight>
        <img src={bg} alt="" />
      </Flight> */}
    </Container>
  );
}

const Flight = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    display: block;
  }
`;
const Container = styled.div`
  position: relative;
  z-index: 1;
  /* background: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 10; */
`;
