import React from 'react'
import HeaderEdit from '../includes/HeaderEdit'
import Footer from '../includes/Footer'
import NewsAndEventsListing from '../includes/newsandevents/NewsAndEventsListing'

function NewsAndEvents() {
  return (
    <>
    <HeaderEdit backgroundCl={"#00AEC0"} />
    <NewsAndEventsListing/>
    <Footer />
    </>
  )
}

export default NewsAndEvents