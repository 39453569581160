import React, { useEffect, useState } from "react";
import styled from "styled-components";

import arrow from "../../assets/images/icons/arrow-left.svg";
import { ADD, REMOVE, hideOverFlow } from "../general/functions";
import { useNavigate } from "react-router-dom";
import { accountsConfig } from "../../axiosConfig";

function CourseSearchModal({ modal, setModal }) {
  const [tab, settab] = useState("course");
  const [ProgramsList, setProgramsList] = useState([]);
  const [program, setprogram] = useState("");
  const [course, setcourse] = useState("");
  const [jobTitle, setjobTitle] = useState("");
  const [error, seterror] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (modal) {
      hideOverFlow(ADD);
      setprogram(ProgramsList[0]?.id);
    } else {
      hideOverFlow(REMOVE);
    }
  }, [modal]);

  useEffect(() => {
    accountsConfig.get(`study/get-programme/`, {}).then((res) => {
      const StatusCode = res.data.app_data.StatusCode;
      const Data = res.data.app_data.data;
      if (StatusCode == 6000) {
        setProgramsList(Data);
      } else {
        setProgramsList([]);
      }
    });
  }, []);

  const handleCourseSearch = () => {
    if (program === "") {
      setprogram(ProgramsList[0]?.id);
    }
    if (course === "") {
      seterror(1);
      resetError();
      return;
    }

    setcourse("");
    handleModalClose();
    navigate(`/find-a-course?search=${course}&&program=${program}`);
  };

  const handleJobSearch = () => {
    if (jobTitle === "") {
      seterror(2);
      resetError();
      return;
    }

    setjobTitle("");
    handleModalClose();
    navigate(`/jobs?search=${jobTitle}`);
  };

  const handleModalClose = () => {
    setModal(false);
  };

  const resetError = () => {
    setTimeout(() => {
      seterror("");
    }, 2000);
  };

  return (
    <Section className={modal && "active"}>
      <Modal>
        <Container>
          <Tabs>
            <Tab
              onClick={() => settab("course")}
              className={tab === "course" && "active"}
            >
              Search for courses
            </Tab>
            <Tab
              onClick={() => settab("job")}
              className={tab !== "course" && "active"}
            >
              Search for jobs
            </Tab>
          </Tabs>
          {tab === "course" ? (
            <TabInner>
              <Select onChange={(e) => setprogram(e.target.value)}>
                {ProgramsList?.map((item, index) => {
                  if (index === 0) {
                    return (
                      <Option value={item?.id} selected>
                        <div>{item?.name}</div>
                      </Option>
                    );
                  } else {
                    return (
                      <Option value={item?.id}>
                        <div>{item?.name}</div>
                      </Option>
                    );
                  }
                })}
              </Select>
              <Input
                onChange={(e) => setcourse(e.target.value)}
                value={course}
                placeholder={
                  error === 1 ? "Enter course name" : "Enter course subject"
                }
                className={error === 1 && "error"}
              ></Input>
            </TabInner>
          ) : (
            <TabInner>
              <Input
                onChange={(e) => setjobTitle(e.target.value)}
                value={jobTitle}
                placeholder={error === 2 ? "Enter job name" : "Enter job title"}
                className={error === 2 && "error"}
              ></Input>
            </TabInner>
          )}

          <Btns>
            <BackBtn onClick={handleModalClose}>Back</BackBtn>
            {tab === "course" ? (
              <Submit onClick={handleCourseSearch}>
                Discover courses <img src={arrow} alt="" />
              </Submit>
            ) : (
              <Submit onClick={handleJobSearch}>
                Discover jobs <img src={arrow} alt="" />
              </Submit>
            )}
            {/* <Submit>
              Discover courses <img src={arrow} alt="" />
            </Submit> */}
          </Btns>
        </Container>
      </Modal>
    </Section>
  );
}

export default CourseSearchModal;

const Section = styled.div`
  transform: scale(0, 0);
  visibility: hidden;
  position: fixed;
  inset: 0;
  top: 0;
  z-index: 10001;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);

  &.active {
    transform: scale(1, 1);
    visibility: visible;
  }
`;

const Modal = styled.div`
  width: 80%;
  background-color: rgba(248, 248, 248, 1);
  z-index: 10002;
  max-width: 650px;
  height: 50%;
  max-height: 600px;

  @media screen and (max-width:580px) {
    height: 60%;
    max-height: 350px;
  }
  @media screen and (max-width:480px) {
    width: 90%;
  }

  @media screen and (max-width:400px) {
    height: 65%;
    max-height:375px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
  justify-content: space-between;
  min-height: 80%;
`;

const Tabs = styled.div`
  display: flex;
`;

const Tab = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  color: #fff;
  background-color: rgba(206, 206, 206, 1);
  font-size: 19px;
  cursor: pointer;

  &.active {
    background-color: rgba(0, 174, 192, 1);
  }

  @media screen and (max-width:580px) {
    font-size: 16px;
    height: 50px;
  }
`;

const TabInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 85%;
  margin: 0 auto;

  @media screen and (max-width:580px) {
    width:95%;
  }
`;

const Btns = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  width: 85%;
  margin: 0 auto;

  @media screen and (max-width:580px) {
    gap: 15px;
    width:95%;
  }
  @media screen and (max-width:400px) {
    flex-direction: column-reverse;
  }
`;

const Input = styled.input`
  height: 50px;
  border: 1.5px solid rgba(0, 174, 192, 1);
  padding: 0 10px;
  outline-color: rgba(0, 174, 192, 1);
  font-size: 15px;
  color: rgba(171, 175, 177, 1);
  font-family: "ubuntu-light";

  &::placeholder {
    color: rgba(171, 175, 177, 1);
    font-size: 15px;
  }

  &.error {
    &::placeholder {
      color: red;
    }
  }
`;

const Select = styled.select`
  height: 50px;
  padding: 0 10px;
  border: 1.5px solid rgba(0, 174, 192, 1);
  outline-color: rgba(0, 174, 192, 1);
  color: rgba(171, 175, 177, 1);
  font-size: 15px;
  font-family: "ubuntu-light";
`;

const Option = styled.option`
  font-size: 15px;
  font-family: "ubuntu-light";

  div {
    height: 100%;
    width: 100%;
    background-color: red;
    font-size: 15px;
    padding: 10px 0px;
  }
`;

const BackBtn = styled.div`
  color: rgba(0, 174, 192, 1);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0 10px;
  border: 1px solid rgba(0, 174, 192, 1);
  min-width: 100px;
  cursor: pointer;

  @media screen and (max-width:580px) {
    width:50%;
  }

  @media screen and (max-width:400px) {
    width: 100%;
  }
`;

const Submit = styled.div`
  background-color: rgba(0, 174, 192, 1);
  color: #fff;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0 10px;
  min-width: 100px;
  cursor: pointer;

  @media screen and (max-width:580px) {
    width:50%;
    font-size: 16px;
  }

  @media screen and (max-width:400px) {
    width: 100%;
  }
`;
