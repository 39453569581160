import React from "react";
import styled from "styled-components";

function IntakesContent({ data }) {
  return (
    <Section>
      <Table>
        <Thead>
          <Tr>
            <Td>Intake</Td>
            <Td>Deadline</Td>
          </Tr>
        </Thead>
        <TBody>
          {data?.map((item) => (
            <Tr>
              <Td className="value">{item?.intake}</Td>
              <Td className="value">{item?.deadline}</Td>
            </Tr>
          ))}
        </TBody>
      </Table>
    </Section>
  );
}

export default IntakesContent;

const Section = styled.div`
  padding: 30px;
  box-sizing: border-box;

  @media screen and (max-width: 768px) {
    padding: 12px 10px;
  }
  @media (max-width:374px){
    padding: 0%;
  }
`;

const Table = styled.table`
  color: #fff;
  border-collapse: collapse;
`;

const Thead = styled.thead`
  background-color: rgba(126, 126, 126, 1);
  padding: 5px;
`;

const TBody = styled.tbody`
  padding: 5px;
  background-color: rgba(83, 83, 83, 1);
`;

const Tr = styled.tr``;

const Td = styled.td`
  font-size: 16px;
  padding: 10px;

  &.value {
    font-size: 15px;
  }

  @media screen and (max-width: 580px) {
    &.value {
      font-size: 13px;
    }
  }
`;
