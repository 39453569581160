import React from 'react';
import styled, { keyframes } from 'styled-components';
import image1 from '../../assets/images/HomePage/mobileimage.webp';
import image2 from '../../assets/images/HomePage/pcimage.webp';
import gif from '../../assets/images/HomePage/gif.gif'
function ParticipateImageModal({ isModal, setModal, participateModal }) {
  const handleImageClick = () => {
    setModal(false); // Close the image modal
    participateModal(true); // Open the participate modal
  };

  return (
    <MainContainer>
      {isModal ? <Overlay onClick={() => setModal(false)}></Overlay> : ""}
      <BackContainer style={{ transform: isModal ? "scale(1,1)" : "scale(0, 0)" }}>
        <Modal className="modal">
          <Container>
            {/* <Close onClick={() => setModal(false)}>
              <i className="ri-close-line"></i>
            </Close> */}
            <Button onClick={handleImageClick} ><span>Click to Participate</span> <img src={gif} alt="" /></Button>
            <Image className='mobile' src={image1} onClick={handleImageClick} />
            <Image className='pc' src={image2} onClick={handleImageClick} />
          </Container>
        </Modal>
      </BackContainer>
    </MainContainer>
  );
}

export default ParticipateImageModal;

const Image = styled.img`
  width: 100%;
  cursor: pointer;
`;

const BackContainer = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  margin: 0 auto;
  right: 0;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
`;

const Overlay = styled.div`
  background: rgba(35, 46, 65, 0.37);
  /* backdrop-filter: blur(2px); */
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0px;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;

const Modal = styled.div`
  width: 46%;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  transition: 0.5s;
  z-index: 101;
  border-radius: 5px;

  @media all and (max-width: 1700px) {
    width: 46%;
  }
  @media all and (max-width: 1440px) {
    width: 50%;
  }
  @media all and (max-width: 1280px) {
    width: 50%;
  }
  @media all and (max-width: 1080px) {
    width: 50%;
  }
  @media all and (max-width: 768px) {
    top: 45%;
    width: 65%;
  }
  @media all and (max-width: 480px) {
    width: 90%;
    top: 45%;
  }
`;

const MainContainer = styled.div``;

const Close = styled.div`
  color: white;
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 30px;
  cursor: pointer;

  @media all and (max-width: 1440px) {
    top: 20px;
  }
`;

const Container = styled.div`
  background-color: #fff;
  display: flex;
  border-radius: 20px;
  .mobile{
    display: none;
  }
  @media (max-width:767px){
    .pc{
      display: none;
    }
    .mobile{
      display: block;
    }
  }
`;
const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

// Apply the animation to the button
const Button = styled.button`

  position: absolute;
  bottom: 0%;
  /* right: 50%; */
  cursor: pointer;
  left: 50%;
  background-color: yellow;
  border-radius: 50px;
  border: 2px solid black;
  color: black;
  font-family: 'ubuntu-bold';
  font-size: 24px;
  padding: 16px 30px;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  /* width: 70px; */
  overflow: hidden;
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      120deg,
      rgba(255, 255, 255, 0) 30%,
      rgb(255 255 255 / 53%),
      rgba(255, 255, 255, 0) 70%
    );
    top: 0;
    left: -100px;
    animation: shimmerAnimations 1s infinite linear;
    @keyframes shimmerAnimations {
      0% {
        left: -100px;
      }
      20% {
        left: 100%;
      }
      100% {
        left: 100%;
      }
    }
    animation: shimmerAnimations 3s infinite linear;
  }

  img{
    width: 25px;
  }
  
  @media (max-width:767px){
    font-size: 14px;
    padding: 10px 30px;
    bottom: 0%;
    left: 50%;
  }
  @media (max-width:370px){
    font-size: 12px;
    padding: 10px 25px;
    bottom: 0%;
    left: 50%;
    img{
    width: 20px;
  }
  }
  
`;