import React, { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import AppRouter from "./AppRouter";
import { StoreContext } from "../../context/Store";
import { SET_USER_DATA, USER_LOGOUT } from "../../context/types";
import QuickFinder from "../../ui/home/includes/quickfinder/QuickFinder";

function MainRouter() {
  const { state, dispatch } = useContext(StoreContext);
  useEffect(() => {
    if (!state?.is_LoggedIn) {
      const userData = localStorage.getItem('user_data') || null;
      if (userData) {
        dispatch({ type: SET_USER_DATA, payload: JSON.parse(userData) });
      } else {
        dispatch({ type: USER_LOGOUT, payload: null });
      }
    }
  }, [])

  return (
    <>
      <Routes>
        <Route path="/*" element={<AppRouter />} />

      </Routes>
      <QuickFinder/>
    </>
  );
}

export default MainRouter;
