import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import styled from 'styled-components';
import { accountsConfig } from '../../../../../axiosConfig';

function Intake() {
  const location = useLocation();
  const isOfferPage = location.pathname === '/offers';
  const navigate = useNavigate()
  const [courseError,setCourseError] = useState({text:"",show:false})
  const [IntakeError,setIntakeError] = useState({text:"",show:false})
  const [getSubCourse,setSubCourse] = useState([])
  
  const [selectedSubCourseOption, setSelectedSubCourseOption] = useState(null);
  const [selectedIntakeOption, setSelectedIntakeOption] = useState(null);
  const intake_list = [
    { value: "january", label: "January" },
    { value: "february", label: "February" },
    { value: "march", label: "March" },
    { value: "april", label: "April" },
    { value: "may", label: "May" },
    { value: "june", label: "June" },
    { value: "july", label: "July" },
    { value: "august", label: "August" },
    { value: "september", label: "September" },
    { value: "october", label: "October" },
    { value: "november", label: "November" },
    { value: "december", label: "December" },
  ];

  useEffect(() => {
    accountsConfig.get("study/get-courses/").then((res) => {
      const data = res.data.app_data.data;
      if (res.data.app_data.StatusCode === 6000) {
        setSubCourse(res.data.app_data.data.map(course => ({
          value: course.id,
          label: course.name
        })));      
      } else {
        setSubCourse([]);
      }
    });
  }, []);

  // Course Selection 
  const handleCourseChange = (selectedOption) => {
    setSelectedSubCourseOption(selectedOption);
    if (courseError.show){
      setCourseError({ text: '', show: false });
    }
  };

  // Intake Selection 
  const handleIntakeChange = (selectedOption) => {
    setSelectedIntakeOption(selectedOption);
    if (IntakeError.show){
      setIntakeError({ text: '', show: false });
    }
  };

  // Submit answers
  const SubmitAnswer = () => {
    if (selectedIntakeOption === null) {
      setIntakeError({ text: 'Please select an intake', show: true });
      return;
    }
    if (selectedSubCourseOption === null) {
      setCourseError({ text: 'Please select a course', show: true });
      return;
    }

    

    setTimeout(() => {
      setCourseError({ text: '', show: false });
      setIntakeError({ text: '', show: false });
    }, 3);

    if (selectedSubCourseOption && selectedIntakeOption) {
      navigate(`/find-a-course?study_area=${selectedSubCourseOption.value}&&intake=${selectedIntakeOption.value}`);
    }
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 1000,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 1000,
    }),
  };
  return (
    <Section isOfferPage={isOfferPage}>
      <div className="select">
    <Select
      value={selectedIntakeOption}
      onChange={handleIntakeChange}
      options={intake_list}
      placeholder="Select your intake"
      className="basic-multi-select select"
      classNamePrefix="select"
      styles={customStyles} 
      menuPortalTarget={document.body} 
    />
       {IntakeError.show && <ErrorMsg>{IntakeError.text}</ErrorMsg>}
    </div>
    <div className="select">
    <Select
      value={selectedSubCourseOption}
      onChange={handleCourseChange}
      options={getSubCourse}
      placeholder="Enter course subject e.g. Law"
      isSearchable
      styles={customStyles} 
      menuPortalTarget={document.body} 
    />
   {courseError.show && <ErrorMsg>{courseError.text}</ErrorMsg>}
    </div>
    
    <Button OfferPage={isOfferPage} onClick={SubmitAnswer}>Search</Button>
  </Section>
  )
}

export default Intake

const Section = styled.div`
  padding: ${props => props.isOfferPage ? '20px 20px' : '40px'};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  .select{
    width: 100%;
  }
  @media (max-width: 880px){
    flex-direction: column;
  }
  @media (max-width: 425px){
    padding: ${props => props.isOfferPage ? '20px 20px' : '40px 20px'};
    gap: ${props => props.isOfferPage ? '10px' : '30px'};
  }
`;

const Button = styled.a`
  cursor: pointer;
  font-size: 16px;
  border-radius: 108px;
  color: white;
  background-color: var(--headerColor);
  padding: 14px 25px;
  font-family: 'ubuntu-medium';
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 880px){
    padding: ${props => props.isOfferPage ? '12px 0px' : '14px 0px'};
    width: 100%;
  }
`;

const ErrorMsg = styled.p`
  font-size: smaller;
  color: red;
  margin-top: 2px;
  margin-bottom: -16px;
`