import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import Slider from "react-slick";
import arrow from "../../../../assets/images/HomePage/icons/arrow.svg";
import { accountsConfig } from "../../../../axiosConfig";

function Institutes() {
  const [universityBanner, setUniversityBanner] = useState();
  const [name, setName] = useState("");
  const [getUniversities, setUniversities] = useState([]);

  const [description, setDecription] = useState();
  //get universities
  useEffect(() => {
    accountsConfig.get("study/view-university/").then((res) => {
      const data = res.data.app_data.data;
      if (res.data.app_data.StatusCode == 6000) {
        setUniversities(data);
        setUniversityBanner(data[0].photo);
        setName(data[0].name);
        setDecription(data[0].description);
      } else {
        setUniversities([]);
      }
    });
  }, []);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 3,
    arrows: false,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <Container>
      <Wrapper>
        <Content>
          <CoverTop>
            <Title>Institutes</Title>
            {/* <Botton>
              See all Universities
              <Icon>
                <img src={arrow} alt="" />
              </Icon>
            </Botton> */}
          </CoverTop>
          <CoverBanner>
            <Banner>
              <img src={universityBanner} alt="alt" />
            </Banner>
            <Right>
              <InstituteName> {name}</InstituteName>
              <InstitueDescription>{description} </InstitueDescription>
            </Right>
          </CoverBanner>
          <LogoContainer>
            <Slider {...settings}>
              {getUniversities.map((item) => (
                <ImageContainer
                  onClick={() => {
                    setUniversityBanner(item.photo);
                    setName(item.name);
                    setDecription(item.description);
                  }}
                >
                  <img src={item.thumbnail} alt="image" />
                </ImageContainer>
              ))}
            </Slider>
          </LogoContainer>
        </Content>
      </Wrapper>
    </Container>
  );
}

export default Institutes;

const Container = styled.div`
  /* padding: 80px 0; */
  background-color: transparent;
  @media all and (max-width: 480px) {
    padding: 30px 0;
  }
`;
const Wrapper = styled.div``;
const Icon = styled.div``;
const Botton = styled.div`
  margin-bottom: 20px;
  display: flex;
  /* box-shadow: 10px 10px 13px -11px rgba(138, 138, 138, 1); */
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  color: #fff;
  gap: 10px;

  @media (max-width: 475px) {
    font-size: 15px;
  }
`;
const CoverTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media all and (max-width: 768px) {
    margin-bottom: 20px;
  }

  @media (max-width: 580px) {
    margin-bottom: 0px;
  }
`;
const CoverBanner = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
  height: 250px;
  @media all and (max-width: 1080px) {
    flex-direction: column;
    gap: 16px;
    height: max-content;
  }
`;
const Right = styled.div`
  width: 35%;
  /* box-shadow: 10px 10px 23px -11px rgba(138, 138, 138, 1); */
  /* border-radius: 8px; */
  padding: 15px;
  border: 1px solid #fff;
  @media all and (max-width: 1080px) {
    width: 100%;
    box-shadow: none;
    box-sizing: border-box;
  }
`;
const InstituteName = styled.h2`
  margin-bottom: 20px;
  color: #ffffff;
`;
const InstitueDescription = styled.p`
  color: #ffffff;
  line-height: 30px;
  font-size: 16px;

  @media (max-width: 580px) {
    font-size: 14px;
    /* text-align: justify; */
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media (max-width: 1080px) {
    gap: 30px;
  }

  @media (max-width: 580px) {
    gap: 20px;
  }
`;
const Title = styled.h2`
  margin-bottom: 20px;
  font-size: 30px;
  color: #fff;
`;
const Banner = styled.div`
  height: 100%;
  width: 60%;
  margin-right: 30px;
  /* height: 500px; */
  object-fit: cover;
  overflow: hidden;
  /* margin-bottom: 50px; */

  @media (max-width: 1080px) {
    width: 100%;
    min-height: 100%;
    margin-right: 0px;
  }

  img {
    width: 100%;
    border-radius: 10px;
    display: block;
    height: 100%;
  }
`;
const LogoContainer = styled.div``;
const ImageContainer = styled.div`
  background-color: white;
  border: 1px solid #00b1c3;
  overflow: hidden;
  cursor: pointer;
  width: 95% !important;
  padding: 10px;
  &:active {
    border: 2px solid #00b1c3;
  }
  img {
    display: block;
    width: 100%;
  }
`;
