import React from "react";

//packages
import styled from "styled-components";

//imports
import Title from "./titles/Title";
import SubTitle from "./titles/SubTitle";
import DescriptionText from "./titles/DescriptionText";
import { entry_program_canada } from "../../../../../general/commonArrays";

//images
import bluepoint from "../../../../../../assets/images/migrate/bluepoint.png";

export default function StartupVisa() {
  return (
    <>
      <Container>
        <Wrapper className="wrapper">
          <Content>
            <Heading>Canada Start-Up Visa for Entrepreneurs</Heading>
            <Description className="main-description">
              Canada has witnessed a surge in immigration,
              especially among skilled individuals. Entrepreneurs are now welcomed
              through the Start-Up Visa program, aligning with Canada's goal of
              boosting its economy and creating job opportunities.
            </Description>
            <div className="grid">
              <Left>
                <SubTitle label="Eligibility Criteria:" />
                {/* <DescriptionText
                  label=" To qualify for the Start-Up Visa, entrepreneurs must meet specific
                    requirements:"
                /> */}
                <Points>
                  {entry_program_canada.map((obj, index) => (
                    <CoverPoint key={index}>
                      <ICon>
                        <img src={bluepoint} alt="circle" />
                      </ICon>
                      <CoverTextPoint>
                        <Span>{obj.title} </Span>
                      </CoverTextPoint>
                    </CoverPoint>
                  ))}
                </Points>
              </Left>
              <Right>
                <SubTitle label="Application Process:" />
                <Description>
                The PR application process involves three phases:
                </Description>
                <Points>
                  {entry_program_canada.slice(0, 3).map((obj, index) => (
                    <CoverPoint key={index}>
                      <ICon>
                        <img src={bluepoint} alt="circle" />
                      </ICon>
                      <CoverTextPoint>
                        <Span>{obj.title} </Span>
                      </CoverTextPoint>
                    </CoverPoint>
                  ))}
                </Points>
                <SubTitle label="Timeline:" />
                {/* <DescriptionText label="" /> */}
                <Description>
                  The processing time for the entire process may range from 12 to 18 months.
                </Description>
              </Right>
            </div>
          </Content>
        </Wrapper>
      </Container>
    </>
    // <Container>
    //   <Wrapper className="wrapper">
    //     <Content>
    //       <Title label="Canada Start-Up Visa for Entrepreneurs" />
    //       <SubTitle label="Introduction:" />
    //       <DescriptionText
    //         label="Canada has witnessed a surge in immigration,
    //         especially among skilled individuals. Entrepreneurs are now welcomed
    //         through the Start-Up Visa program, aligning with Canada's goal of
    //         boosting its economy and creating job opportunities."
    //       />
    //       <SubTitle label="Eligibility Criteria:" />
    //       <DescriptionText
    //         label="To qualify for the Start-Up Visa, entrepreneurs must
    //         meet specific requirements:"
    //       />
    //       <SubTitle label="Eligibility Criteria:" />
    //       <DescriptionText
    //         label=" To qualify for the Start-Up Visa, entrepreneurs must meet specific
    //         requirements:"
    //       />
    //       <Points>
    //         {entry_program_canada.map((obj, index) => (
    //           <CoverPoint key={index}>
    //             <ICon>
    //               <img src={bluepoint} alt="circle" />
    //             </ICon>
    //             <CoverTextPoint>
    //               <Span>{obj.title} </Span>
    //             </CoverTextPoint>
    //           </CoverPoint>
    //         ))}
    //       </Points>
    //       <SubTitle label="Application Process:" />
    //       <DescriptionText label="The PR application process involves three phases:" />
    //       <Points>
    //         {entry_program_canada.slice(0, 3).map((obj, index) => (
    //           <CoverPoint key={index}>
    //             <ICon>
    //               <img src={bluepoint} alt="circle" />
    //             </ICon>
    //             <CoverTextPoint>
    //               <Span>{obj.title} </Span>
    //             </CoverTextPoint>
    //           </CoverPoint>
    //         ))}
    //       </Points>
    //       <SubTitle label="Timeline:" />
    //       <DescriptionText label="The processing time for the entire process may range from 12 to 18 months." />
    //     </Content>
    //   </Wrapper>
    // </Container>
  );
}

const Container = styled.div`
  background-color: #f8f8f8;
  padding: 80px 0;
  @media (max-width: 480px) {
    padding: 40px 0;
  }
`;
const Wrapper = styled.div``;
const Content = styled.div`
  .grid{
    display: grid;
    grid-template-columns: repeat(2,1fr);
  }
  @media (min-width:768px) and (max-width:1024px){
    .grid{
      gap: 50px;
    }
  }
  @media (max-width:767px){
    .grid{
      grid-template-columns: repeat(1,1fr);
      gap: 10px;
    }
  }
`;

const Points = styled.div`
  &.flex {
    display: flex;
    flex-wrap: wrap;
  }
  margin-bottom: 30px;
`;
const CoverPoint = styled.div`
  display: flex;
  gap: 5px;
  /* align-items: center; */
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  &.flex {
    width: 50%;
  }
`;
const ICon = styled.div``;
const CoverTextPoint = styled.div`
  display: flex;
  gap: 5px;
  line-height: 22px;
`;
const TextPoint = styled.h4`
  font-size: 18px;
`;
const Span = styled.p`
  color: #2b2b2b;
`;

const Heading = styled.h2`
  color: #00aec0;
  font-size: 48px;
  margin-bottom: 20px;
  text-align: center;
  @media (min-width:768px) and (max-width:1024px){
    font-size: 40px;
  }
  @media (max-width: 767px) {
    font-size: 24px;
  }
`

const Description = styled.p`
  color: #2b2b2b;
  line-height: 26px;
  margin-bottom: 20px;
  
  &.main-description{
    width: 70%;
    margin: 0 auto;
    padding-bottom: 40px;
    text-align: center;

  }
  @media (max-width:767px){
    &.main-description{
      width: 100%;
      padding-bottom: 20px;
    }
  }
`;

const Left = styled.div`
  width: 90%;
  @media (max-width:768px){
    width: 100%;
  }
`
const Right = styled.div``