import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { accountsConfig } from '../../../../../axiosConfig';
import { useLocation, useNavigate } from 'react-router-dom';

function ScholarShips() {
  const navigate = useNavigate()
  const location = useLocation();
  const isOfferPage = location.pathname === '/offers';
  const [getBlog, setBlog] = useState([])
  const [getDestination,setDestination] = useState([])
  const [error,setError] = useState({text:"",show:false})
  const [courseerror,setCourseError] = useState({text:"",show:false})
  const [selectedBlogOption, setBlogOption] = useState(null);
  const [selectedDestinationOption, setDestinationOption] = useState(null);
  useEffect(() => {
    accountsConfig.get("destinations/destination-suggestion/").then((res) => {
      if (res.data.app_data.StatusCode === 6000) {
        setDestination(res.data.app_data.data.map(des => ({
          value: des.id,
          label: des.country__name
        })));      
      } else {
        setDestination([]);
      }
    });
  }, []);

  useEffect(() => {
    console.log(selectedDestinationOption);
    
    if (selectedDestinationOption && selectedDestinationOption.value) {
      accountsConfig
        .get(`general/home-blog-suggestion/?destination=${selectedDestinationOption.value}`)
        .then((res) => {
          if (res.data.app_data.StatusCode === 6000) {
            setBlog(
              res.data.app_data.data.map((blog) => ({
                value: blog.id,
                label: blog.title,
              }))
            );
            console.log(res.data.app_data.data);
            
          } else {
            setBlog([]);
          }
        })
        .catch((err) => {
          console.error("Error fetching courses:", err);
          setBlog([]); 
        });
    } else {
      setBlog([]);
    }
  }, [selectedDestinationOption]); 
  

  // Course Selection 
  const handleBlogChange = (selectedOption) => {
    setBlogOption(selectedOption);
    if (courseerror.show){
      setCourseError({text:"",show:false})
    }
  };

   // Destination Selection 
   const handleDestination = (selectedOption) => {
    setDestinationOption(selectedOption);
    if (error.show){
      setError({text:"",show:false})
    }
  };

  // Submit answers
  const SubmitAnswer = ()=>{
    if (selectedDestinationOption===null){
      setError({text:"Please select a country",show:true})
      return
    }
    if (selectedBlogOption===null){
      setCourseError({text:"Please select a cource",show:true})
      return
    }
    
    setTimeout(() => {
      setError({ text:"", show: false });
    }, 3000);
    if (selectedBlogOption){
      navigate(`/blogs/${selectedBlogOption.value}`)
    }
    

  }

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 1000,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 1000,
    }),
  };

  return (
    <Section isOfferPage={isOfferPage}>
      <div className="select">
      <Select
        value={selectedDestinationOption}
        onChange={handleDestination}
        options={getDestination}
        placeholder="Select a study destination"
        className="basic-multi-select select"
        classNamePrefix="select"
        styles={customStyles} 
        menuPortalTarget={document.body} 
      />
      {error.show && <ErrorMsg>{error.text}</ErrorMsg>}
      </div>
      <div className="select">
      <Select
        value={selectedBlogOption}
        onChange={handleBlogChange}
        options={getBlog}
        placeholder="Select Scholarship"
        isSearchable
        styles={customStyles} 
        menuPortalTarget={document.body} 
      />
      {courseerror.show && <ErrorMsg>{courseerror.text}</ErrorMsg>}
      </div>
      
      <Button isOfferPage={isOfferPage} onClick={SubmitAnswer}>Search</Button>
    </Section>
  )
}

export default ScholarShips

const Section = styled.div`
  padding: ${props => props.isOfferPage ? '20px 20px' : '40px'};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  .select{
    width: 100%;
  }
  @media (max-width: 880px){
    flex-direction: column;
  }
  @media (max-width: 425px){
    padding: ${props => props.isOfferPage ? '20px 20px' : '40px 20px'};
    gap: ${props => props.isOfferPage ? '10px' : '30px'};
  }
`;

const Button = styled.a`
  cursor: pointer;
  font-size: 16px;
  border-radius: 108px;
  color: white;
  background-color: var(--headerColor);
  padding: 14px 25px;
  font-family: 'ubuntu-medium';
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 880px){
    padding: ${props => props.isOfferPage ? '12px 0px' : '14px 0px'};
    width: 100%;
  }
`;

const ErrorMsg = styled.p`
  font-size: smaller;
  color: red;
  margin-top: 2px;
  margin-bottom: -16px;
`