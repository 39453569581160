import React from "react";

//packages
import styled from "styled-components";

//imports
import HeaderEdit from "../HeaderEdit";
import Introduction from "../../screens/migrate/canada/Introduction";
import EntryProgram from "../../screens/migrate/canada/EntryProgram";
import PnpOverview from "../../screens/migrate/canada/PnpOverview";
import StartupVisa from "../../screens/migrate/canada/StartupVisa";
import WorkPermits from "../../screens/migrate/canada/WorkPermits";
import IntroductionAus from "../../screens/migrate/austrailia/IntroductionAus";
import Overview from "../../screens/migrate/austrailia/Overview";
import Footer from "../Footer";

//images
import bg from "../../../../../assets/images/migrate/flight.svg";
function WhyToAustralia() {
  return (
    <Container>
      <HeaderEdit backgroundCl="#00AEC0" />
      <IntroductionAus />
      <Overview />
      <Footer />
    </Container>
  )
}

export default WhyToAustralia

const Flight = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    display: block;
  }
`;
const Container = styled.div`
  position: relative;
  z-index: 1;
  /* background: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 10; */
`;
