import React, { useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import HeaderEdit from "../../includes/HeaderEdit";

import Banner from "../../includes/jobs/Banner";
import rightArrow from "../../../../../assets/images/jobs/icons/right-arrow.svg";
import upArrow from "../../../../../assets/images/icons/uparrow.svg";
import downArrow from "../../../../../assets/images/icons/downarrow.svg";
import plus from "../../../../../assets/images/icons/plus.svg";
import minus from "../../../../../assets/images/jobs/icons/minus.svg";
import filterIcon from "../../../../../assets/images/icons/filter.svg";
import arrow from "../../../../../assets/images/courseSearch/icons/arrow.svg";

import Nodata from "../../includes/nodata/Nodata";
import Footer from "../../includes/Footer";
import JobFilterModal from "../../../../modals/JobFilterModal";
import { useEffect } from "react";
import { accountsConfig } from "../../../../../axiosConfig";
import { industryData } from "../../../../general/commonArrays";
import SectionLoader from "../../../../general/loader/SectionLoader";

function JobSearchPage() {
  const location = useLocation();

  const [expanded, setexpanded] = useState(1);
  const [showMore, setshowMore] = useState("");
  const [searchedItems, setSearchedItems] = useState("");
  const [filterModal, setFilterModal] = useState(false);
  const [jobs, setjobs] = useState([]);

  //country
  const [country, setcountry] = useState("");
  const [countryList, setCountryList] = useState([]);

  //jobtitle
  const [jobtitle, setjobtitle] = useState("");
  const [jobtitleList, setJobtitleList] = useState([]);

  //Industry
  const [industry, setIndustry] = useState("");
  const [industryList, setIndustryList] = useState([]);

  //Employement Types
  const [type, setType] = useState("");
  const [typeList, setTypeList] = useState(industryData);

  // pagination
  const [paginationData, setpaginationData] = useState({});
  const [page, setpage] = useState(1);

  // experience
  const [minexp, setminexp] = useState(0);
  const [maxexp, setmaxexp] = useState(50);

  const [loading, setloading] = useState(false);

  const navigate = useNavigate();
  const test = [
    {
      value: "Engg",
      name: "Engg",
      id: 1,
      label: "test",
    },
    {
      value: "Soft",
      name: "Soft",
      id: 2,
      label: "test",
    },
    {
      value: "Design",
      name: "Design",
      id: 3,
      label: "test",
    },
    {
      value: "Engg",
      name: "Engg",
      id: 1,
      label: "test",
    },
    {
      value: "Soft",
      name: "Soft",
      id: 2,
      label: "test",
    },
    {
      value: "Design",
      name: "Design",
      id: 3,
      label: "test",
    },
  ];

  // job search from header
  const searchParams = new URLSearchParams(location.search);
  const searchValue = searchParams.get("search");

  const targetDivRef = useRef(null);
  const stickBtnRef = useRef(null);

  const formData = new FormData();

  // setting the filter nav as sticky in mobile version
  useEffect(() => {
    if (window.innerWidth <= 580) {
      const checkDivPosition = () => {
        const targetDiv = targetDivRef.current;
        if (!targetDiv) return;

        const rect = targetDiv.getBoundingClientRect();

        // Check if the top of the div is at the top of the viewport
        const isAtTop = rect.top <= 75;

        // Check if the top of the div is at the top of the viewport
        const notIsAtTop = rect.top >= 150;

        // Check if the bottom of the div is at the top of the viewport
        const isAtBottom = rect.bottom <= 0;

        // Check if the bottom of the div is at the top of the viewport
        const isNotAtBottom = rect.bottom >= 0;

        if (stickBtnRef.current) {
          if (notIsAtTop) {
            stickBtnRef.current.classList.remove("fixed");
          }

          if (isAtTop) {
            stickBtnRef.current.classList.add("fixed");
          }

          if (isAtBottom) {
            stickBtnRef.current.classList.add("d-none");
          }
          if (isNotAtBottom) {
            stickBtnRef.current.classList.remove("d-none");
          }
        }
      };

      // Call the function on scroll
      const handleScroll = () => {
        checkDivPosition();
      };

      // Attach scroll event listener
      window.addEventListener("scroll", handleScroll);

      // Initial check when the component mounts
      checkDivPosition();

      // Cleanup: Remove the event listener when the component unmounts
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  // job search from header
  useEffect(() => {
    if (searchValue) {
      setSearchedItems(searchValue);
    }
  }, [searchValue]);

  // fetch jobs
  useEffect(() => {
    setloading(true);
    setFormDataValues();
    accountsConfig
      .post("study/list-jobs/", formData, {
        params: {
          page: page,
        },
      })
      .then((response) => {
        const { StatusCode, data, pagination_data } = response.data.app_data;
        setloading(false);

        if (StatusCode === 6000) {
          setjobs(data);
          setpaginationData(pagination_data);
        } else {
          setjobs([]);
        }
      });
  }, [country, page, minexp, maxexp, searchedItems, jobtitle, industry, type]);

  const setFormDataValues = () => {
    if (country) {
      formData.append("country_id", country);
    }

    if (minexp) {
      formData.append("min_value", minexp);
    }

    if (maxexp) {
      formData.append("max_value", maxexp);
    }

    if (searchedItems) {
      formData.append("q", searchedItems);
    }

    if (jobtitle) {
      formData.append("job_title_id", jobtitle);
    }

    if (industry) {
      formData.append("job_industry_id", industry);
    }

    if (type) {
      formData.append("employement_type", type);
    }
  };

  //get countries
  useEffect(() => {
    accountsConfig.get(`study/get-countries/`, {}).then((res) => {
      const StatusCode = res.data.app_data.StatusCode;
      const Data = res.data.app_data.data;
      if (StatusCode == 6000) {
        setCountryList(Data);
      } else {
        setCountryList([]);
      }
    });
  }, []);

  //get job-title
  useEffect(() => {
    accountsConfig.get(`study/list-job-title/`, {}).then((res) => {
      const StatusCode = res.data.app_data.StatusCode;
      const Data = res.data.app_data.data;
      if (StatusCode == 6000) {
        setJobtitleList(Data);
      } else {
        setJobtitleList([]);
      }
    });
  }, []);

  //get Industry
  useEffect(() => {
    accountsConfig.get(`study/list-job-industry/`, {}).then((res) => {
      const StatusCode = res.data.app_data.StatusCode;
      const Data = res.data.app_data.data;
      if (StatusCode == 6000) {
        
        setIndustryList(Data);
      } else {
        setIndustryList([]);
      }
    });
  }, []);

  //handle country change
  const handleCountryChange = (value) => {
    if (country === parseInt(value)) {
      setcountry("");
    } else {
      setcountry(value);
    }
  };

  //handle job-title change
  const handleJobTitleChange = (value) => {
    if (jobtitle === value) {
      setjobtitle("");
    } else {
      setjobtitle(value);
    }
  };

  //handle Industry change
  const handleIndustryChange = (value) => {
    if (industry === value) {
      setIndustry("");
    } else {
      setIndustry(value);
    }
  };

  //handle type change
  const handleTypeChange = (value) => {
    if (type === value) {
      setType("");
    } else {
      setType(value);
    }
  };

  // handleExpand
  const handleExpand = (id) => {
    if (id === expanded) {
      setexpanded(0);
    } else {
      setexpanded(id);
    }
  };

  // handleExp
  const handleExp = (e) => {
    if (e.target.id === "max") {
      setmaxexp(e.target.value);
    } else {
      setminexp(e.target.value);
    }
  };

  // handleshow more in filter
  const handleShowMore = (id) => {
    if (showMore === id) {
      setshowMore("");
    } else {
      setshowMore(id);
    }
  };

  // handleFilterModal
  const handleFilterModal = () => {
    setFilterModal(true);
  };

  // set page for pagination
  const setPageForPagination = (pageNum) => {
    setpage(pageNum);
  };

  //clear all
  const clearAll = () => {
    setcountry("");
    setjobtitle("");
    setIndustry("");
    setType("");
  };

  // handleSearch
  const handleSearch = (e) => {
    setSearchedItems(e.target.value);
  };

  return (
    <Section>
      <HeaderEdit />
      <Container>
        <Banner handleChange={handleSearch} searchedItem={searchedItems} />
        <Showing>
          <Wrapper>
            <JobsSection>
              <SelectCourse>
                <TopBar>
                  <TopBarTitle>Filter</TopBarTitle>
                  <TopBarTitle className="clear" onClick={clearAll}>
                    Clear
                  </TopBarTitle>
                </TopBar>
                <FilterOptions>
                  {/* Job Title */}
                  <Cover>
                    <DropDown>
                      <DropdownTop onClick={() => handleExpand(1)}>
                        <FilterName>Job Title</FilterName>
                        <ArrowImg>
                          <img
                            src={expanded === 1 ? upArrow : downArrow}
                            alt=""
                          />
                        </ArrowImg>
                      </DropdownTop>
                      {expanded === 1 && (
                        <DropdownContainer>
                          <DropDownItems>
                            {/* Listing option if the len < 5 + show more text */}
                            {jobtitleList &&
                              showMore !== 1 &&
                              jobtitleList?.length >= 5 &&
                              jobtitleList?.map((item, indx) => {
                                if (indx < 5 && indx !== 4) {
                                  return (
                                    <DropDownItem>
                                      <Radio
                                        type="radio"
                                        name="job-title"
                                        value={item.id}
                                        onClick={() =>
                                          handleJobTitleChange(item.id)
                                        }
                                        checked={jobtitle === item.id}
                                      />
                                      <DropDownItemName>
                                        {item.name}
                                      </DropDownItemName>
                                    </DropDownItem>
                                  );
                                } else if (indx === 4) {
                                  return (
                                    <ShowMore onClick={() => handleShowMore(1)}>
                                      <img src={plus}></img>
                                      <span>Show More</span>
                                    </ShowMore>
                                  );
                                }
                              })}

                            {/* Listing all option and checking the showmore is active  */}
                            {jobtitleList &&
                              (jobtitleList?.length <= 5 || showMore === 1) &&
                              jobtitleList?.map((item, indx) => (
                                <DropDownItem>
                                  <Radio
                                    type="radio"
                                    name="country"
                                    value={item.id}
                                    onClick={() =>
                                      handleJobTitleChange(item.id)
                                    }
                                    checked={jobtitle === item.id}
                                  />
                                  <DropDownItemName>
                                    {item.name}
                                  </DropDownItemName>
                                </DropDownItem>
                              ))}
                            {showMore === 1 && (
                              <ShowMore onClick={() => handleShowMore(1)}>
                                <img src={minus}></img>
                                <span>Show Less</span>
                              </ShowMore>
                            )}
                          </DropDownItems>
                        </DropdownContainer>
                      )}
                    </DropDown>
                  </Cover>

                  {/* Country */}
                  <Cover>
                    <DropDown>
                      <DropdownTop onClick={() => handleExpand(2)}>
                        <FilterName>Country</FilterName>
                        <ArrowImg onClick={() => handleExpand(2)}>
                          <img
                            src={expanded === 2 ? upArrow : downArrow}
                            alt=""
                          />
                        </ArrowImg>
                      </DropdownTop>
                      {expanded === 2 && (
                        <DropdownContainer>
                          <DropDownItems>
                            {/* Listing option if the len < 5 + show more text */}
                            {countryList &&
                              showMore !== 2 &&
                              countryList?.length >= 5 &&
                              countryList?.map((item, indx) => {
                                if (indx < 5 && indx !== 4) {
                                  return (
                                    <DropDownItem>
                                      <Radio
                                        type="radio"
                                        name="country"
                                        value={item.id}
                                        onClick={() =>
                                          handleCountryChange(item.id)
                                        }
                                        checked={country === item.id}
                                      />
                                      <DropDownItemName>
                                        {item.name}
                                      </DropDownItemName>
                                    </DropDownItem>
                                  );
                                } else if (indx === 4) {
                                  return (
                                    <ShowMore onClick={() => handleShowMore(2)}>
                                      <img src={plus}></img>
                                      <span>Show More</span>
                                    </ShowMore>
                                  );
                                }
                              })}

                            {/* Listing all option and checking the showmore is active  */}
                            {countryList &&
                              (countryList?.length <= 5 || showMore === 2) &&
                              countryList?.map((item, indx) => (
                                <DropDownItem>
                                  <Radio
                                    type="radio"
                                    name="country"
                                    value={item.id}
                                    onClick={() => handleCountryChange(item.id)}
                                    checked={country === item.id}
                                  />
                                  <DropDownItemName>
                                    {item.name}
                                  </DropDownItemName>
                                </DropDownItem>
                              ))}
                            {showMore === 2 && (
                              <ShowMore onClick={() => handleShowMore(2)}>
                                <img src={minus}></img>
                                <span>Show Less</span>
                              </ShowMore>
                            )}
                          </DropDownItems>
                        </DropdownContainer>
                      )}
                    </DropDown>
                  </Cover>

                  {/* Experience */}
                  <Cover>
                    <DropDown>
                      <DropdownTop onClick={() => handleExpand(3)}>
                        <FilterName>Experience</FilterName>
                        <ArrowImg onClick={() => handleExpand(3)}>
                          <img
                            src={expanded === 3 ? upArrow : downArrow}
                            alt=""
                          />
                        </ArrowImg>
                      </DropdownTop>
                      {expanded === 3 && (
                        <DropdownContainer>
                          <DropDownItems>
                            <Labels>
                              <Label>Min: {minexp}</Label>
                              <Label>Max: {maxexp}</Label>
                            </Labels>
                            <Range
                              type="range"
                              min="0"
                              max="50"
                              id="min"
                              value={minexp}
                              onChange={(e) => handleExp(e)}
                            />
                            <Range
                              type="range"
                              min="0"
                              max="50"
                              id="max"
                              value={maxexp}
                              onChange={(e) => handleExp(e)}
                            />
                          </DropDownItems>
                        </DropdownContainer>
                      )}
                    </DropDown>
                  </Cover>

                  {/* Industry */}
                  <Cover>
                    <DropDown>
                      <DropdownTop onClick={() => handleExpand(4)}>
                        <FilterName>Industry</FilterName>
                        <ArrowImg onClick={() => handleExpand(4)}>
                          <img
                            src={expanded === 4 ? upArrow : downArrow}
                            alt=""
                          />
                        </ArrowImg>
                      </DropdownTop>
                      {expanded === 4 && (
                        <DropdownContainer>
                          <DropDownItems>
                            {/* Listing option if the len < 5 + show more text */}
                            {industryList &&
                              showMore !== 4 &&
                              industryList?.length >= 5 &&
                              industryList?.map((item, indx) => {
                                if (indx < 5 && indx !== 4) {
                                  return (
                                    <DropDownItem key={indx}>
                                      <Radio
                                        type="radio"
                                        name="industry"
                                        value={item.id}
                                        onClick={() =>
                                          handleIndustryChange(item.id)
                                        }
                                        checked={industry === item.id}
                                      />
                                      <DropDownItemName>
                                        {item.name}
                                      </DropDownItemName>
                                    </DropDownItem>
                                  );
                                } else if (indx === 4) {
                                  return (
                                    <ShowMore onClick={() => handleShowMore(4)}>
                                      <img src={plus}></img>
                                      <span>Show More</span>
                                    </ShowMore>
                                  );
                                }
                              })}

                            {/* Listing all option and checking the showmore is active  */}
                            {industryList &&
                              (industryList?.length < 5 || showMore === 4) &&
                              industryList?.map((item, indx) => (
                                <DropDownItem>
                                  <Radio
                                    type="radio"
                                    name="industry"
                                    value={item.id}
                                    onClick={() =>
                                      handleIndustryChange(item.id)
                                    }
                                    checked={industry === item.id}
                                  />
                                  <DropDownItemName>
                                    {item.name}
                                  </DropDownItemName>
                                </DropDownItem>
                              ))}
                            {showMore === 4 && (
                              <ShowMore onClick={() => handleShowMore(4)}>
                                <img src={minus}></img>
                                <span>Show Less</span>
                              </ShowMore>
                            )}
                          </DropDownItems>
                        </DropdownContainer>
                      )}
                    </DropDown>
                  </Cover>

                  {/* Employment type */}
                  <Cover>
                    <DropDown>
                      <DropdownTop onClick={() => handleExpand(6)}>
                        <FilterName>Employment type</FilterName>
                        <ArrowImg onClick={() => handleExpand(6)}>
                          <img
                            src={expanded === 6 ? upArrow : downArrow}
                            alt=""
                          />
                        </ArrowImg>
                      </DropdownTop>
                      {expanded === 6 && (
                        <DropdownContainer>
                          <DropDownItems>
                            {/* Listing option if the len < 5 + show more text */}
                            {typeList &&
                              showMore !== 6 &&
                              typeList?.length >= 6 &&
                              typeList?.map((item, indx) => {
                                if (indx < 6 && indx !== 4) {
                                  return (
                                    <DropDownItem>
                                      <Radio
                                        type="radio"
                                        name="type"
                                        value={item.value}
                                        onClick={() =>
                                          handleTypeChange(item.value)
                                        }
                                        checked={type === item.value}
                                      />
                                      <DropDownItemName>
                                        {item.name}
                                      </DropDownItemName>
                                    </DropDownItem>
                                  );
                                } else if (indx === 4) {
                                  return (
                                    <ShowMore onClick={() => handleShowMore(6)}>
                                      <img src={plus}></img>
                                      <span>Show More</span>
                                    </ShowMore>
                                  );
                                }
                              })}

                            {/* Listing all option and checking the showmore is active  */}
                            {typeList &&
                              (typeList?.length <= 6 || showMore === 6) &&
                              typeList?.map((item, indx) => (
                                <DropDownItem>
                                  <Radio
                                    type="radio"
                                    name="type"
                                    value={item.value}
                                    onClick={() => handleTypeChange(item.value)}
                                    checked={type === item.value}
                                  />
                                  <DropDownItemName>
                                    {item.name}
                                  </DropDownItemName>
                                </DropDownItem>
                              ))}

                            {showMore === 6 && (
                              <ShowMore onClick={() => handleShowMore(6)}>
                                <img src={minus}></img>
                                <span>Show Less</span>
                              </ShowMore>
                            )}
                          </DropDownItems>
                        </DropdownContainer>
                      )}
                    </DropDown>
                  </Cover>
                </FilterOptions>
              </SelectCourse>
              <MobileFilter ref={stickBtnRef}>
                <Filter onClick={handleFilterModal}>
                  <Arrow>
                    <img src={filterIcon} alt="" />
                  </Arrow>
                  Filter
                </Filter>
              </MobileFilter>

              <ShowingJobs ref={targetDivRef}>
                {searchedItems && (
                  <Head>
                    <SearchedItem>{`Jobs : "${searchedItems}"`}</SearchedItem>
                    <FoundItemCount>({jobs?.length} Jobs)</FoundItemCount>
                  </Head>
                )}

                <Cards type={jobs?.length}>
                  {loading ? (
                    <SectionLoader />
                  ) : jobs?.length == 0 ? (
                    <Nodata />
                  ) : (
                    jobs?.map((item) => (
                      <Card>
                        <Box>
                          <Left>
                            <JobTitle>
                              {item?.jobs} at
                              <span>{item?.name}</span>
                            </JobTitle>
                            <LocationInfo>
                              <span>Location :</span> {item?.location}
                            </LocationInfo>
                          </Left>
                          <Right>
                            <Type>{item?.job_level}</Type>
                          </Right>
                        </Box>
                        <Box className="row">
                          <Skills>
                            <span>Skills Needed :</span>
                            {item?.skills_needed}
                          </Skills>
                          <Experience>
                            <span>Experience Required :</span>
                            {item?.experience} years
                          </Experience>
                          <Qualification>
                            <span>Qualification :</span>
                            {item?.qualifications}
                          </Qualification>
                          <Specialization>
                            <span>Specialization :</span>
                            {item?.specialization}
                          </Specialization>
                        </Box>
                        <Box className="last btn">
                          <ViewJobBtn
                            onClick={() => navigate(`/job/${item?.id}`)}
                          >
                            View Job <img src={rightArrow} alt="" />
                          </ViewJobBtn>
                        </Box>
                      </Card>
                    ))
                  )}
                </Cards>
                <Paginator>
                  <PaginatorInner>
                    {paginationData?.has_previous_page && (
                      <LeftArr
                        onClick={() =>
                          setPageForPagination(paginationData?.current_page - 1)
                        }
                      >
                        <img src={arrow} alt="" />
                      </LeftArr>
                    )}
                    {paginationData?.current_page - 1 > 0 && (
                      <Page
                        onClick={() =>
                          setPageForPagination(paginationData?.current_page - 1)
                        }
                      >
                        {paginationData?.current_page - 1}
                      </Page>
                    )}
                    <Page className="active">
                      {paginationData?.current_page}
                    </Page>
                    {paginationData?.current_page + 1 <=
                      paginationData?.total_pages && (
                      <Page
                        onClick={() =>
                          setPageForPagination(paginationData?.current_page + 1)
                        }
                      >
                        {paginationData?.current_page + 1}
                      </Page>
                    )}
                    {paginationData?.has_next_page && <Page>..</Page>}
                    {paginationData?.has_next_page && (
                      <RightArr
                        onClick={() =>
                          setPageForPagination(paginationData?.current_page + 1)
                        }
                      >
                        <img src={arrow} alt="" />
                      </RightArr>
                    )}
                  </PaginatorInner>
                </Paginator>
              </ShowingJobs>
            </JobsSection>
          </Wrapper>
        </Showing>
      </Container>

      <JobFilterModal
        isModal={filterModal}
        setModal={setFilterModal}
        expanded={expanded}
        test={test}
        maxexp={maxexp}
        minexp={minexp}
        showMore={showMore}
        country={country}
        type={type}
        industry={industry}
        jobtitle={jobtitle}
        countryList={countryList}
        jobtitleList={jobtitleList}
        industryList={industryList}
        typeList={typeList}
        clear={clearAll}
        handleCountryChange={handleCountryChange}
        handleExpand={handleExpand}
        handleShowMore={handleShowMore}
        handleExp={handleExp}
        handleJobTitleChange={handleJobTitleChange}
        handleIndustryChange={handleIndustryChange}
        handleTypeChange={handleTypeChange}
      />

      <Footer />
    </Section>
  );
}

export default JobSearchPage;

const Section = styled.div``;

const Container = styled.div``;

const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;
const Content = styled.div``;

const Showing = styled.div`
  padding: 0 0;
  background-color: #f8f8f8;
  padding-top: 50px;
  @media all and (max-width: 480px) {
    padding: 0;
    padding-bottom: 50px;
    padding-top: 20px;
  }
`;

const Cover = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid #d9d9d9;
`;

const FilterName = styled.span`
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const DropDown = styled.div`
  color: #006492;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const DropdownTop = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const JobsSection = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 50px;
  @media all and (max-width: 1780px) {
    gap: 30px;
  }
  @media all and (max-width: 1080px) {
    flex-wrap: wrap;
  }

  @media (max-width: 580px) {
    margin-top: 0;
  }
`;
const SelectCourse = styled.div`
  padding: 10px;
  width: 16%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media all and (max-width: 1080px) {
    width: 100%;
  }
  @media all and (max-width: 480px) {
    display: none;
  }
`;

const ShowingJobs = styled.div`
  width: 80%;
  margin-bottom: 25px;
  @media all and (max-width: 1080px) {
    width: 100%;
  }
`;
const Head = styled.div`
  width: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background-color: #fff;
  box-sizing: border-box;

  &::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 5px;
    background-color: #00aec0;
    left: 0;
  }
`;

const FoundItemCount = styled.div`
  color: #8a8a8a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (max-width: 450px) {
    font-size: 14px;
  }
`;

const SearchedItem = styled.div`
  color: #696969;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (max-width: 450px) {
    font-size: 14px;
  }
`;

const Cards = styled.div`
  /* display: grid;
  grid-template-columns: ${({ type }) =>
    type == 0 ? "1fr" : "1fr 1fr 1fr"}; */
  display: grid;
  grid-template-columns: "1fr";
  margin-top: 20px;
  gap: 20px;
  @media all and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 15px 20px;
  display: flex;
  box-sizing: border-box;
  gap: 10px;
  flex-direction: column;

  @media (max-width: 1200px) {
    flex-wrap: wrap;
    row-gap: 20px;
  }

  @media all and (max-width: 768px) {
    width: 100%;
  }

  @media all and (max-width: 675px) {
    flex-direction: column;
  }
`;

const FilterOptions = styled.form`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 25px;
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 90%;
  padding: 0 8px;
  width: 100%;
  box-sizing: border-box;
`;

const DropDownItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
`;

const DropDownItem = styled.div`
  display: flex;
  width: 100%;
  gap: 5px;
  align-items: center;
`;

const DropDownItemName = styled.p`
  color: #565757;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px 10px;
  background-color: #00aec0;
  border-radius: 4px;
`;

const TopBarTitle = styled.div`
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: 'ubuntu-bold';

  &.clear {
    cursor: pointer;
  }
`;

const Radio = styled.input`
  width: 13px;
  height: 13px;
`;

const ArrowImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    width: 100%;
    display: block;
    height: auto;
  }
`;

const ShowMore = styled.div`
  display: flex;
  gap: 8px;
  margin: 5px 0;
  cursor: pointer;

  span {
    color: #00aec0;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  /* img {
    width: 4px;
    height: 1px;
  } */
`;

const Paginator = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PaginatorInner = styled.div`
  display: flex;
  gap: 10px;
  margin: 30px auto;
`;

const LeftArr = styled.div`
  width: 23px;
  height: 23px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: #fff;
  border: 0.5px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    display: block;
    width: auto;
    object-fit: contain;
  }
`;

const RightArr = styled.div`
  width: 23px;
  height: 23px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: #fff;
  border: 0.5px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    display: block;
    width: auto;
    object-fit: contain;
    transform: rotate(180deg);
  }
`;

const Page = styled.div`
  width: 23px;
  height: 23px;
  flex-shrink: 0;
  border-radius: 50%;
  color: #00aec0;
  background-color: #fff;
  border: 0.5px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.active {
    background-color: #00aec0;
    color: white;
  }
`;

const Box = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  border-bottom: 1px solid #e7f0fa;
  padding: 10px 0;
  position: relative;

  &.row {
    flex-direction: row;
    gap: 15px;
  }

  &.btn {
    justify-content: flex-end;
    border: none;
    align-items: center;
    padding: 5px 0px 0px;
  }

  @media screen and (max-width: 850px) {
    &.row {
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  @media screen and (max-width: 650px) {
    &.row {
      column-gap: 0px;
    }
  }

  @media screen and (max-width: 480px) {
    &.btn {
      justify-content: center;
    }
  }

  @media screen and (max-width: 375px) {
    &.row {
      row-gap: 20px;
    }
  }
`;

const Left = styled.div`
  display: flex;
  width: 80%;
  flex-direction: column;
  gap: 10px;

  @media screen and (max-width: 425px) {
    width: 100%;
  }
`;

const Right = styled.div`
  display: flex;
  width: 20%;
  justify-content: flex-end;

  @media screen and (max-width: 600px) {
    align-items: flex-end;
  }

  @media screen and (max-width: 425px) {
    width: 35%;
    position: absolute;
    bottom: 10px;
    right: 0px;
  }
`;

const JobTitle = styled.div`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  width: 100%;
  text-align: center;

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: flex-start;
    text-align: left;
  }

  span {
    font-size: 18px;
    color: #565757;
    text-align: center;
    padding-left: 5px;

    @media screen and (max-width: 600px) {
      font-size: 17px;
      text-align: left;
      padding-left: 0px;
    }
  }
`;

const LocationInfo = styled.div`
  width: 100%;
  color: #909090;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #00aec0;
  }

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`;

const Type = styled.div`
  padding: 2px 12px;
  font-size: 15px;
  text-transform: capitalize;
  background-color: rgba(231, 240, 250, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00aec0;
  height: 35px;
  box-sizing: border-box;

  @media screen and (max-width: 600px) {
    font-size: 13px;
    padding: 3px 6px;
    height: 20px;
    align-items: end;
  }
`;

const Skills = styled.div`
  display: flex;
  gap: 8px;
  flex: 1;
  flex-direction: column;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  color: #909090;

  span {
    color: #565757;
  }

  @media screen and (max-width: 850px) {
    width: 45%;
    flex: unset;
  }
  @media screen and (max-width: 650px) {
    width: 50%;
  }
  @media screen and (max-width: 400px) {
    font-size: 13px;
  }

  @media screen and (max-width: 375px) {
    width: 100%;
    margin: 0 auto;
    text-align: left;
  }
`;

const Experience = styled.div`
  display: flex;
  gap: 8px;
  flex: 1;
  flex-direction: column;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  color: #909090;

  @media screen and (max-width: 850px) {
    width: 45%;
    flex: unset;
  }

  @media screen and (max-width: 650px) {
    width: 50%;
  }

  @media screen and (max-width: 400px) {
    font-size: 13px;
  }

  @media screen and (max-width: 375px) {
    width: 100%;
    margin: 0 auto;
    text-align: left;
  }

  span {
    color: #565757;
  }
`;
const Qualification = styled.div`
  display: flex;
  gap: 8px;
  flex: 1;
  flex-direction: column;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  color: #909090;

  @media screen and (max-width: 850px) {
    width: 45%;
    flex: unset;
  }

  @media screen and (max-width: 650px) {
    width: 50%;
  }

  @media screen and (max-width: 400px) {
    font-size: 13px;
  }

  @media screen and (max-width: 375px) {
    width: 100%;
    margin: 0 auto;
    text-align: left;
  }

  span {
    color: #565757;
  }
`;
const Specialization = styled.div`
  display: flex;
  gap: 8px;
  flex: 1;
  flex-direction: column;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  color: #909090;

  @media screen and (max-width: 850px) {
    width: 45%;
    flex: unset;
  }

  @media screen and (max-width: 650px) {
    width: 50%;
  }

  @media screen and (max-width: 400px) {
    font-size: 13px;
  }

  @media screen and (max-width: 375px) {
    width: 100%;
    margin: 0 auto;
    text-align: left;
  }

  span {
    color: #565757;
  }
`;

const ViewJobBtn = styled.button`
  background-color: #00aec0;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 7px;
  border: none;
  outline: none;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    font-size: 13px;
    padding: 9px 18px;
  }
`;

const Range = styled.input`
  width: 100%;
  background-color: #00aec0;
  -webkit-appearance: none;
  appearance: none;
  height: 2px;
  position: absolute;
  bottom: 0;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background-color: #00aec0;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: relative;
    z-index: 5;
    cursor: pointer;
  }

  &::-webkit-slider-runnable-track {
    position: relative;
    z-index: 1;
  }

  &#max {
    &::-webkit-slider-runnable-track {
      background-color: red;
      height: 0px;
    }

    &::-webkit-slider-thumb {
      vertical-align: middle;
      position: relative;
      transform: translateY(-50%);
    }
  }
`;

const Labels = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0 12px;
`;

const Label = styled.p`
  font-size: 14px;
  font-style: normal;
  color: #2c2c2c;
  margin: 0px;
  padding: 0px;
`;

const MobileFilter = styled.div`
  display: none;
  @media all and (max-width: 480px) {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 50px;
    width: 100%;
    padding: 0 20px;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    position: sticky;
    top: 10px;
    z-index: 3;
  }

  &.fixed {
    position: fixed;
    width: 90%;
    top: 90px;
  }

  &.d-none {
    display: none;
  }
`;
const Arrow = styled.div`
  color: #00b0c2;
  font-size: 18px;
  margin-right: 8px;
`;

const Filter = styled.div`
  font-size: 15px;
  width: 150px;
  display: flex;
  color: #575657;
  cursor: pointer;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
