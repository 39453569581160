import React from "react";
import WhyStudyAbroad from "./includes/WhyStudyAbroad";
import styled from "styled-components";
import HeaderEdit from "../../includes/HeaderEdit";
import Guidelines from "./includes/Guidelines";
import Timeline from "./includes/Timeline";
import ThingsToCheck from "./includes/ThingsToCheck";
import Faq from "./includes/Faq";
import Footer from "../../includes/Footer";

export default function StudyAbroad() {
  return (
    <Container>
      <HeaderEdit backgroundCl="#00AEC0" />
      <WhyStudyAbroad />
      <Guidelines />
      <Timeline />
      <ThingsToCheck />
      <Faq />
      <Footer />
    </Container>
  );
}

const Container = styled.div``;
const Why = styled.div`
  margin-top: 120px;
`;
