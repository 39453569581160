import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { accountsConfig } from '../../../../../axiosConfig';
import { useLocation, useNavigate } from 'react-router-dom';

function Course() {
  const navigate = useNavigate()
  const location = useLocation();
  const isOfferPage = location.pathname === '/offers';
  const [getSubCourseList,setSubCourseList] = useState([])
  const [getDestination,setDestination] = useState([])
  const [error,setError] = useState({text:"",show:false})
  const [courseerror,setCourseError] = useState({text:"",show:false})
  const [selectedSubCourseOption, setSelectedSubCourseOption] = useState(null);
  const [selectedCountryOption, setSelectedCountryOption] = useState(null);
  useEffect(() => {
    accountsConfig.get("study/get-countries/").then((res) => {
      if (res.data.app_data.StatusCode === 6000) {
        setDestination(res.data.app_data.data.map(country => ({
          value: country.id,
          label: country.name
        })));      
      } else {
        setDestination([]);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedCountryOption && selectedCountryOption.value) {
      accountsConfig
        .get(`study/get-courses-related-country/?country=${selectedCountryOption.value}`)
        .then((res) => {
          const data = res.data.app_data.data;
          if (res.data.app_data.StatusCode === 6000) {
            setSubCourseList(
              data.map((course) => ({
                value: course.id,
                label: course.name,
              }))
            );
          } else {
            setSubCourseList([]);
          }
        })
        .catch((err) => {
          console.error("Error fetching courses:", err);
          setSubCourseList([]); 
        });
    } else {
      setSubCourseList([]);
    }
  }, [selectedCountryOption]); 
  

  // Course Selection 
  const handleCourseChange = (selectedOption) => {
    setSelectedSubCourseOption(selectedOption);
    if (courseerror.show){
      setCourseError({text:"",show:false})
    }
  };

   // Destination Selection 
   const handleDestination = (selectedOption) => {
    setSelectedCountryOption(selectedOption);
    if (error.show){
      setError({text:"",show:false})
    }
  };

  // Submit answers
  const SubmitAnswer = ()=>{
    if (selectedCountryOption===null){
      setError({text:"Please select a country",show:true})
      return
    }
    if (selectedSubCourseOption===null){
      setCourseError({text:"Please select a cource",show:true})
      return
    }
    
    setTimeout(() => {
      setError({ text:"", show: false });
    }, 3000);
    if (selectedSubCourseOption !=null && selectedCountryOption !=null){
      navigate(`/find-a-course?study_area=${selectedSubCourseOption.value}&&country=${selectedCountryOption.value}`)
    }
    

  }

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 1000,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 1000,
    }),
  };

  return (
    <Section isOfferPage={isOfferPage}>
      <div className="select">
      <Select
        value={selectedCountryOption}
        onChange={handleDestination}
        options={getDestination}
        placeholder="Select a study destination"
        className="basic-multi-select select"
        classNamePrefix="select"
        styles={customStyles} 
        menuPortalTarget={document.body} 
      />
      {error.show && <ErrorMsg>{error.text}</ErrorMsg>}
      </div>
      <div className="select">
      <Select
        value={selectedSubCourseOption}
        onChange={handleCourseChange}
        options={getSubCourseList}
        placeholder="Enter course subject e.g. Law"
        isSearchable
        styles={customStyles} 
        menuPortalTarget={document.body} 
      />
      {courseerror.show && <ErrorMsg>{courseerror.text}</ErrorMsg>}
      </div>
      
      <Button isOfferPage={isOfferPage} onClick={SubmitAnswer}>Search</Button>
    </Section>
  );
}

export default Course;

const Section = styled.div`
  padding: ${props => props.isOfferPage ? '20px 20px' : '40px'};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  .select{
    width: 100%;
  }
  @media (max-width: 880px){
    flex-direction: column;
  }
  @media (max-width: 425px){
    padding: ${props => props.isOfferPage ? '20px 20px' : '40px 20px'};
    gap: ${props => props.isOfferPage ? '10px' : '30px'};
  }
`;

const Button = styled.a`
  cursor: pointer;
  font-size: 16px;
  border-radius: 108px;
  color: white;
  background-color: var(--headerColor);
  padding: 14px 25px;
  font-family: 'ubuntu-medium';
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 880px){
    padding: ${props => props.isOfferPage ? '12px 0px' : '14px 0px'};
    width: 100%;
  }
`;

const ErrorMsg = styled.p`
  font-size: smaller;
  color: red;
  margin-top: 2px;
  margin-bottom: -16px;
`