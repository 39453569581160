import React from "react";
import styled from "styled-components";

//images
import feat1 from "../../../../../../assets/images/webinars/featured1.jpg";
import feat2 from "../../../../../../assets/images/webinars/featured2.jpg";

export default function FeaturedWebinars({ getData }) {
  return (
    <Container>
      <Title>Featured Webinars</Title>
      <Deasc>
      Attend our webinars on Study Abroad opportunities
      and possibilities. Interact with our experts to gain
      insights and guidance on how to forge your path to international
      education and career success.
      </Deasc>
      <Webinars>
        {getData.map(
          (item, index) =>
            item.is_featured && (
              <Item key={index}>
                <ImageContainer>
                  <img src={item.thumbnail} alt="" />
                </ImageContainer>
                <Watch>
                  <Buttons>
                    <Button>
                      <Span>Watch Now</Span>
                      <Arrow>
                        <i class="ri-arrow-right-line"></i>
                      </Arrow>
                    </Button>
                  </Buttons>
                  <Tag>{item.title}</Tag>
                </Watch>
              </Item>
            )
        )}
      </Webinars>
    </Container>
  );
}
const Container = styled.div`
  margin-top: 30px;
  background-color: #fff;
  padding: 40px;
`;
const Title = styled.h2`
  color: var(--blue);
  text-align: center;
  margin-bottom: 20px;
`;
const Deasc = styled.p`
  color: #696984;
  text-align: center;
  margin-bottom: 50px;
`;
const Webinars = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 480px) {
    display: block;
  }
`;
const Item = styled.div`
  position: relative;
  width: 30%;
  @media (max-width: 768px) {
    width: 48%;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;
const ImageContainer = styled.div`
  img {
    width: 100%;
    display: block;
  }
`;

const Watch = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -15%);
  width: 100%;
`;
const Buttons = styled.div`
  display: flex;
  justify-content: center;
`;
const Button = styled.div`
  display: flex;
  justify-content: center;
  font-size: 14px;
  gap: 10px;
  border: 1px solid #fff;
  height: 40px;
  width: 130px;
  cursor: pointer;
  color: #fff;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  align-items: center;
`;
const Span = styled.div``;
const Arrow = styled.div``;
const Tag = styled.div`
  text-align: center;
  background-color: #eca903cc;
  color: #fff;
  padding: 20px;
  width: 80%;
  margin: 0 auto;
  font-size: 14px;
  @media (max-width: 480px) {
    width: 100%;
    box-sizing: border-box;
  }
`;
