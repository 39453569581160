import React from "react";
import styled from "styled-components";

import clock from "../../../../../assets/images/destination/icons/clock.svg";

function PartimeContent({ data }) {
  return (
    <Section>
      {data?.map((item) => (
        <Item>
          <Icon>
            <img src={clock} alt="" />
          </Icon>
          <Value>{item?.title}</Value>
        </Item>
      ))}
    </Section>
  );
}

export default PartimeContent;

const Section = styled.div`
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap:10px;

  @media screen and (max-width: 768px) {
    padding: 12px 10px;
  }
`;

const Item = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Value = styled.div``;
