import React, { useEffect, useState } from "react";
// import { newevents } from "../../../general/commonArrays";
import { styled } from "styled-components";
import tele from "../../../../assets/images/events/tele.png";
import mob from "../../../../assets/images/events/mob.png";
import email from '../../../../assets/icons/email.svg'
import Slider from "react-slick";
import { accountsConfig } from "../../../../axiosConfig";
import arrow_botton from '../../../../assets/icons/arrow_botton-white.svg'
import { useNavigate } from "react-router-dom";

function Events() {
  const navigate = useNavigate()
  const [newevents,setNewevents] = useState([])
  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 2000,
    autoplaySpeed: 2000,
    speed: 500,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    accountsConfig.get("general/list-news-events/?limit=5").then((res) => {
        const data = res.data.app_data.data;
        if (res.data.app_data.StatusCode === 6000) {
            setNewevents(data);
        } else {
            setNewevents([]);
        }
    });
}, []);
  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <Title>News & Events</Title>
          <EventCards>
            {newevents.map((item, index) => (
              <Card key={index}>
                <ImageContainer>
                  <img src={item?.thumbnail} alt={item?.title} />
                </ImageContainer>
                <TitleSection>
                  <BannerTitle>{item?.title} </BannerTitle>
                  {item.criteria!==null&&item.criteria.length>0&&item.criteria.map((item) => (
                    <div className="flex">
                      <strong>-</strong>
                      <span>{item?.criteria} </span>
                    </div>
                  ))}
                </TitleSection>
                {/* <ReadMore>Read More</ReadMore> */}
              </Card>
            ))}
          </EventCards>
          <MobEvent>
            <Slider {...settings}>
              {newevents.map((item, index) => (
                <Card key={index}>
                  <ImageContainer>
                    <img src={item?.thumbnail} alt={item?.title} />
                  </ImageContainer>
                  <TitleSection>
                    <BannerTitle>{item?.title} </BannerTitle>
                    {item.criteria!==null&&item.criteria.length>0&&item.criteria.map((item) => (
                      <div className="flex">
                        <strong>-</strong>
                        <span>{item?.criteria} </span>
                      </div>
                    ))}
                  </TitleSection>
                  {/* <ReadMore>Read More</ReadMore> */}
                </Card>
              ))}
            </Slider>
          </MobEvent>
          <ViewAll onClick={()=>navigate('/news-and-events')}>
             <p><span>View All</span> <img src={arrow_botton} alt="" /></p>
             </ViewAll>

        </Content>
      </Wrapper>
      <Cutout></Cutout>
      <Contact>
        <Cover className="line shake">
          <Icon className="rise-shake">
            <img src={tele} alt="telephone" />
          </Icon>
          <CoverTitle className="name">
            <Text>Contact Number</Text>
            <Atag href="tel:+918943280333">+918943280333</Atag>
          </CoverTitle>
        </Cover>
        <Cover className="shake">
          <Icon className="rise-shake">
            <img src={mob} alt="telephone" />
          </Icon>
          <CoverTitle>
            <Text>Mobile </Text>
            <Atag href="tel:+919645020503">+919645020503</Atag>
          </CoverTitle>
        </Cover>
        <Cover className="line shake name">
          <Icon className="rise-shake">
            <img src={email} alt="telephone" />
          </Icon>
          <CoverTitle className="">
            <Text>Email</Text>
            <Atag href="mailto:info@mioc.in">info@mioc.in</Atag>
          </CoverTitle>
        </Cover>
      </Contact>
    </Container>
  );
}

export default Events;

const Container = styled.div`
  padding: 80px 0 0 0;
  position: relative;
  background: #fff;
  z-index: 10;
  @media all and (max-width: 480px) {
    padding: 80px 0 10px 0;
  }
`;
const Wrapper = styled.div``;
const Content = styled.div``;
const MobEvent = styled.div`
  display: none;
  @media all and (max-width: 768px) {
    display: block;
  }
`;
const Title = styled.h1`
  color: #fff;
  margin-bottom: 10px;
`;
const Cutout = styled.div`
  background: linear-gradient(
    90deg,
    rgba(0, 100, 147, 1) 0%,
    rgba(0, 177, 195, 1) 100%
  );
  z-index: 900;
  width: 100%;
  height: 200px;
  position: absolute;
  top: 0;
  z-index: -1;
`;
const EventCards = styled.div`
  display: flex;
  justify-content: space-between;
  @media all and (max-width: 980px) {
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
  @media all and (max-width: 980px) {
    display: none;
  }
`;
const Card = styled.div`
  width: 25%;
  margin-right: 20px;
  background: #eae9e9;
  position: relative;
  padding-bottom: 20px;
  @media all and (max-width: 980px) {
    width: 31%;
    margin-bottom: 20px;
    height: 420px;
  }
  @media all and (max-width: 768px) {
    min-height: 556px;
    width: 90% !important;
    margin: 0 auto;
  }
  @media all and (max-width: 480px) {
    width: 100% !important;
  }
`;
const ImageContainer = styled.div`
  img {
    display: block;
    width: 100%;
  }
`;
const TitleSection = styled.div`
  padding: 20px;
  span {
    color: #4e4e4e;
    font-size: 14px;
    display: block;
    @media all and (max-width: 1440px) {
      line-height: 22px;
      margin-bottom: 10px;
    }
  }
  strong {
    color: #4e4e4e;
    margin-right: 5px;
  }
  &.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
const BannerTitle = styled.div`
  color: var(--headerColor);
  font-family: "ubuntu-medium";
  margin-bottom: 10px;
`;
const ReadMore = styled.div`
  color: var(--headerColor);
  font-size: 15px;
  font-family: "ubuntu-medium";
  text-align: right;
  position: absolute;
  bottom: 10px;
  right: 20px;
  text-decoration: underline;
  cursor: pointer;
`;
const Cover = styled.div`
  display: flex;
  align-items: center;
  &.line {
    position: relative;
    /* width: 20%; */
    margin-right: 50px;
    @media all and (max-width: 480px) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  &.name {
    margin-left: 50px;
    @media all and (max-width: 480px) {
      margin-right: 0;
      margin-top: 14px;
      margin-bottom: 20px;
      margin-left: -14px;
    }
    &::after {
      content: "";
      position: absolute;
      width: 1px;
      left: -24px;
      height: 60px;
      background-color: var(--darkblue);
      top: -6px;
      @media all and (max-width: 480px) {
        display: none;
      }
    }
  }
`;
const Contact = styled.div`
  background: #eae9e9;
  display: flex;
  justify-content: center;
  width: 75%;
  padding: 40px;
  margin: 50px auto;
  border-radius: 5px;
  align-items: center;
  @media all and (max-width: 480px) {
    flex-direction: column;
    box-sizing: border-box;
    margin: 40px auto;
    width: 90%;
  }
`;
const Icon = styled.div`
  margin-right: 10px;
  img {
    width: 100%;
    display: block;
  }
`;
const CoverTitle = styled.div`
  position: relative;
  &.name {
    &::after {
      content: "";
      position: absolute;
      width: 1px;
      right: -24px;
      height: 60px;
      background-color: var(--darkblue);
      top: -6px;
      @media all and (max-width: 480px) {
        display: none;
      }
    }
  }
`;
const Text = styled.div`
  color: #575757;
  font-family: "ubuntu-regular";
  margin-bottom: 5px;
  font-size: 14px;
`;
const Atag = styled.a`
  /* color: var(--darkblue); */
  /* background: rgb(0, 152, 246);
  background: linear-gradient(
    9deg,
    rgba(0, 152, 246, 1) 0%,
    rgba(0, 92, 185, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: var(--headerColor);
  font-family: "ubuntu-medium";
`;

const ViewAll = styled.p`
  margin-top: 30px;
  text-align: center;
  
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  p{
    font-family: 'ubuntu-bold';
    color: #1f93b0;
    cursor: pointer;
    border: 1px solid #1f93b0;
    padding: 10px 20px;
    border-radius: 20px;
  }
  img{
    width: 13px;
  }
`