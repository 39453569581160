import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import HeaderEdit from "../includes/HeaderEdit";
import banner from "../../../../assets/images/blog/blogBanner.jpg";
import share from "../../../../assets/images/blog/share.png";
import prev from "../../../../assets/images/blog/leftarrow.png";
import next from "../../../../assets/images/blog/rightarrow.png";
import styled from "styled-components";
import Footer from "../includes/Footer";
import { accountsConfig } from "../../../../axiosConfig";

export default function BlogInner() {
  const [blogData, setblogData] = useState({});

  const { id } = useParams();

  useEffect(() => {
    accountsConfig.get(`general/single-blog/${id}`).then((response) => {
      const { data, StatusCode } = response.data.app_data;

      if (StatusCode === 6000) {
        setblogData(data?.message);
      } else {
        setblogData({});
      }
    });
  }, [id]);
  
  return (
    <Container>
      <HeaderEdit backgroundCl="#00AEC0" />
      <MainContainer>
        <Banner>
          <img src={blogData?.image} alt="" />
          <Bar>
            <Topbar>
              <Date>{blogData?.created_at}</Date>
              {/* <Covershare>
                <Text>3 min read</Text>
                <IconShare>
                  <img src={share} alt="" />
                </IconShare>
              </Covershare> */}
            </Topbar>
          </Bar>
        </Banner>
        <Wrapper className="wrapper">
          <SubContainer>
            <Title>{blogData?.title}</Title>
            <Description
              dangerouslySetInnerHTML={{ __html: blogData?.description }}
            />
            <Tags>
              {blogData?.tags?.map((item) => (
                <TagItem>{item?.name}</TagItem>
              ))}
            </Tags>
            {/* <Buttons>
              <Button>
                <LabelText>PREVIOUS POST</LabelText>
                <CoverArrow>
                  <ArrowIcon>
                    <img src={prev} alt="" />
                  </ArrowIcon>
                  <Span>
                    Navigating Your Path to Canadian Education: The Role of
                    Overseas Education Consultants
                  </Span>
                </CoverArrow>
              </Button>
              <Button>
                <LabelText className="next">NEXT POST</LabelText>
                <CoverArrow>
                  <ArrowIcon>
                    <img src={next} alt="" />
                  </ArrowIcon>
                  <Span>
                    Navigating Your Path to Canadian Education: The Role of
                    Overseas Education Consultants
                  </Span>
                </CoverArrow>
              </Button>
            </Buttons> */}
            <Comment>
              <TextComent>Leave a comment</TextComent>
              <TextArea>
                <LabelC>Comment</LabelC>
                <TextareaInput placeholder="Your comment"></TextareaInput>
              </TextArea>
              <CoverInput>
                <CoverC>
                  <LabelC>Name</LabelC>
                  <Input type="text" placeholder="your name" />
                </CoverC>
                <CoverC>
                  <LabelC>Email</LabelC>
                  <Input type="email" placeholder="Your email address " />
                </CoverC>
              </CoverInput>
              <Submit>Submit</Submit>
            </Comment>
          </SubContainer>
        </Wrapper>
      </MainContainer>
      <Footer />
    </Container>
  );
}

const Container = styled.div`
  background-color: #f8f8f8;
  /* padding-bottom: 40px; */
`;
const MainContainer = styled.div`
  padding-top: 220px;
  @media all and (max-width: 480px) {
    padding-top: 100px;
  }
`;
const Banner = styled.div`
  width: 90%;
  margin: 0 auto;
  position: relative;
  img {
    display: block;
    width: 100%;
  }
`;
const Wrapper = styled.div`
  @media all and (max-width: 1440px) {
    &.wrapper {
      width: 80% !important;
    }
  }
`;
const Bar = styled.div`
  position: absolute;
  bottom: 0;
  width: 89%;
  margin: 0 auto;
  background: #fff;
  padding: 20px;
  left: 50%;
  right: 50%;
  transform: translate(-50%);
  box-sizing: border-box;
`;
const SubContainer = styled.div`
  background-color: #fff;
  padding: 20px 40px;
`;
const Topbar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-top: 20px;
`;
const Date = styled.div`
  color: #909090;
`;
const Covershare = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const IconShare = styled.div`
  background-color: #00aec0;
  padding: 10px;
`;
const Text = styled.div`
  color: #909090;
`;
const Title = styled.h1`
  color: #147d92;
  font-size: 48px;
  margin-bottom: 20px;
  @media all and (max-width: 1440px) {
    font-size: 36px;
  }
  @media all and (max-width: 768px) {
    font-size: 26px;
  }
  @media all and (max-width: 480px) {
    font-size: 22px;
  }
`;
const Description = styled.div`
  color: #2b2b2b;
  text-align: justify;
  p {
    margin-bottom: 10px;
    line-height: 28px;
  }
`;
const Tags = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;
const TagItem = styled.div`
  background-color: #f8f8f8;
  padding: 20px;
  box-sizing: border-box;
  font-size: 14px;
  color: #147d92;
`;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 50px 0;
  @media all and (max-width: 1440px) {
    flex-wrap: wrap;
  }
`;
const Button = styled.div`
  @media all and (max-width: 768px) {
    margin-bottom: 20px;
  }
`;
const LabelText = styled.div`
  color: #909090;
  margin-bottom: 10px;
  &.next {
    text-align: right;
  }
  @media all and (max-width: 768px) {
    &.next {
      text-align: left;
    }
  }
`;
const ArrowIcon = styled.div``;
const Span = styled.div`
  color: #147d92;
  width: 500px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  @media all and (max-width: 1440px) {
    width: 350px;
  }
  @media all and (max-width: 480px) {
    width: 200px;
  }
`;
const Comment = styled.div``;
const TextComent = styled.h2`
  color: #565757;
  margin-bottom: 20px;
`;
const TextArea = styled.div`
  border: 1px solid #cfd3d4;
  padding: 10px 20px;
  margin-bottom: 20px;
`;
const LabelC = styled.div`
  margin-bottom: 10px;
  color: #5e6366;
`;
const TextareaInput = styled.textarea`
  border: none;
  outline: none;
  padding: 10px 0;
  height: 80px;
`;
const Input = styled.input`
  border: none;
  outline: none;
  height: 40px;
`;
const Submit = styled.div`
  height: 40px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #147d92;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
`;
const CoverInput = styled.div`
  display: flex;
  gap: 20px;
  @media all and (max-width: 480px) {
    flex-direction: column;
  }
`;
const CoverC = styled.div`
  border: 1px solid #cfd3d4;
  padding: 10px 20px;
  margin-bottom: 20px;
  width: 50%;
  box-sizing: border-box;
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const CoverArrow = styled.div`
  display: flex;
  gap: 10px;
`;
