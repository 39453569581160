import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { course_imgs } from "../../../../general/commonArrays";
import Slider from "react-slick";
import Institutes from "../Institutes";
import { accountsConfig } from "../../../../../axiosConfig";
import arrow_bottom from '../../../../../assets/icons/arrow_bottom.svg'
function Study() {
  const [courses, setcourses] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    accountsConfig.get("study/get-courses/?is_home={true}").then((res) => {
      const { data, StatusCode } = res.data.app_data;
      if (StatusCode === 6000) {
        setcourses(data);
      } else {
        setcourses([]);
      }
    });
  }, []);

  const settings = {
    dots: false,
    arrows: false,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
  };

  const items = [
    "Study For Free",
    "Study With Scholarship",
    "Study Without IELTS",
    "Test Preparations",
    "Personalized Guidance",
  ];

  const handleCouseFilter = (id) => {
    navigate("/find-a-course", {
      state: {
        courseId: id,
      },
    });
  };

  const truncateName = (name) => {
    if (!name) return "";

    if (name.length > 35) {
      return name.substring(0, 35) + "...";
    }

    const words = name.split(" ");

    if (words.length > 4) {
      return words.slice(0, 4).join(" ") + "...";
    }

    return name;
  };

  return (
    <TabContent>
      <TopArea>
        <TopAreaItems>
          <MobTitle>Our Courses</MobTitle>
          <Info>
            <SliderArea className="ares">
              <Slider {...settings}>
                {items.map((obj) => (
                  <Item>
                    <ItemInner className={"slider-item"}>{obj}</ItemInner>
                  </Item>
                ))}
              </Slider>
            </SliderArea>
            <Box className="bx">
              <Title>Our Courses</Title>
              <Desc>
                <Para>
                  Medcity Study Abroad offers a diverse range of Bachelor’s, Master’s, 
                  and Doctorate programs across various academic disciplines globally.
                  We are dedicated to guiding young aspirants towards permanent success
                  in their fields of interest by providing personalized support from course
                  selection to application and visa processes.
                </Para>
                <Para className="hide">
                Our extensive network and experienced consultants ensure you find the right 
                program and university to match your academic and career goals.
                </Para>
              </Desc>
            </Box>
          </Info>
          <MobPara>
          Our extensive network and experienced consultants ensure you find the right 
          program and university to match your academic and career goals.
          </MobPara>
        </TopAreaItems>
      </TopArea>
      <BottomArea>
        <Cards>
          {courses?.map((item) => (
            <Card onClick={() => handleCouseFilter(item?.id)}>
              <img src={item?.image} alt="" />
              <Name>{truncateName(item?.name)}</Name>            </Card>
          ))}
        </Cards>
        <ViewAll onClick={()=>navigate('/find-a-course')}>
          <p><span>View All</span> <img src={arrow_bottom} alt="arrow-icon" /></p>
        </ViewAll>
      </BottomArea>
      {/* <InstituteContainer>
        <Institutes />
      </InstituteContainer> */}
    </TabContent>
  );
}

export default Study;

const TabContent = styled.div`
  background-color: #f8f8f8;
`;

const TopArea = styled.div`
  padding: 80px 80px 25px;

  @media (min-width: 1920px) {
    padding: 80px 80px 40px;
  }

  @media (max-width: 1400px) {
    padding: 60px 60px 25px;
  }

  @media (max-width: 1275px) {
    padding: 40px 40px 25px;
  }
  @media (max-width: 600px) {
    padding: 40px 20px 25px;
  }
`;

const BottomArea = styled.div`
  padding: 80px;

  @media (max-width: 1400px) {
    padding: 60px;
  }
  @media (max-width: 1275px) {
    padding: 40px;
  }

  @media (max-width: 600px) {
    padding: 20px;
  }
`;
const ViewAll = styled.p`
  text-align: center;
  
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  p{
    font-family: 'ubuntu-bold';
    color: #1f93b0;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: -50px;
    border: 1px solid #1f93b0;
    padding: 10px 20px;
    border-radius: 20px;
  }
  img{
    width: 13px;
  }
  @media (max-width:1275px){
    padding-top: 10px;
    padding-bottom: 20px;
  }
  @media (max-width:600px){
    padding-top: 10px;
    padding-bottom: 30px;
  }
  
`
const TopAreaItems = styled.div`
  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
`;

const Info = styled.div`
  display: flex;
  gap: 25px;
  flex-direction: row-reverse;

  @media (max-width: 768px) {
    height: 250px;
  }

  @media (max-width: 600px) {
    height: 225px;
  }
  @media (max-width: 480px) {
    gap: 20px;
    flex-direction: column;
    height: max-content;
  }
`;

const CourseDisplay = styled.div``;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 60%;

  @media (max-width: 1080px) {
    width: 50%;
  }
  @media (max-width: 768px) {
    height: 100%;
    justify-content: flex-end;
    gap: 50px;
  }

  @media (max-width: 600px) {
    gap: 25px;
    justify-content: center;
  }

  @media (max-width: 600px) {
    gap: 25px;
    justify-content: center;
  }

  @media (max-width: 480px) {
    width: 90%;
    margin: 0 auto;
  };
`;

const SliderArea = styled.div`
  float: right;
  width: 40%;
  background-color: transparent;

  @media (max-width: 1080px) {
    width: 50%;
  }

  .slick-center .slider-item {
    z-index: 100;
    width: 100% !important;
    filter: blur(0px);
    transform: scaleZ(2);
  }
  .slick-vertical .slick-slide {
    border: none !important;
  }
  .slick-list {
    height: 260px !important;
  }

  @media (max-width: 768px) {
    .slick-list {
      height: 220px !important;
    }
  }

  @media (max-width: 600px) {
    .slick-list {
      height: 180px !important;
    }
  }

  @media (max-width: 480px) {
    width: 90%;
    margin: 0 auto;
  }
`;

const Title = styled.h3`
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #1f93b0;
  font-family: "ubuntu-medium";

  @media (max-width: 768px) {
    font-size: 31px;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

const MobTitle = styled.h3`
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #1f93b0;
  font-family: "ubuntu-medium";
  width: 100%;
  text-align: center;
  display: none;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    font-size: 31px;
  }

  @media (max-width: 600px) {
    display: block;
  }
`;

const Desc = styled.p`
  color: #2b2b2b;
  font-size: 15.5px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Item = styled.div`
  height: 100%;
  width: 100% !important;
  background-color: transparent;
`;

const ItemInner = styled.div`
  height: 88px;
  margin: 5px auto;
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: linear-gradient(180deg, #1db0cd 0%, #16849a 100%);
  /* box-shadow: 0px 29.834px 59.668px 0px rgba(5, 31, 37, 0.25),
    0px 9.945px 9.945px 0px #1eb6d4 inset,
    0px -9.945px 9.945px 0px #147d92 inset; */
  position: relative;
  z-index: 0;
  filter: blur(1.5px);
  width: 80% !important;
  transition: all 0.3s, transform 0.3s;
  background-color: transparent;

  @media (max-width: 1275px) {
    font-size: 21px;
  }

  @media (max-width: 768px) {
    font-size: 22px;
  }

  @media (max-width: 600px) {
    height: 55px;
    font-size: 16px;
  }
`;

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  @media (max-width: 580px) {
    gap: 10px;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 31%;
  cursor: pointer;
  img{
    height: 80%;
  }
  @media (max-width: 1080px) {
    width: 45%;
    margin: 0 auto;
  }

  @media (max-width: 600px) {
    width: 48%;
  }
`;

const Name = styled.div`
  height: 60px;
  width: 100%;
  background-color: #1f93b0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18.501px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @media (max-width: 600px) {
    font-size: 16px;
    text-align: center;
  }

  @media (max-width: 580px) {
    font-size: 14px;
    text-align: center;
    height: 35px;
  }
`;

const InstituteContainer = styled.div`
  background-color: #161c35;
  padding: 80px;

  @media (max-width: 768px) {
    padding: 40px;
  }

  @media (max-width: 600px) {
    padding: 20px;
  }
`;

const Para = styled.div`
  @media (max-width: 1080px) {
    text-align: justify;
  }

  @media (max-width: 768px) {
    &.hide {
      display: none !important;
    }
  }
`;

const MobPara = styled.div`
  display: none;
  color: #2b2b2b;
  font-size: 15.5px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-align: justify;

  @media (max-width: 768px) {
    display: block;
  }

  @media (max-width: 480px) {
    width: 90%;
    margin: 0 auto;
  }
`;
