// --imports--
import { BrowserRouter } from "react-router-dom";
import MainRouter from "./components/routing/router/MainRouter";
import "remixicon/fonts/remixicon.css";
import "./assets/css/style.css";
import "./assets/css/jquery.hislide.min.css";
import "react-calendar/dist/Calendar.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "./assets/css/map.css";
import "./assets/js/map.js";
import "./assets/js/wow/wow.js";
// import "./assets/js/wow/wow.min.js";
function App() {
  return (
    <BrowserRouter>
      <MainRouter />
      <script
        type="text/JavaScript"
        src="https://maps.google.com/maps/api/js?sensor=false"
      ></script>
    </BrowserRouter>
  );
}

export default App;
