import React, { useEffect, useState } from "react";
import styled from "styled-components";
import FeaturedWebinars from "./includes/FeaturedWebinars";
import HeaderEdit from "../../includes/HeaderEdit";
import line from "../../../../../assets/images/webinars/line.png";
import UpcomingWebinars from "./includes/UpcomingWebinars";
import CompletedWebinars from "./includes/CompletedWebinars";
import Footer from "../../includes/Footer";
import { accountsConfig } from "../../../../../axiosConfig";

export default function Webinars() {
  const [getData, setData] = useState([]);

  useEffect(() => {
    accountsConfig.get("general/list-webinars/").then((res) => {
      const data = res.data.app_data.data;
      if (res.data.app_data.StatusCode == 6000) {
        setData(data);
      } else {
        setData([]);
      }
    });
  }, []);
  
  return (
    <Container>
      <HeaderEdit backgroundCl="#00AEC0" />
      <Wrapper className="wrapper">
        <Content>
          <Title>Webinars</Title>
          <FeaturedWebinars getData={getData} />
          <Line>
            <img src={line} alt="" />
          </Line>
          <UpcomingWebinars getData={getData} />
          <Line>
            <img src={line} alt="" />
          </Line>
          <CompletedWebinars getData={getData} />
        </Content>
      </Wrapper>
      <Footer color="other" />
    </Container>
  );
}

const Container = styled.div`
  background-color: #f8f8f8;
`;
const Wrapper = styled.div``;
const Content = styled.div`
  padding-top: 150px;
`;
const Title = styled.h1`
  color: var(--blue);
  text-align: center;
  margin-bottom: 10px;
`;
const Line = styled.div`
  margin-top: 50px;
  img {
    display: block;
    width: 100%;
  }
`;
