
import { course_imgs } from "../general/commonArrays";
import { SET_BASIC_DATA, SET_COLLEGE_INTERESTED, SET_CURRENT_EDUCATION_INFO, SET_PAST_EDUCATION_INFO, SET_USER_DATA, UPDATE_PHASE, USER_LOGOUT } from "./types";

//initial store data 
// const initialState = {
//   is_LoggedIn: false,
//   phase:1,
//   data: {
//     access_token: null,
//     refresh_token: null,
//   },
//   apply_details:{
//     basic_info:{   
//     },
//     current_educational_info:{
//
//     },
//     past_educational_info:{
//
//     },
//     college_interested:{
//
//    }
//   }
// };

export const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_USER_DATA:
      localStorage.setItem("user_data",JSON.stringify(payload));
      return { ...state, is_LoggedIn: true,data:payload };

    case USER_LOGOUT:
      localStorage.removeItem("user_data");
      return { ...state, is_LoggedIn: false, data: {access_token: null,refresh_token: null,}};


    case UPDATE_PHASE:
      return { ...state,phase:payload};

    case SET_BASIC_DATA:
        return { ...state,apply_details:{...state.apply_details,basic_info:payload}};
    
    case SET_CURRENT_EDUCATION_INFO:
      return { ...state,apply_details:{...state.apply_details,current_educational_info:payload}};

    case SET_PAST_EDUCATION_INFO:
      return { ...state,apply_details:{...state.apply_details,past_educational_info:payload}};
    
    case SET_COLLEGE_INTERESTED:
      return { ...state,apply_details:{...state.apply_details,college_interested:payload}};
    default:
      break;
  }
};
