import React, { useEffect, useState } from "react";
import { faqArray } from "../../../../../general/commonArrays";
import styled from "styled-components";
import { Fade, Zoom } from "react-reveal";
import { accountsConfig } from "../../../../../../axiosConfig";

export default function Faq() {
  const [upAndDown, setupAndDown] = useState();
  const [getData, setData] = useState();

  //list faq
  useEffect(() => {
    accountsConfig.get("study/list-faq/").then((res) => {
      const data = res.data.app_data.data;
      if (res.data.app_data.StatusCode == 6000) {
        setData(data);
      } else {
        setData([]);
      }
    });
  }, []);

  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <Title>FAQ</Title>
          <Desc>
            Here goes a few queries that run in your mind, when you think about
            studying abroad.
          </Desc>
          <ListItems>
            {getData?.map((item, index) => (
              <List onClick={() => setupAndDown(item.questions)} key={index}>
                <Top>
                  <Text>{item.questions}</Text>
                  <Icon>
                    {upAndDown == item.questions ? (
                      <i class="ri-arrow-up-s-line"></i>
                    ) : (
                      <i class="ri-arrow-down-s-line"></i>
                    )}
                  </Icon>
                </Top>
                {upAndDown == item.questions && (
                  <Fade>
                    <Detail>{item.answers}</Detail>
                  </Fade>
                )}
              </List>
            ))}
          </ListItems>
        </Content>
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  background-color: #f8f8f8;
  padding: 60px 0;
`;
const Wrapper = styled.div``;
const Content = styled.div``;
const Title = styled.h1`
  color: var(--blue);
  text-align: center;
  margin-bottom: 10px;
`;
const Desc = styled.p`
  font-size: 18px;
  font-family: 'ubuntu-regular';
  text-align: center;
`;
const ListItems = styled.div`
  margin-top: 50px;
`;
const List = styled.div`
  margin-bottom: 10px;
  cursor: pointer;
`;
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #00aec0;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px 4px 0 0;
  box-shadow: 0px 4px 10px 0px #00000040;
`;
const Text = styled.div`
  font-family: 'ubuntu-regular';
  font-size: 18px;
`;
const Icon = styled.div`
  font-size: 20px;
`;
const Detail = styled.p`
  border: 1px solid #00aec0;
  padding: 10px;
  font-size: 16px;
  border-radius: 0 0 4px 4px;
  line-height: 156%;
  @media (max-width: 768px) {
    line-height: 28px;
  }
`;
