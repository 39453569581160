import React from "react";
import { useNavigate} from 'react-router-dom';

import styled from "styled-components";
import HeaderEdit from "../includes/HeaderEdit";
import Footer from "../includes/Footer";
import NotFoundLoader from "../../../general/pageNotFound/NotFoundLoader";
import Icon from "../../../../assets/images/icons/back_to_home.svg";

function PageNotFound() {
  const navigate = useNavigate();
  return (
    <Section>
      <HeaderEdit backgroundCl={"rgba(0, 174, 192, 1)"} />
      <Container className="wrapper">
        <Content>
          <H1 className="small">Oops!</H1>
          <H1>PAGE NOT FOUND</H1>
        </Content>
        <NotFoundLoader />
        <Button onClick={()=>navigate('/')}>
          <img src={Icon} alt="" /> Back to home
        </Button>
      </Container>
      <Footer />
    </Section>
  );
}

export default PageNotFound;

const Section = styled.div``;

const Container = styled.div`
  margin-top: 50px;
  padding-top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;

  @media screen and (max-width: 980px) {
    margin-bottom: 80px;
    margin-top: 40px;
  }

  @media screen and (max-width: 650px) {
    gap: 15px;
  }

  @media screen and (max-width: 580px) {
    padding-top: 60px;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
`;

const H1 = styled.h1`
  font-family: "ubuntu-bold";
  text-align: center;
  color: rgba(0, 174, 192, 1);
  font-size: 4rem;

  &.small {
    font-size: 3rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 3.5rem;
    &.small {
      font-size: 3rem;
    }
  }

  @media screen and (max-width: 580px) {
    font-size: 2.2rem;
    &.small {
      font-size: 1.6rem;
    }
  }
`;

const Button = styled.div`
  color: rgba(0, 174, 192, 1);
  padding: 10px 15px;
  border: 1px solid rgba(0, 174, 192, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  gap: 10px;
  font-size: 18px;
  cursor: pointer;
  box-sizing: border-box;

  img {
    max-width: 30px;
  }
`;
