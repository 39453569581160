import React from "react";
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';

import loc from "../../../../../assets/images/work/IMAGE.jpg";
import { dream_country } from "../../../../general/commonArrays";
export default function WorkTab() {
  const navigate  = useNavigate();

  return (
    <Container>
      <CoverJob>
        <TopBar>
          <Title>Job Opportunities</Title>
          <Description>
          Studying abroad broadens academic horizons and 
          offers practical work experience. Many countries 
          allow part-time student jobs, enhancing financial 
          support, cultural immersion, and employability through 
          work permits and post-study options.
          </Description>
        </TopBar>
        <Bottom>
          <ImageSection>
            <ImageContainer>
              <img src={loc} alt="image   " />
            </ImageContainer>
          </ImageSection>
          <Feature>
            <Cover>
              <Text>Exotic Destinations</Text>
              <Sub>
                Embark on life-changing journeys, where work meets wanderlust.
              </Sub>
            </Cover>
            <Cover>
              <Text>In-demand Jobs</Text>
              <Sub>
                Find your niche in various industries with high demand abroad.
              </Sub>
            </Cover>
            <Cover>
              <Text>Cultural Exchange</Text>
              <Sub>
                Explore new cultures, grow as a person, and forge lifelong
                connections.
              </Sub>
            </Cover>
          </Feature>
        </Bottom>
      </CoverJob>
      <DreamCountry>
        <Top>
          <Choose>Choose Your Dream Country to Work</Choose>
          <Button onClick={()=>navigate('/jobs')}>
            See all jobs
            <Icon>
              <i class="ri-arrow-right-s-fill"></i>
            </Icon>
          </Button>
        </Top>
        <Cards>
          {dream_country.map((item, index) => (
            <Card key={index}>
              <ImageContainer>
                <img className="card" src={item.image} alt={item.title} />
              </ImageContainer>
              <TitleBanner>{item.title} </TitleBanner>
            </Card>
          ))}
        </Cards>
      </DreamCountry>
    </Container>
  );
}

const CoverJob = styled.div`
  background-color: #f8f8f8;
  padding: 40px 60px;
  @media all and (max-width: 580px) {
    padding: 20px;
  }
`;
const Container = styled.div``;
const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  @media all and (max-width: 768px) {
    flex-direction: column;
  }
`;
const Title = styled.h1`
  width: 40%;
  font-size: 45px;
  background: linear-gradient(
    90deg,
    #21a9c1 0%,
    #19a2bb 26.47%,
    #109bb5 52.94%,
    #1897b3 76.47%,
    #1f93b0 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media all and (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;
    font-size: 35px;
  }

  @media screen and (max-width:580px) {
    font-size: 30px;
    text-align:center;
  }
`;
const Description = styled.p`
  width: 60%;
  color: #000000b7;
  line-height: 22px;
  @media all and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width:580px) {
    font-size: 15px;
    text-align: justify;
    word-break: break-all;
  }
`;
const Bottom = styled.div`
  display: flex;
  margin-top: 30px;
  align-items: center;
  gap: 50px;
  @media all and (max-width: 640px) {
    flex-direction: column;
  }
`;
const ImageSection = styled.div`
  width: 60%;
  @media all and (max-width: 640px) {
    width: 100%;
  }
`;
const ImageContainer = styled.div`
  img {
    display: block;
    width: 100%;
    &.card {
      border-radius: 20px 20px 0 0;
    }
  }
`;
const Feature = styled.div`
  width: 40%;
  @media all and (max-width: 640px) {
    width: 100%;
  }
`;
const Cover = styled.div`
  margin-bottom: 20px;
`;
const Text = styled.h3`
  color: #335f8a;
  font-family: "ubuntu-regular";
  font-weight: normal;
  margin-bottom: 10px;
  font-size: 22px;
`;
const Sub = styled.p`
  color: #000000b7;
  font-size: 14px;
`;

const DreamCountry = styled.div`
  background-color: #161c35;
  padding: 40px;

  @media screen and (max-width:580px) {
    padding: 40px 20px;
  }
`;
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  @media all and (max-width: 640px) {
    flex-direction: column;
    text-align:center;
    gap: 15px;
  }
`;
const Choose = styled.h2`
  color: #ffffff;
`;
const Button = styled.div`
  color: #335f8a;
  background-color: #fff;
  display: flex;
  cursor: pointer;
  height: 40px;
  width: fit-content;
  padding: 0 20px;
  gap: 5px;
  align-items: center;
`;
const Icon = styled.div``;
const Cards = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
`;
const Card = styled.div`
  width: 30%;
  margin: 0 auto;
  @media all and (max-width: 640px) {
    width: 40%;
  }
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const TitleBanner = styled.div`
  background-color: #fff;
  height: 80px;
  color: #335f8a;
  border-radius: 0 0 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
