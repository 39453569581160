import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import HeaderEdit from "../includes/HeaderEdit";
import Footer from "../includes/Footer";
import Swal from 'sweetalert2';

import downArrow from "../../../../assets/images/careers/icons/down_arrow.svg";
import pdf from "../../../../assets/images/careers/icons/pdf.svg";
import { accountsConfig } from "../../../../axiosConfig";
import ButtonLoader from "../../../general/loader/ButtonLoader";

function Careers() {
  const [cv, setcv] = useState("");
  const [jobs, setjobs] = useState([]);
  const [loader, setLoader] = useState(false);

  const selectRef = useRef();
  const formRef = useRef();

  useEffect(() => {
    accountsConfig.get("general/get-careers/").then((res) => {
      const { data, StatusCode } = res.data.app_data;

      if (StatusCode === 6000) {
        setjobs(data);
      } else {
        setjobs([]);
      }
    });
  }, []);

  const handleCvInput = (e) => {
    if (e.target.files[0] != undefined) {
      setcv(e.target.files[0]);
    } else {
      setcv("");
    }
  };

  const handleFormSubmit = (e) => {
      e.preventDefault();
      const formData = new FormData(formRef.current);
      // submiting the form
      setLoader(true);
      accountsConfig.post("general/apply-careers/",formData).then((res) => {
        const { data, StatusCode } = res.data.app_data;
        setLoader(false);
        if (StatusCode === 6000) {
          e.target.reset()
          setcv('')
          showSuccessAlert()
        } else {
          showErrorAlert()
        }
      });
  };

  //sweet alert for success modal
  const showSuccessAlert = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Success",
      showConfirmButton: false,
      html: "<p>Submitted successfully</p>",
      timer: 2500,
      iconColor: "#1eb300",
    });
  };

  //sweet alert for error modal
  const showErrorAlert = () => {
    Swal.fire({
      position: "center",
      icon: "error",
      title: "Something went wrong",
      showConfirmButton: false,
      html: "<p>Something went wrong</p>",
      timer: 2500,
      iconColor: "#ff0000",
    });
  };

  return (
    <Section>
      <HeaderEdit backgroundCl={"#00AEC0"} />
      <Container>
        <Wrapper className="wrapper">
          <Info>
            <Title>Careers</Title>
            <Body>
              <Para>
              Medcity International Overseas Corporation
              is a key player in international study counselling
              and an education specialist. We hire interns and full-time
              employees as counsellors, IT support, graphic designers, 
              content writer and video editor.
              </Para>
              <Para>
              If you are a talented individual interested in developing 
              your own skills while being a team player at Medcity Study 
              Abroad, then apply to one of the open positions and start 
              your journey with the best Study Abroad agency around. Send 
              your updated resume with your work portfolio to <a href="mailto:careers@mioc.in">careers@mioc.in</a>
              </Para>
            </Body>
          </Info>
          <BoxContainer>
            {jobs?.map((job) => (
              <Box>
                <BoxH1>{job?.name}</BoxH1>
                <BoxBlock>
                  <BoxP>{job?.description}</BoxP>
                  <BoxP className="bold">Who can apply?</BoxP>
                  <BoxP>
                    <ul>
                      {job?.criteria?.map((item) => (
                        <li>
                          {item?.criteria}
                        </li>
                      ))}
                    </ul>
                  </BoxP>
                </BoxBlock>
              </Box>
            ))}
          </BoxContainer>

          <FormDiv>
            <Title className="fs-35">Interested in joining our team?</Title>
            <Form onSubmit={(e)=>handleFormSubmit(e)} ref={formRef}>
              <Heading>Send a message</Heading>
              <InputsDiv>
                <InputCover className="name">
                  <Label>Name</Label>
                  <Input placeholder="Your name" type="text" name='name' required />
                </InputCover>

                <InputCover className="phone">
                  <Label>Phone Number</Label>
                  <Input placeholder="Your number" type="number" name="phone" required />
                </InputCover>

                <InputCover className="email">
                  <Label htmlFor="email">Email</Label>
                  <Input id="email" placeholder="Email" name="email" type="email" required />
                </InputCover>

                <InputCover className="role">
                  <Label htmlFor="select-id" className="role-select">
                    Applying for
                  </Label>
                  <Select ref={selectRef} name="apply_for" required>
                    {
                      jobs?.map((obj)=>(
                        <Option value={obj?.id}>{obj.name}</Option>
                      ))
                    }
                  </Select>
                </InputCover>

                <InputCover className="cv">
                  <Label htmlFor="cv" className="cv">
                    Upload CV
                  </Label>
                  <Label className="cv-label" htmlFor="cv">
                    {cv
                      ? cv?.name.length > 25
                        ? cv?.name.slice(0, 25) + ".."
                        : cv?.name
                      : "Choose File"}
                  </Label>
                  <FileInput
                    onChange={(e) => handleCvInput(e)}
                    type="file"
                    id="cv"
                    name="cv"
                    required
                  />
                </InputCover>
              </InputsDiv>
              <SubmiBtn type={loader? 'button' : 'submit'}>{loader ? <ButtonLoader/> : 'Submit' }</SubmiBtn>
            </Form>
          </FormDiv>
        </Wrapper>
      </Container>
      <Footer />
    </Section>
  );
}

export default Careers;

const Section = styled.section``;

const Container = styled.div`
  background-color: #f8f8f8;
  padding-top: 100px;
`;

const Wrapper = styled.div``;

const Info = styled.div`
  margin-top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;

  @media screen and (max-width: 580px) {
    margin-top: 40px;
  }
`;

const Title = styled.h3`
  text-align: center;
  font-size: 50px;
  font-style: normal;
  line-height: normal;
  color: #1897b2;

  &.fs-35 {
    font-size: 35px;
  }

  @media screen and (max-width: 580px) {
    font-size: 32px;
    &.fs-35 {
      font-size: 22px;
    }
  }
`;

const Body = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
`;

const BoxContainer = styled.div`
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Box = styled.div`
  box-sizing: border-box;
  padding: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const BoxH1 = styled.h1`
  color: #147d92;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-family: "ubuntu-medium";
  border-bottom: 1px solid #d9d9d9;
  padding: 8px 0;
`;

const BoxBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const BoxP = styled.div`
  color: #2b2b2b;
  text-align: justify;
  font-size: 15.5px;
  font-style: normal;
  line-height: 22px;

  ul {
    padding: 0 0 0 20px;
  }

  &.bold {
    font-family: "ubuntu-bold";
  }
`;

const Para = styled.p`
  color: #2b2b2b;
  font-size: 16px;
  font-style: normal;
  line-height: 22px;
  text-align: justify;

  a {
    color: #19a2bb;
  }

  @media screen and (max-width: 580px) {
    font-size: 15px;
  }
`;

const FormDiv = styled.div`
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const Form = styled.form`
  padding: 30px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 80px;
`;

const InputsDiv = styled.div`
  display: grid;
  gap: 24px;
  grid-template-areas:
    "name name"
    "phone email"
    "role cv";

  @media screen and (max-width: 768px) {
    grid-template-areas:
      "name name"
      "phone phone"
      "email email"
      "role role"
      "cv cv";
  }
`;

const InputCover = styled.div`
  border: 1.5px solid #cbcbcb;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;

  &.name {
    grid-area: name;
  }

  &.email {
    grid-area: email;
  }

  &.phone {
    grid-area: phone;
  }

  &.role {
    grid-area: role;
  }

  &.cv {
    grid-area: cv;
  }

  @media screen and (max-width: 768px) {
    height: 65px;
  }
`;

const Heading = styled.div`
  color: #565757;
  font-size: 21px;
  font-style: normal;
  line-height: normal;
  font-family: "ubuntu-medium";
`;

const Label = styled.label`
  color: #5e6366;
  font-size: 13px;
  font-style: normal;
  line-height: normal;
  position: relative;
  cursor: pointer;

  &.role-select {
    &::after {
      content: "";
      position: absolute;
      top: 5px;
      right: 0;
      background-image: url(${downArrow});
      background-size: contain;
      width: 25px;
      height: 30px;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }

  &.cv {
    &::after {
      content: "";
      position: absolute;
      top: 5px;
      right: 0;
      background-image: url(${pdf});
      background-size: contain;
      background-repeat: no-repeat;
      width: 25px;
      height: 30px;
      cursor: pointer;
    }
  }

  &.cv-label {
    color: #abafb1;
    width: max-content;
    border-bottom: 1px solid #abafb1;
    cursor: pointer;
  }
`;
const Input = styled.input`
  border: none;
  outline: none;
  padding: 0;
  &::placeholder {
    color: #abafb1;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    font-family: "ubuntu-regular";
    padding: 0;
  }
`;

const Select = styled.select`
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #abafb1;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  font-family: "ubuntu-regular";
  position: absolute;
  left: 0;
  right: 0;
  padding-left: 15px;
  padding-top: 25px;
  background-color: transparent;

  option:hover {
    background-color: red;
  }
`;

const Option = styled.option`
  color: #000000;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  font-family: "ubuntu-regular";
  background-color: #ffffff;
  padding: 20px !important;
`;

const FileInput = styled.input`
  display: none !important;
`;

const SubmiBtn = styled.button`
  width: 20%;
  background-color: #00aec0;
  color: #fff;
  text-align: center;
  font-size: 18px;
  line-height: normal;
  border: none;
  outline: none;
  padding: 14px 10px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 50%;
  }

  @media screen and (max-width: 375px) {
    width: 100%;
  }
`;
