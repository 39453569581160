import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import successimage from '../../../../../assets/images/partcipateandnwin/successimage.svg';
import Confetti from 'react-confetti';
import { accountsConfig } from '../../../../../axiosConfig';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';


function PartcipateAndnWin({ setModal }) {
    const navigate = useNavigate();
    const [windowDimensions, setWindowDimensions] = useState({ width: 0, height: 0 });
    const [step, setStep] = useState({ question: false, enquiry: false, success: false });
    const [formData, setFormData] = useState({
        dreamjob: '',
        dreamcountry: '',
        dreamsalary: '',
        name: '',
        email: '',
        number: '',
        dob: null,
        gender: null,
    });
    const [errors, setErrors] = useState({
        number: '',email:""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Validate phone number if the input name is 'number'
        if (name === 'number') {
            const indianPhoneNumberPattern = /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/;
            if (!indianPhoneNumberPattern.test(value)) {
                setErrors({ ...errors, number: 'Phone number must be a valid 10-digit Indian number starting with 6, 7, 8, or 9' });
            } else {
                setErrors({ ...errors, number: '' });
            }
        }
        
        if (name === 'email') {
            if (value === "") {
                setErrors({ ...errors, email: "Email cannot be empty" });
            } else if (!validateEmail(value)) {
                setErrors({ ...errors, email: "Invalid email format" });
            } else {
                setErrors({ ...errors, email: "" });
            }
        }
        

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };


    const handleQuestionSubmit = (e) => {
        e.preventDefault();
        setStep({ ...step, question: true });
    };

    const handleEnquirySubmit = (e) => {
        e.preventDefault();
        if (errors.number || formData.number=="") {
            alert('Please enter a valid phone number');
            return;
        }else if (errors.email || formData.email=="") {
            alert('Please enter a valid email');
            return;
        }
        accountsConfig.post('general/partcipateandnwin/', formData).then((res) => {
            const { data, StatusCode } = res.data.app_data;

            if (StatusCode === 6000) {
                setStep({ ...step, enquiry: true });
            } else if (StatusCode === 6001) {
                Swal.fire({
                    icon: 'error',
                    title: 'Sorry...',
                    text: data.message, 
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong. Please try again later.',
                });
            }
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong. Please try again later.',
            });
            console.error("Error submitting enquiry:", error);
        });
    };

    useEffect(() => {
        if (step.enquiry === true) {
            const timer = setTimeout(() => {
                navigate('/offers');
                setModal(false);
                setStep({question: false, enquiry: false, success: false })
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [step.enquiry, navigate]);

    useEffect(() => {
        const updateWindowDimensions = () => {
            setWindowDimensions({ width: window.innerWidth, height: window.innerHeight });
        };

        updateWindowDimensions();
        window.addEventListener('resize', updateWindowDimensions);

        return () => {
            window.removeEventListener('resize', updateWindowDimensions);
        };
    }, []);

    return (
        <>
            {!step.question ? (
                <Section onSubmit={handleQuestionSubmit}>
                    <Heading>Participate and Win 25,000!</Heading>
                    <InputContainer>
                        <div>
                            <Title>What's your dream job ?</Title>
                            <Input value={formData.dreamjob} placeholder='Enter your Dream Job' name='dreamjob' onChange={handleChange} required />
                        </div>
                        <div>
                            <Title>Where's your dream country to work in?</Title>
                            <Input value={formData.dreamcountry} placeholder='Enter Country Name' name='dreamcountry' onChange={handleChange} required />
                        </div>
                        <div>
                            <Title>What's your dream salary?</Title>
                            <Input value={formData.dreamsalary} placeholder='Enter Salary Expectations' name='dreamsalary' onChange={handleChange} required />
                        </div>
                    </InputContainer>
                    <Button type='submit'>Next</Button>
                </Section>
            ) : !step.enquiry ? (
                <Section onSubmit={handleEnquirySubmit}>
                    <Heading>Tell Us About Yourself!</Heading>
                    <InputContainer>
                        <div>
                            <Title>Full Name</Title>
                            <Input value={formData.name} placeholder='Enter your name' name='name' onChange={handleChange} required />
                        </div>
                        <div>
                            <Title>Email Address</Title>
                            <Input value={formData.email} placeholder='Enter email address' type='email' name='email' onChange={handleChange} required />
                            {errors.email && <Error>{errors.email}</Error>}
                        </div>
                        <div>
                            <Title>Phone Number</Title>
                            <Input
                                value={formData.number}
                                placeholder='Enter phone number'
                                name='number'
                                onChange={handleChange}
                                type='number'
                                required
                            />
                            {errors.number && <Error>{errors.number}</Error>}
                        </div>
                        {/* <div className='two-input'>
                            <div>
                                <Title>Date of Birth</Title>
                                <Input value={formData.dob} placeholder='Enter email address' name='dob' onChange={handleChange} type='date' required />
                            </div>
                            <div>
                                <Title>Gender</Title>
                                <Select value={formData.gender} required name='gender' onChange={handleChange}>
                                    <option disabled value="">Select your gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </Select>
                            </div>
                        </div> */}
                    </InputContainer>
                    <Button type='submit'>Next</Button>
                </Section>
            ) : (
                <SuccessSection>
                    <Confetti
                        width={windowDimensions.width}
                        height={windowDimensions.height}
                        recycle={false}
                        numberOfPieces={500}
                    />
                    <Image src={successimage} alt="" />
                    <Heading success={true}>Thank You for Sharing Your Dreams!</Heading>
                    <Title success={true}>
                        We’re thrilled to hear about your aspirations and grateful
                        for your participation. Your journey inspires us, and we’re
                        excited to be a part of it!
                    </Title>
                    <Button onClick={() => (navigate('/offers'), setModal(false))}>Explore Special Offers 🎉</Button>
                </SuccessSection>
            )}
        </>
    );
}

export default React.memo(PartcipateAndnWin);

const Section = styled.form`
    height: 100%;
    width: 100%;
    border-radius: 20px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
    @media (max-width:767px){
        padding: 20px 20px;
        gap: 20px;
    }
`;

const SuccessSection = styled.div`
    height: 100%;
    width: 100%;
    border-radius: 20px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    @media (max-width:767px){
        padding: 20px;
        gap: 20px;
    }
`;

const Image = styled.img`
    width: 140px;
`;

const Heading = styled.p`
  font-size: 40px;
  line-height: 50px;
  color: #00AEC0;
  font-family: 'ubuntu-bold';
  width: ${props => (props.success ? '100%' : '60%')};
  text-align: ${props => (props.success ? 'center' : 'start')};

  @media (max-width:1700px){
    width: 100%;
  }
  @media (max-width:767px){
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
  }
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    div{
        display: flex;
        flex-direction: column;
        gap: 6px;
        width: 100%;
    }
    .two-input{
        display: flex;
        flex-direction: row;
    }
`;

const Title = styled.p`
    font-family: 'ubuntu-regular';
    font-size: 16px;
    color: black;
    text-align: ${props => (props.success ? 'center' : 'start')};
`;

const Input = styled.input`
  padding: 0px 8px;
  border-radius: 6px;
  height: 48px;
  border: 0.5px solid #959595;

  &:focus {
    border-color: #959595; 
    outline: none;
  }

  &::placeholder {
    color: #BABABA;
    font-size: 16px;
  }
`;

const Select = styled.select`
  padding: 0px 8px;
  border-radius: 6px;
  height: 48px;
  border: 0.5px solid #959595;

  &:focus {
    border-color: #959595; 
    outline: none;
  }

  &::placeholder {
    color: #BABABA;
    font-size: 16px;
  }
`;

const Button = styled.button`
    background-color: #00AEC0;
    color: white;
    font-size: 15px;
    font-family: 'ubuntu-bold';
    border: none;
    padding: 10px 24px;
    border-radius: 6px;
    cursor: pointer;
    width: 100%;
`;

const Error = styled.p`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`;
