import React, { useContext, useRef, useState } from "react";
import styled from "styled-components";
import { accountsConfig } from "./../../../../axiosConfig";

import downArrow from "../../../../assets/images/careers/icons/down_arrow.svg";
import pdf from "../../../../assets/images/careers/icons/pdf.svg";
import date from "../../../../assets/images/course/modal/icon/date.svg";
import delete_icon from "../../../../assets/images/course/modal/icon/delete.svg";
import CustomSelect from "../../../utils/CustomSelect";
import { generateNext15Years } from "../../../general/functions";
import { useEffect } from "react";
import { StoreContext } from "../../../context/Store";
import ButtonLoader from "../../../general/loader/ButtonLoader";
import { UPDATE_PHASE } from "../../../context/types";

function Phase4() {
  const { state, dispatch } = useContext(StoreContext);
  const values = generateNext15Years();
  const [counter, setcounter] = useState(1);
  const [year, setyear] = useState(values[0]);
  const [loader, setloader] = useState(false);
  const [colleges, setcolleges] = useState([]);
  const [error, seterror] = useState("");

  useEffect(() => {
    addItem();

    return () => {
      setcolleges([]);
    };
  }, []);

  const addItem = () => {
    // prevnting the add func if a unfilled item is present
    if(!areAllObjectsValid(colleges)){
        return;
    }

    const item = {
      id: colleges.length,
      name: "",
      status: "",
      date: "",
    };
    setcolleges((prev) => [...prev, item]);
  };

  const deleteItem = (id) => {
    if (colleges.length === 1) {
      return;
    }
    setcolleges((prev) => prev.filter((item) => item.id !== id));
  };

  const handleDatechange = (e, id) => {
    setcolleges((prevArray) => {
      return prevArray.map((item) => {
        if (item.id === id) {
          // Update the item with the new data
          return { ...item, date: e.target.value };
        }
        return item;
      });
    });
  };

  // Function to check if all fields in an object are not empty
  function areAllFieldsNotEmpty(obj) {
    for (let key in obj) {
      if (key !== "id") {
        if (obj.hasOwnProperty(key) && !obj[key]) {
          return false; // Found an empty field
        }
      }
    }
    return true;
  }

  // Function to check all objects in the array
  function areAllObjectsValid(array) {
    for (let obj of array) {
      if (!areAllFieldsNotEmpty(obj)) {
        return false;
      }
    }
    return true;
  }

  const getAllDataInFormData = () => {
    const tempForm = new FormData();
    tempForm.append("college", state?.apply_details?.basic_info?.college);
    tempForm.append(
      "course_name",
      state?.apply_details?.basic_info?.course_name
    );
    tempForm.append(
      "applied_course_pk",
      state?.apply_details?.basic_info?.course
    );
    tempForm.append(
      "percentage",
      state?.apply_details?.current_educational_info?.percentage
    );
    tempForm.append(
      "experience",
      state?.apply_details?.current_educational_info?.work_experience
    );
    //past education
    tempForm.append(
      "tenth_board",
      state?.apply_details?.past_educational_info?.ten_board
    );
    tempForm.append(
      "tenth_passing_year",
      state?.apply_details?.past_educational_info?.ten_passing_year
    );
    tempForm.append(
      "tenth_percentage",
      state?.apply_details?.past_educational_info?.ten_percentage
    );
    tempForm.append(
      "tenth_school_name",
      state?.apply_details?.past_educational_info?.ten_school_name
    );
    //12th
    tempForm.append(
      "twelvth_passing_year",
      state?.apply_details?.past_educational_info?.twelve_passing_year
    );
    tempForm.append(
      "twelvth_percentage",
      state?.apply_details?.past_educational_info?.twelve_percentage
    );
    tempForm.append(
      "twelvth_school_name",
      state?.apply_details?.past_educational_info?.twelve_school_name
    );
    tempForm.append(
      "twelvth_specialization",
      state?.apply_details?.past_educational_info?.twelve_specialisation
    );
    tempForm.append(
      "twelvth_board",
      state?.apply_details?.past_educational_info?.twelve_board
    );

    tempForm.append(
      "is_applied_entrance",
      state?.apply_details?.current_educational_info?.exam_status
    );

    if (state?.apply_details?.current_educational_info?.exam_status === "yes") {
      const examInfo = JSON.parse(
        state?.apply_details?.current_educational_info?.exams_info
      );
      if (examInfo.length > 0) {
        examInfo.map((item) => {
          tempForm.append("exam_names", item?.name);
          tempForm.append("exam_scores", item?.score);
        });
      }
    } else if (
      state?.apply_details?.current_educational_info?.exam_status === "booked"
    ) {
      const examInfo = JSON.parse(
        state?.apply_details?.current_educational_info?.exams_info
      );
      if (examInfo.length > 0) {
        examInfo.map((item) => {
          tempForm.append("exam_names", item?.name);
          tempForm.append("exam_dates", item?.date);
        });
      }
    }

    if (colleges.length > 0) {
      colleges.map((item) => {
        tempForm.append("collage_names", item?.name);
        tempForm.append("application_statuse", item?.status);
        tempForm.append("dates", item?.date);
      });
    }

    return tempForm;
  };

  const handleChange = (e, type, id) => {
    setcolleges((prev) =>
      prev.map((item) => {
        if (item?.id === id) {
          return { ...item, [type]: e.target.value };
        }
      })
    );
  };

  const handleSubmit = () => {
    setloader(true);
    const form = getAllDataInFormData();
    accountsConfig
      .post(`study/subcourse-apply/${state?.apply_details?.basic_info?.profileId}/`, form)
      .then((response) => {
        const { data, StatusCode } = response.data.app_data;
        setloader(false);

        if (StatusCode === 6000) {
          dispatch({ type: UPDATE_PHASE, payload: 6 });
        } else {
          alert("something went wrong")
        }
      });
  };

  return (
    <Section>
      <Mob>
        <Para>When do you plan to start your studies?</Para>
        <InputCover>
          <Label>
            Year <span>*</span>
          </Label>
          <Icon>
            <img src={date} alt="" />
          </Icon>
          <CustomSelect
            placeholder={"Select year"}
            setvalue={setyear}
            value={year}
            values={values}
          />
        </InputCover>
      </Mob>
      <Info>
        Which Colleges Have You Applied/Applying To In This Academic Year ?
      </Info>
      <Wrapper>
        <Bottom>
          <ItemsContainer>
            {colleges.map((item, index) => (
              <Item>
                <No>{index + 1}</No>
                <InputCover className="College">
                  <Label htmlFor="College">College</Label>
                  <Input
                    id="College"
                    placeholder="Select a college"
                    name="college_name"
                    type="text"
                    value={item?.name}
                    onChange={(e) => handleChange(e, "name", item?.id)}
                    required
                  />
                </InputCover>
                <InputCover className="Status">
                  <Label htmlFor="Status">Status</Label>
                  <Input
                    id="Status"
                    placeholder="Status"
                    name="Status"
                    type="text"
                    value={item?.status}
                    onChange={(e) => handleChange(e, "status", item?.id)}
                    required
                  />
                </InputCover>
                <InputCover className="date_cover date">
                  <Label htmlFor="Date">Date</Label>
                  <InputDiv>
                    <Icon>
                      <img src={date} alt="" />
                    </Icon>
                    <DateShow>{item?.date || "Select Date"}</DateShow>
                    <Input
                      id="date_input"
                      className="date"
                      type="date"
                      onChange={(e) => handleDatechange(e, item?.id)}
                    />
                  </InputDiv>
                </InputCover>
                <Icon className="delete" onClick={() => deleteItem(item?.id)}>
                  <img src={delete_icon} alt="" />
                </Icon>
              </Item>
            ))}
          </ItemsContainer>
          <AddMore>
            <Button onClick={addItem}>Add More</Button>
          </AddMore>
        </Bottom>
      </Wrapper>
      <BelowArea>
        <Para>When do you plan to start your studies?</Para>
        <InputCover className="year">
          <Label>
            Year <span>*</span>
          </Label>
          <Icon>
            <img src={date} alt="" />
          </Icon>
          <CustomSelect
            placeholder={"Select year"}
            setvalue={setyear}
            value={year}
            values={values}
          />
        </InputCover>
      </BelowArea>
      <Container>
        <BackBtn>Back</BackBtn>
        {loader ? (
          <SubmiBtn>
            <ButtonLoader />
          </SubmiBtn>
        ) : (
          <SubmiBtn onClick={handleSubmit}>Submit</SubmiBtn>
        )}
      </Container>
    </Section>
  );
}

export default Phase4;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Info = styled.p`
  margin: 0;
  padding: 0;
  text-decoration: underline;
  color: rgba(86, 87, 87, 1);
  font-size: 13px;
  line-height: 21px;

  @media screen and (max-width: 580px) {
    font-size: 12px;
  }
`;

const Mob = styled.div`
  display: none;
  flex-direction: column;
  gap: 10px;

  @media screen and (max-width: 768px) {
    display: flex;
  }
`;

const InputsDiv = styled.div`
  display: grid;
  gap: 12px;
  grid-template-areas:
    "board calender percentage"
    "name  null null";

  &.12th-info {
    grid-template-areas:
      "board calender percentage"
      "name  specialisation null";
  }

  @media screen and (max-width: 768px) {
    grid-template-areas:
      "name gender"
      "phone phone"
      "email email"
      "role role"
      "cv cv";
  }
`;

const InputCover = styled.div`
  border: 1.5px solid #cfd3d4;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  background-color: #fff;

  &.w-70 {
    width: 70%;
  }

  &.date_cover {
    gap: 4.5px;
  }

  &.College {
    grid-area: college;
  }

  &.Status {
    grid-area: status;
  }

  &.date {
    grid-area: date;
  }

  &.year {
    width: 30%;
  }

  @media screen and (max-width: 1400px) {
    padding: 10px 5px;
  }

  @media screen and (max-width: 580px) {
    padding: 5px 5px;
  }
`;

const Heading = styled.div`
  color: #565757;
  font-size: 21px;
  font-style: normal;
  line-height: normal;
  font-family: "ubuntu-medium";
`;

const Label = styled.label`
  color: #00aec0;
  font-size: 13px;
  font-style: normal;
  line-height: normal;
  position: relative;
  cursor: pointer;

  span {
    color: red;
  }

  &.role-select {
    &::after {
      content: "";
      position: absolute;
      top: 5px;
      right: 0;
      background-image: url(${downArrow});
      background-size: contain;
      width: 25px;
      height: 30px;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }

  &.cv {
    &::after {
      content: "";
      position: absolute;
      top: 5px;
      right: 0;
      background-image: url(${pdf});
      background-size: contain;
      background-repeat: no-repeat;
      width: 25px;
      height: 30px;
      cursor: pointer;
    }
  }

  &.cv-label {
    color: #abafb1;
    width: max-content;
    border-bottom: 1px solid #abafb1;
    cursor: pointer;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  gap: 5px;

  &.phone {
    grid-area: phone;
  }
`;

const Input = styled.input`
  border: none;
  outline: none;
  padding: 0;
  &::placeholder {
    color: #abafb1;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    font-family: "ubuntu-regular";
    padding: 0;
  }

  &.date {
    &::-webkit-calendar-picker-indicator {
      opacity: 0;
      position: absolute;
      right: 15px;
      z-index: 2;
      height: 22px;
      width: 22px;
      bottom: 10px;
    }
    &::-webkit-datetime-edit {
      opacity: 0;
    }
    width: 60%;
  }

  /* &.date:focus {
    &::-webkit-datetime-edit {
      color: black;
    }
  } */
`;

const Select = styled.select`
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #abafb1;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  font-family: "ubuntu-regular";
  position: absolute;
  left: 0;
  right: 0;
  padding-left: 15px;
  padding-top: 25px;
  background-color: transparent;

  option:hover {
    background-color: red;
  }
`;

const Option = styled.option`
  color: #000000;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  font-family: "ubuntu-regular";
  background-color: #ffffff;
  padding: 20px !important;
`;

const FileInput = styled.input`
  display: none !important;
`;

const SubmiBtn = styled.button`
  width: 20%;
  background-color: rgba(0, 174, 192, 1);
  color: #fff;
  text-align: center;
  font-size: 16px;
  line-height: normal;
  border: none;
  outline: none;
  padding: 14px 10px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 50%;
  }

  @media screen and (max-width: 375px) {
    width: 100%;
  }
`;

const BackBtn = styled.button`
  width: 20%;
  background-color: #fff;
  color: rgba(0, 174, 192, 1);
  text-align: center;
  font-size: 16px;
  line-height: normal;
  border: 1px solid rgba(0, 174, 192, 1);
  outline: none;
  padding: 14px 10px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 50%;
  }

  @media screen and (max-width: 375px) {
    width: 100%;
  }
`;

const Box = styled.div`
  width: 30%;
  border: 1.5px solid #cfd3d4;
  display: flex;
  gap: 5px;
  box-sizing: border-box;
  padding: 5px;

  img {
    width: 30px;
    object-fit: contain;
    height: auto;
  }

  select {
    width: 100%;
    border: none;
    outline: none;
  }
`;

const Container = styled.div`
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

const Icon = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 15px;
  right: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }

  &.delete {
    position: unset;
    grid-area: delete;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    &.delete {
      justify-content: flex-end;
    }
  }
`;

const Top = styled.div`
  margin-top: 15px;
  @media screen and (max-width: 680px) {
    display: none;
  }
`;

const Bottom = styled.div``;

const InfoBox = styled.div`
  background-color: rgba(243, 243, 243, 0.57);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 8px 16px;
`;

const Div = styled.div`
  color: rgba(86, 87, 87, 1);
  font-size: 16px;
`;

const AddMore = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
`;

const Button = styled.button`
  outline: none;
  border: none;
  border: 1px solid rgba(0, 174, 192, 1);
  color: rgba(0, 174, 192, 1);
  background-color: white;
  padding: 5px 10px;
  box-sizing: border-box;
  font-size: 14px;
  cursor: pointer;
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 160px;
  overflow-x: hidden;

  @media screen and (max-width: 580px) {
    max-height: 250px;
  }

  &::-webkit-scrollbar {
    width: 2px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 174, 192, 1);
    width: 10px;
  }
`;

const Item = styled.div`
  display: grid;
  grid-template-areas: "id college status date delete";
  align-items: center;
  gap: 10px;

  @media screen and (max-width: 1400px) {
    gap: 10px;
  }

  @media screen and (max-width: 768px) {
    background-color: rgba(235, 235, 235, 1);
    padding: 5px;
    grid-template-areas:
      "college college"
      "status status"
      "date date"
      "null delete";
  }
`;

const No = styled.div`
  grid-area: id;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const BelowArea = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Para = styled.p`
  margin: 0;
  padding: 0;
  color: rgba(86, 87, 87, 1);
  font-size: 14px;

  @media screen and (max-width: 580px) {
    font-size: 12px;
  }
`;

const DateShow = styled.div`
  color: #abafb1;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  font-family: "ubuntu-regular";
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const InputDiv = styled.div`
  display: flex;
`;
