import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import arrow from "../../../../../assets/images/courseSearch/icons/arrow.svg";
import { accountsConfig } from '../../../../../axiosConfig';

function NewsAndEventsListing() {
    const [newevents, setNewevents] = useState(null);
    const [paginationData, setpaginationData] = useState({});
    const [page, setpage] = useState(1);

    useEffect(() => {
        accountsConfig.get(`general/list-news-events/?page=${page}`).then((res) => {
            if (res.data.app_data.StatusCode === 6000) {
                const { data, pagination_data } = res.data.app_data;
                setNewevents(data);
                setpaginationData(pagination_data);
            } else {
                setNewevents([]);
            }
        });
    }, [page]);

    const setPageForPagination = (pageNum) => {
        if (pageNum > 0 && pageNum <= paginationData?.total_pages) {
            setpage(pageNum);
        }
    };

    return (
        <Section>
            <Wrapper className="wrapper">
                <Title>News and Events</Title>
                <Listing>
                    {newevents === null ? (
                        <p>Loading ...</p>
                    ) : newevents.length > 0 ? (
                        newevents.map((item, index) => (
                            <Item key={index}>
                                <ImageContainer>
                                <img className='element' src={item?.thumbnail} alt="" />
                                </ImageContainer>
                                <ItemTitle>{item?.title}</ItemTitle>
                                <ItemCriteriaContainer>
                                    {item.criteria.map((criterion, index) => (
                                        <ItemCriteria key={index}>* {criterion.criteria}</ItemCriteria>
                                    ))}
                                </ItemCriteriaContainer>
                            </Item>
                        ))
                    ) : (
                        <p>No news or events available.</p>
                    )}
                </Listing>
                <Paginator>
                    <PaginatorInner>
                        {paginationData?.has_previous_page && (
                            <LeftArr onClick={() => setPageForPagination(paginationData?.current_page - 1)}>
                                <img src={arrow} alt="Previous" />
                            </LeftArr>
                        )}
                        {paginationData?.current_page - 1 > 0 && (
                            <Page onClick={() => setPageForPagination(paginationData?.current_page - 1)}>
                                {paginationData?.current_page - 1}
                            </Page>
                        )}
                        <Page className="active">
                            {paginationData?.current_page}
                        </Page>
                        {paginationData?.current_page + 1 <= paginationData?.total_pages && (
                            <Page onClick={() => setPageForPagination(paginationData?.current_page + 1)}>
                                {paginationData?.current_page + 1}
                            </Page>
                        )}
                        {paginationData?.has_next_page && <Page>..</Page>}
                        {paginationData?.has_next_page && (
                            <RightArr onClick={() => setPageForPagination(paginationData?.current_page + 1)}>
                                <img src={arrow} alt="Next" />
                            </RightArr>
                        )}
                    </PaginatorInner>
                </Paginator>
            </Wrapper>
        </Section>
    );
}

export default NewsAndEventsListing;

const Section = styled.section`
    background-color: #f8f8f8;
    padding-top: 100px;
    @media (max-width: 1425px) {
        padding-top: 90px;
    }
    @media (max-width: 1200px) {
        padding-top: 80px;
    }
    @media (max-width: 950px) {
        padding-top: 73px;
    }
`;

const Wrapper = styled.div``;

const Title = styled.h1`
    color: var(--blue);
    text-align: center;
    margin-top: 50px;
    font-size: 30px;
    @media (max-width: 768px) {
        font-size: 26px;
    }
`;

const Listing = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-top: 30px;
    @media (max-width:1024px){
        grid-template-columns: repeat(2,1fr);
    }
    @media (max-width:767px){
        grid-template-columns: repeat(1,1fr);
    }
`;

const Item = styled.div`
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
`;

const ImageContainer = styled.div`
    img{
        width: 100%;
    }
    .element {
    transition: transform 0.3s ease-in-out;
    }
    .element:hover {
        transform: scale(1.1);
    }   
    overflow: hidden;
`

const ItemCriteriaContainer = styled.div`
`
const ItemCriteria = styled.p`
    font-size: 14px;
    margin: 10px 15px;
    color: #666;
    font-style: italic;
`;


const ItemTitle = styled.p`
    font-size: 16px;
    font-weight: bold;
    border-radius: 16px;
    text-align: center;
    margin-top: 10px;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const Paginator = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const PaginatorInner = styled.div`
    display: flex;
    gap: 10px;
    margin: 30px auto;
`;

const LeftArr = styled.div`
    width: 23px;
    height: 23px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: #fff;
    border: 0.5px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
        display: block;
        width: auto;
        object-fit: contain;
    }
`;

const RightArr = styled.div`
    width: 23px;
    height: 23px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: #fff;
    border: 0.5px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
        display: block;
        width: auto;
        object-fit: contain;
        transform: rotate(180deg);
    }
`;

const Page = styled.div`
    width: 23px;
    height: 23px;
    flex-shrink: 0;
    border-radius: 50%;
    color: #00aec0;
    background-color: #fff;
    border: 0.5px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.active {
        background-color: #00aec0;
        color: white;
    }
`;
