import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

import handShake from '../../../../assets/icons/handshake.png';
import airplane from '../../../../assets/icons/airplane.png';
import meeting from '../../../../assets/icons/meeting.png';
import bulb from '../../../../assets/icons/bulb.png';

function WhyMedcity() {
  const Why = [
    { icon: handShake, title: "Extensive Partnerships", description: "Medcity Study Abroad has a rich history of processing study visas for over 30 countries and partners with more than 1,000 universities worldwide, offering over 10,000 course options." },
    { icon: airplane, title: "High Visa Success Rate", description: "Medcity boasts one of the highest visa success rates in South India, making it the top choice for study abroad aspirants throughout the country." },
    { icon: meeting, title: "Comprehensive Guidance", description: "Our highly trained counselors provide continuous support, from initial contact to beyond your arrival at your study destination, covering all aspects of the application process." },
    { icon: bulb, title: "One-Stop Solution", description: "Medcity offers a complete solution for study abroad needs, including preparation for English language tests like IELTS, PTE, or TOEFL, ensuring you find the course that matches your aspirations." },
  ];

  return (
    <Section>
      <Wrapper className="wrapper">
        <Title>Why Medcity Study Abroad?</Title>
        <Swiper
          spaceBetween={20}
          slidesPerView={4}
          autoHeight={false}
          breakpoints={{
            1024: {
              slidesPerView: 4,
            },
            767: {
              slidesPerView: 2,
            },
            200: {
              slidesPerView: 1,
            },
          }}
        >
          {/* {Why.map((why, index) => ( */}
            <SwiperSlide >
              {/* <Card>
                <img src={why.icon} alt="" />
                <Heading>{why.title}</Heading>
                <Content>{why.description}</Content>
              </Card> */}
              <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <Card>
                <img src={handShake} alt="" />
                <Heading>Extensive Partnerships</Heading>
                <Content>Medcity Study Abroad has a rich history of processing study visas for over 30 countries and partners with more than 1,000 universities worldwide, offering over 10,000 course options.</Content>
              </Card>
              </div>
              </SwiperSlide>
              <SwiperSlide >
              <Card>
                <img src={airplane} alt="" />
                <Heading>High Visa Success Rate</Heading>
                <Content>Medcity boasts one of the highest visa success rates in South India, making it the top choice for study abroad aspirants throughout the country. <br /> <br /></Content>
              </Card>
              </SwiperSlide>
              <SwiperSlide>
              <Card>
                <img src={meeting} alt="" />
                <Heading>Comprehensive Guidance</Heading>
                <Content>Our highly trained counselors provide continuous support, from initial contact to beyond your arrival at your study destination, covering all aspects of the application process.</Content>
              </Card>
              </SwiperSlide>
              <SwiperSlide>
              <Card>
                <img src={bulb} alt="" />
                <Heading>One-Stop Solution</Heading>
                <Content>Medcity offers a complete solution for study abroad needs, including preparation for English language tests like IELTS, PTE, or TOEFL, ensuring you find the course that matches your aspirations.</Content>
              </Card>
              </SwiperSlide>
          {/*  ))} */}
        </Swiper>
      </Wrapper>
    </Section>
  );
}

export default WhyMedcity;

const Section = styled.div`
  background-color: #f8f8f8;
  padding: 50px 0px;
  @media (max-width: 767px) {
    padding: 30px 0px;
  }
`;

const Wrapper = styled.div`
  .swiper-container {
    padding-bottom: 20px;
  }
`;

const Title = styled.h1`
  color: #1f93b0;
  padding-bottom: 30px;
  @media (max-width: 767px) {
    font-size: 20px;
  }
`;

const Card = styled.div`
  border-radius: 20px;
  background-color: white;
  width: 100%;
  padding: 24px;
  margin: 1px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content:space-between;
  min-height:335px;
  height:100%;
  gap: 20px;
  img {
    width: 40px;
  }

`;

const Heading = styled.h5`
  font-family: 'ubuntu-bold';
  font-size: 18px;
`;

const Content = styled.p`
  font-family: 'ubuntu-regular';
  color: #2b2b2b;
  font-size: 15.5px;
  flex-grow:1;
`;
