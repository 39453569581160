import React, { useEffect, useState } from "react";
import HeaderEdit from "../includes/HeaderEdit";
import styled from "styled-components";
import leftarrow from "../../../../assets/images/blog/left.png";
import rightarrow from "../../../../assets/images/blog/right.png";
import banner from "../../../../assets/images/blog/blogBanner.jpg";
import thumb from "../../../../assets/images/blog/blogthumb.jpg";
import thumb1 from "../../../../assets/images/blog/blogthumb2.jpg";
import thumb2 from "../../../../assets/images/blog/blogthumb3.jpg";
import thumb3 from "../../../../assets/images/blog/blogthumb4.jpg";
import searchIcon from "../../../../assets/images/blog/searchicon.png";
import { Link } from "react-router-dom";
import { accountsConfig } from "../../../../axiosConfig";
import { blog_list, monthsArray } from "../../../general/commonArrays";

export default function Blogs() {
  const [getData, setData] = useState([]);
  const [getTags, setTags] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(false);
  const [showFullMonth, setShowFullMonth] = useState(false);
  const [showFullTags, setFullTags] = useState(false);
  const [monthId, setMonthId] = useState("");
  const [searchedItems, setSearcheditems] = useState("");

  const showPrevious = () => {
    setFade(true);
    setTimeout(() => {
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + getData.length) % getData.length
      );
      setFade(false);
    }, 500);
  };

  const showNext = () => {
    setFade(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % getData.length);
      setFade(false);
    }, 500);
  };

  const currentBlog = getData[currentIndex];
  useEffect(() => {
    accountsConfig
      .get(`general/list-blogs/`, {
        params: {
          month_number: monthId,
          q: searchedItems,
        },
      })
      .then((res) => {
        if (res.data.app_data.StatusCode == 6000) {
          setData(res.data.app_data.data);
        } else {
          setData([]);
        }
      });
  }, [monthId]);
  
  //list labels
  useEffect(() => {
    accountsConfig
      .get(`general/list-tags/`, {
        params: {
          month_number: monthId,
          q: searchedItems,
        },
      })
      .then((res) => {
        if (res.data.app_data.StatusCode == 6000) {
          setTags(res.data.app_data.data);
        } else {
          setTags([]);
        }
      });
  }, [monthId]);

  return (
    <Container>
      <HeaderEdit backgroundCl="#00AEC0" />
      <Content>
        <Wrapper className="wrapper">
          <Section>
            <Heading>Blogs</Heading>
            <Banner className="banner">
              <ImageContainer>
                <img
                  src={currentBlog?.image}
                  alt=""
                  className={`fade-image ${fade ? "fade" : ""}`}
                />
              </ImageContainer>
              {/* <ImageContainerMob>
                <img
                  src={currentBlog?.mob_image}
                  alt=""
                  className={`fade-image ${fade ? "fade" : ""}`}
                />
              </ImageContainerMob> */}
              <SliderSection>
                <CoverCard>
                  <Card>
                    <Title>{currentBlog?.title}</Title>
                    <Para>{currentBlog?.sub_title}</Para>
                    <Button to={`/blogs/${currentBlog?.id}`}>Read More</Button>
                  </Card>
                  <ArrowButtons>
                    <Arrow onClick={() => showPrevious()}>
                      <img src={leftarrow} alt="image" />
                    </Arrow>
                    <Arrow onClick={() => showNext()}>
                      <img src={rightarrow} alt="image" />
                    </Arrow>
                  </ArrowButtons>
                </CoverCard>
              </SliderSection>
            </Banner>
            <BlogContainer>
              <BolgCards>
                {getData.slice(0, 4).map((obj, index) => (
                  <BlogCard key={index} to={`/blogs/${obj?.id}`} >
                    <ImageContainerBlog>
                      <img src={obj.thumbnail} alt="" />
                    </ImageContainerBlog>
                    <Label>
                      <Date>{obj.created_at}</Date>
                      <Date>3 min read</Date>
                    </Label>
                    <Text>{obj.title}</Text>
                    <Description>{obj.sub_title}</Description>
                  </BlogCard>
                ))}
              </BolgCards>
              <BlogFilter>
                <Search>
                  <CoverSearch>
                    <Icon>
                      <img src={searchIcon} alt="" />
                    </Icon>
                    <Input
                      type="text"
                      placeholder="Search"
                      value={searchedItems}
                      onChange={(e) => setSearcheditems(e.target.value)}
                    />
                  </CoverSearch>
                </Search>
                <Archive>
                  <CoverTop>
                    <TextTitle>Archive</TextTitle>
                    <ArrowTop>
                      <img src="" alt="" />
                    </ArrowTop>
                  </CoverTop>
                  <Items>
                    {showFullMonth
                      ? monthsArray.map((obj, index) => (
                          <Item key={index} onClick={() => setMonthId(obj.id)}>
                            {obj.month} 2023
                          </Item>
                        ))
                      : monthsArray.slice(0, 4).map((obj, index) => (
                          <Item key={index} onClick={() => setMonthId(obj.id)}>
                            {obj.month} 2023
                          </Item>
                        ))}
                  </Items>
                  <Show onClick={() => setShowFullMonth(!showFullMonth)}>
                    <AddIcon>
                      {showFullMonth ? (
                        <i class="ri-subtract-line"></i>
                      ) : (
                        <i class="ri-add-line"></i>
                      )}
                    </AddIcon>
                    {showFullMonth ? (
                      <Span>Show Less</Span>
                    ) : (
                      <Span>Show More</Span>
                    )}
                  </Show>
                </Archive>
                <Archive>
                  <CoverTop>
                    <TextTitle>Labels</TextTitle>
                    <ArrowTop>
                      <img src="" alt="" />
                    </ArrowTop>
                  </CoverTop>
                  <Items>
                    {showFullTags
                      ? getTags.map((item, index) => (
                          <Item key={index}>{item.name}</Item>
                        ))
                      : getTags
                          .slice(0, 4)
                          .map((item, index) => (
                            <Item key={index}>{item.name}</Item>
                          ))}
                  </Items>
                  <Show onClick={() => setFullTags(!showFullTags)}>
                    <AddIcon>
                      <i class="ri-add-line"></i>
                    </AddIcon>
                    {showFullTags ? (
                      <Span>Show Less</Span>
                    ) : (
                      <Span>Show More</Span>
                    )}
                  </Show>
                </Archive>
              </BlogFilter>
            </BlogContainer>
          </Section>
        </Wrapper>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  background-color: #f8f8f8;
`;
const Content = styled.div`
  padding-top: 180px;
  @media all and (max-width: 1080px) {
    padding-top: 110px;
  }
`;
const Heading = styled.h1`
  color: #1897b2;
  text-align: center;
  font-size: 45px;
  margin-bottom: 30px;
`;
const Wrapper = styled.div``;
const Section = styled.div``;
const Banner = styled.div`
  position: relative;

  @media screen and (max-width: 580px) {
    min-height: 415px;
  }

  @media screen and (max-width: 350px) {
    min-height: 450px;
  }
`;
const ImageContainerMob = styled.div`
  display: none;
  img {
    display: block;
    width: 100%;
  }
  @media (max-width: 480px) {
    display: block;
  }
`;
const ImageContainer = styled.div`
  img {
    display: block;
    width: 100%;
    object-fit: contain;

    @media screen and (max-width: 580px) {
      object-fit: cover;
      height: 100%;
    }
  }

  @media screen and (max-width: 580px) {
    position: absolute;
    inset: 0;
  }
`;
const SliderSection = styled.div`
  position: absolute;
  bottom: 40px;
  left: 40px;
  width: 45%;
  @media all and (max-width: 980px) {
    width: 90%;
  }
  @media all and (max-width: 480px) {
    left: 20px;
    top: 20px;
  }

  @media all and (max-width: 350px) {
    left: 8px;
    width: 95%;
  }
`;
const CoverCard = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-end;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    gap: 10px;
  }
`;
const Card = styled.div`
  background-color: #fff;
  padding: 30px;

  @media screen and (max-width: 480px) {
    padding: 15px;
  }
`;
const Title = styled.h3`
  color: #147d92;
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 20px;
`;
const Para = styled.p`
  color: #2b2b2b;
  line-height: 26px;
  margin-bottom: 10px;
`;
const Button = styled(Link)`
  background-color: #00aec0;
  height: 35px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
`;
const ArrowButtons = styled.div`
  @media screen and (max-width: 480px) {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;
const Arrow = styled.div`
  background-color: #fff;
  width: 50px;
  cursor: pointer;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
`;
const BlogContainer = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 50px;
  @media all and (max-width: 480px) {
    flex-direction: column;
  }
`;
const BolgCards = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  width: 60%;
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const BlogCard = styled(Link)`
  width: 48%;
  @media all and (max-width: 980px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;
const ImageContainerBlog = styled.div`
  img {
    width: 100%;
    display: block;
  }
`;
const Label = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
`;
const Date = styled.div`
  color: #909090;
  font-size: 14px;
`;
const Text = styled.h3`
  color: #147d92;
  font-size: 16px;
  margin-bottom: 10px;
`;
const Description = styled.p`
  color: #2b2b2b;
  line-height: 24px;
  text-align: justify;
`;
const BlogFilter = styled.div`
  width: 40%;
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const Search = styled.div`
  background-color: #fff;
  padding: 20px;
  margin-bottom: 30px;
`;
const CoverSearch = styled.div`
  display: flex;
`;
const Icon = styled.div`
  background-color: #00aec0;
  padding: 10px;
`;
const Input = styled.input`
  padding-left: 20px;
  outline: none;
  width: 100%;
  border: none;
  background: #f9f9f9;
`;
const Archive = styled.div`
  background-color: #fff;
  padding: 20px;
  margin-bottom: 50px;
`;
const CoverTop = styled.div``;
const TextTitle = styled.h3`
  color: #147d92;
`;
const ArrowTop = styled.div``;
const Items = styled.div``;
const Item = styled.div`
  color: #147d92;
  cursor: pointer;
  margin-bottom: 20px;
  padding: 10px;
  border-bottom: 1px solid #d9d9d9;
`;
const Show = styled.div`
  display: flex;
  cursor: pointer;
  font-size: 12px;
`;
const AddIcon = styled.div`
  color: #147d92;
`;
const Span = styled.div`
  color: #147d92;
`;
