import React, { useState } from "react";
import styled from "styled-components";
import WorkTab from "../title-copy-immigration/WorkTab";
import Migration from "../title-copy-immigration/Migration";
import Study from "../title-copy-immigration/Study";

function FeaturesSection() {
  const [switchTabs, setSwitchTabs] = useState("Study");
  const tabs = [
    {
      name: "Study",
      subtitle: "Global Education Solutions.",
    },
    {
      name: "Work",
      subtitle: "Opens Doors to Careers.",
    },
    {
      name: "Migrate",
      subtitle: "Secure Permanent Residency.",
    },
  ];

  return (
    <Section>
      <Wrapper className="wrapper">
        <TopContent>
          <Heading>Your Immigration Gateway</Heading>
          <SubHeading>
          Unlock Global Opportunities: Your Gateway to International Education and Immigration Success
          </SubHeading>
        </TopContent>
        <Content>
          <Tabs type={switchTabs}>
            {tabs.map((item) => (
              <Tab
                className={switchTabs == item.name && "active"}
                onClick={() => setSwitchTabs(item.name)}
              >
                <TabTitle className={switchTabs == item.name && "active"}>
                  {item.name}
                </TabTitle>
                <TabSubtitle>{item.subtitle}</TabSubtitle>
              </Tab>
            ))}
          </Tabs>
          {switchTabs == "Study" ? (
            <Study />
          ) : switchTabs == "Work" ? (
            <WorkTab />
          ) : switchTabs == "Migrate" ? (
            <Migration />
          ) : (
            ""
          )}
        </Content>
      </Wrapper>
    </Section>
  );
}

export default FeaturesSection;

const Section = styled.div``;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;

  @media (max-width: 900px) {
    width: 100% !important;
  }

  @media (max-width: 580px) {
    gap: 50px;
  }
`;

const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: 700px) {
    width: 85%;
    margin: 0 auto;
  }

  @media (max-width: 580px) {
    width: 95%;
  }
`;

const Heading = styled.h1`
  text-align: center;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #21a9c1;

  @media (max-width: 700px) {
    font-size: 40px;
  }

  @media (max-width: 580px) {
    font-size: 26px;
  }
`;
const SubHeading = styled.p`
  color: #000;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (max-width: 700px) {
    font-size: 16px;
  }

  @media (max-width: 580px) {
    font-size: 14px;
  }
`;

const Content = styled.div``;

const Tabs = styled.div`
  display: flex;
  gap: 25px;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: 0.8s ease;
  &::after {
    transition: 0.8s ease;
    content: "";
    position: absolute;
    height: 6px;
    width: 20%;
    background-color: #1f93b0;
    bottom: 0;
    left: ${({ type }) =>
      type == "Study"
        ? "8%"
        : type == "Work"
        ? "40%"
        : type == "Migrate"
        ? "72%"
        : ""};
    border-radius: 8px 8px 0 0;
  }
`;

const Tab = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  gap: 8px;
  position: relative;
  min-height: 90px;
  cursor: pointer;

  &.active {
    &::after {
      display: block;
    }
  }
  @media (max-width: 750px) {
    min-height: 60px;
  }
`;

const TabTitle = styled.h4`
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #8a8a8a;
  text-align: center;
  &.active {
    color: #21a9c1;
  }
  @media (max-width: 480px) {
    font-size: 26px;
  }
`;

const TabSubtitle = styled.p`
  color: #202730;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  @media (max-width: 750px) {
    display: none;
  }
`;
