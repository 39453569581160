import React from "react";
import CountrySlider from "./CountrySlider";
import { styled } from "styled-components";

function Destinations({ long, lat, setLat, setLong }) {
  <script src="https://code.jquery.com/jquery-3.5.1.min.js"></script>;

  return (
    <Container>
      <DestinationCard>
        {/* <Wrapper className="wrapper"> */}
        <Title>Destinations</Title>
        <SubTitle>Let’s help you live your dream</SubTitle>
        <Description>
          Could your ideal course be in Australia, Canada, New Zealand, the UK
          or the US?
        </Description>
        {/* </Wrapper> */}
      </DestinationCard>
      <CountrySlider />
    </Container>
  );
}

export default Destinations;

const Container = styled.div`
  padding: 120px 0 0 0;
  background: #eaebeb;
  position: relative;
`;
const Wrapper = styled.div``;
const DestinationCard = styled.div`
  width: 70%;
  position: absolute;
  top: 0px;
  transform: translate(-50%, -50%);
  left: 50%;
  right: 50%;
  margin: 0 auto;
  border-radius: 8px;
  background: rgb(0, 100, 147);
  background: linear-gradient(
    -45deg,
    rgba(0, 100, 147, 1) 0%,
    rgba(0, 177, 195, 1) 100%
  );
  color: #fff;
  padding: 60px 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Title = styled.h2``;
const SubTitle = styled.h3`
  font-family: "ubuntu-regular";
`;
const Description = styled.p`
  font-family: "ubuntu-light";
`;
