import React, { useState } from "react";
import styled from "styled-components";

import img from "../../assets/images/course/modal/man-standing.png";
import stats from "../../assets/images/course/modal/icon/stats.svg";
import profile from "../../assets/images/course/modal/icon/profile.svg";
import quote from "../../assets/images/course/modal/icon/qoute.svg";
import closebtn from "../../assets/images/course/modal/icon/closebtn.svg";
import LoginInner from "./includes/LoginInner";
import CreateAcInner from "./includes/CreateAcInner";

function CreateAccountModal({ modal, setModal }) {
    
  const handleModal = () => {
    setModal(false);
  };

  return (
    <Section className={modal && "active"}>
      <OverLay onClick={handleModal} />
      <Modal>
        <Container>
          <LeftSide>
            <Stats>
              <img src={stats} alt="" />
              <Content>
                300+ <br />
                <span>Secured Admissions</span>
              </Content>
            </Stats>
            <Review>
              <Icon>
                <img src={profile} alt="" />
              </Icon>
              <Info>
                <p>Adam Sandler</p>
                <span>BDS at GM University</span>
              </Info>
              <Data>
                <img src={quote} alt="" />
                <h6>
                  Great platform for students who are searching for new career
                  heights.
                </h6>
              </Data>
            </Review>
          </LeftSide>
          <RightSide>
            <Top>
              <Heading>Fill in your basic details</Heading>
              <CloseBtn onClick={handleModal}>
                <img src={closebtn} alt="" />
              </CloseBtn>
            </Top>
            <Bottom>
              <CreateAcInner/>
            </Bottom>
          </RightSide>
        </Container>
      </Modal>
    </Section>
  );
}
export default CreateAccountModal;

const Section = styled.section`
  position: fixed;
  inset: 0;
  backdrop-filter: blur(2px);
  z-index: 255;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0000008a;
  transform: scale(0, 0);

  &.active {
    transform: scale(1, 1);
  }
`;

const Modal = styled.div`
  box-sizing: border-box;
  height: 90%;
  max-height: 625px;
  width: 75%;
  max-width: 1300px;
  margin: auto;
  position: relative;
  z-index: 256;

  @media screen and (max-width: 900px) {
    width: 80%;
  }
  @media screen and (max-width: 580px) {
    width: 90%;
  }
  @media screen and (max-width: 380px) {
    width: 95%;
  }
`;

const Container = styled.div`
  display: flex;
  height: 100%;
`;

const OverLay = styled.div`
  position: absolute;
  inset: 0;
`;

const LeftSide = styled.div`
  width: 35%;
  background-color: #f8f8fd;
  height: 100%;
  background-image: url(${img});
  background-repeat: no-repeat;
  background-size: 55%;
  background-position: 50% 100%;
  position: relative;

  @media screen and (max-width: 1350px) {
    width: 30%;
    background-size: 65%;
  }

  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

const RightSide = styled.div`
  width: 65%;
  height: 100%;
  background-color: white;
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: ${(props) => (parseInt(props.level) === 4 ? "5px" : "25px")};

  @media screen and (max-width: 1250px) {
    padding: 22px;
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  @media screen and (max-width: 850px) {
    padding: 20px;
  }

  @media screen and (max-width: 580px) {
    padding: 10px;
  }
`;

const Stats = styled.div`
  position: absolute;
  height: 90px;
  background-color: #fff;
  left: 50px;
  top: 30px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: 12px 12px 10px 1px rgb(0 174 192 / 6%);

  img {
    width: 30px;
  }
`;

const Content = styled.div`
  color: #202430;
  font-family: "ubuntu-bold";
  font-size: 21px;
  width: 100%;
  span {
    color: #c6c6c6;
    font-family: "ubuntu-regular";
    font-size: 15px;
  }
`;

const Review = styled.div`
  width: 200px;
  position: absolute;
  box-sizing: border-box;
  padding: 12px;
  background-color: rgba(255, 255, 255, 1);
  bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  right: 10px;
`;

const Icon = styled.div`
  position: absolute;
  right: 10px;
  top: -21px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  p {
    font-family: "ubuntu-medium";
    margin: 0;
    padding: 0;
    color: gray;
    font-size: 16px;
    font-weight: unset;
  }

  span {
    margin: 0;
    padding: 0;
    color: gray;
    font-size: 12px;
    font-weight: unset;
  }
`;

const Data = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 6px;

  img {
    width: 20px;
    height: 20px;
  }

  h6 {
    font-weight: unset;
    font-size: 17px;
    color: #000;
    line-height: 22px;
    font-weight: unset;
  }
`;

const Top = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10%;
`;

const Bottom = styled.div`
  height: 90%;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
`;

const Heading = styled.h3`
  width: 90%;
  padding: 0;
  margin: 0;
  color: rgba(86, 87, 87, 1);
  font-size: 21px;

  @media screen and (max-width: 580px) {
    font-size: 18px;
  }
`;

const CloseBtn = styled.div`
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
`;

