import React, { useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import { useState } from "react";

import downArrow from "../../../../assets/images/careers/icons/down_arrow.svg";
import pdf from "../../../../assets/images/careers/icons/pdf.svg";
import date from "../../../../assets/images/course/modal/icon/date.svg";
import {
  SET_BASIC_DATA,
  SET_CURRENT_EDUCATION_INFO,
  UPDATE_PHASE,
} from "../../../context/types";
import { StoreContext } from "../../../context/Store";
import ButtonLoader from "../../../general/loader/ButtonLoader";

function Phase2({ courseData }) {
  const { state, dispatch } = useContext(StoreContext);
  const [checkbox, setcheckbox] = useState(null);
  const [array, setarray] = useState([]);
  const [error, seterror] = useState("");
  const [loader, setloader] = useState(false);
  const [program, setprogram] = useState("");
  const [form, setForm] = useState({});

  const statusList = [
    "Currently Pursuing 11th Standard",
    "Currently Pursuing 12th Standard",
    "Dropout -  Year off",
    "Currently pursuing Graduation",
  ];

  const formRef = useRef();
  const btnRef = useRef();

  useEffect(() => {
    setprogram(courseData?.programme);
    if (!state?.apply_details?.basic_info?.college) {
      const data = {
        course_name: courseData?.course_name,
        course: courseData?.course,
        college: courseData?.university_name,
        program: courseData?.programme,
      };

      dispatch({ type: SET_BASIC_DATA, payload: data });
    }
    if (
      Object.keys(state?.apply_details?.current_educational_info).length > 0
    ) {
      setForm(state?.apply_details?.current_educational_info);
      setcheckbox(state?.apply_details?.current_educational_info?.exam_status);
      setarray(
        JSON.parse(state?.apply_details?.current_educational_info?.exams_info)
      );
    }
  }, [courseData?.id]);

  const handleCheckbox = (value) => {
    setarray([]);
    if (value === checkbox) {
      setcheckbox(null);
      return;
    } else {
      AddItem(value);
    }
    setcheckbox(value);
  };

  const AddItem = (type) => {
    if (type === "no") {
      setarray([]);
      return;
    }
    if (type === "yes") {
      const item = {
        id: array.length,
        name: "",
        score: "",
      };
      setarray((prev) => [...prev, item]);
    } else if (type === "booked") {
      const item = {
        id: array.length,
        name: "",
        date: "",
      };
      setarray((prev) => [...prev, item]);
    }
  };

  const handleDatechange = (e, id) => {
    setarray((prevArray) => {
      return prevArray.map((item) => {
        if (item.id === id) {
          // Update the item with the new data
          return { ...item, date: e.target.value };
        }
        return item;
      });
    });
  };

  const handleInputchange = (e, id, type) => {
    if (type === "name") {
      setarray((prevArray) => {
        return prevArray.map((item) => {
          if (item.id === id) {
            // Update the item with the new data
            return { ...item, name: e.target.value };
          }
          return item;
        });
      });
    } else {
      setarray((prevArray) => {
        return prevArray.map((item) => {
          if (item.id === id) {
            // Update the item with the new data
            return { ...item, score: e.target.value };
          }
          return item;
        });
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkbox === null) {
      seterror(2);
      setTimeout(() => {
        seterror("");
      }, 3500);
      return;
    }

    if (!areAllObjectsValid(array)) {
      seterror(3);
      setTimeout(() => {
        seterror("");
      }, 3500);
      return;
    }

    setloader(true);
    const formData = new FormData(formRef.current);
    formData.append("exam_status", checkbox);
    formData.append("exams_info", JSON.stringify(array));
    const data = {};
    for (const [key, value] of formData.entries()) {
      data[key] = value;
    }
    dispatch({ type: SET_CURRENT_EDUCATION_INFO, payload: data });
    setloader(false);
    dispatch({ type: UPDATE_PHASE, payload: 4 });
  };

  // Function to check if all fields in an object are not empty
  function areAllFieldsNotEmpty(obj) {
    for (let key in obj) {
      if (key !== "id") {
        if (obj.hasOwnProperty(key) && !obj[key]) {
          return false; // Found an empty field
        }
      }
    }
    return true;
  }

  // Function to check all objects in the array
  function areAllObjectsValid(array) {
    for (let obj of array) {
      if (!areAllFieldsNotEmpty(obj)) {
        return false;
      }
    }
    return true;
  }

  const handleBtnClick = () => {
    btnRef.current.click();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <InputsDiv onSubmit={(e) => handleSubmit(e)} ref={formRef}>
        {program !== "Masters" ? (
          <InputCover className="current_status">
            <Label>
              Your Current Education Status<span>*</span>
            </Label>
            <Select required name="current_status" value={form?.current_status || ''} onChange={(e)=>handleChange(e)}>
              {statusList.map((item) => (
                <Option value={item}>{item}</Option>
              ))}
            </Select>
          </InputCover>
        ) : (
          <>
            <InputCover className="institute">
              <Label>
                Select Graduation College/Institute<span>*</span>
              </Label>
              <Input
                placeholder="Enter the institute"
                type="text"
                name="institute"
                value={form?.institute || ""}
                onChange={(e) => handleChange(e)}
                required
              />
            </InputCover>

            <InputCover className="courses">
              <Label>
                Which Course Did You Do ?<span>*</span>
              </Label>
              <Input
                placeholder="Enter the course"
                type="text"
                name="course"
                value={form?.course || ""}
                onChange={(e) => handleChange(e)}
                required
              />
            </InputCover>

            <InputCover className="percentage">
              <Label>
                Graduation Percentage <span style={{ color: "gray" }}>(%)</span>
                <span>*</span>
              </Label>
              <Input
                placeholder="Enter the percentage"
                type="number"
                name="percentage"
                value={form?.percentage || ""}
                onChange={(e) => handleChange(e)}
                required
              />
            </InputCover>

            <InputCover className="exp">
              <Label htmlFor="exp">
                Working Experience<span>*</span>
              </Label>
              <Input
                id="exp"
                placeholder="Years"
                name="work_experience"
                type="number"
                value={form?.work_experience || ""}
                onChange={(e) => handleChange(e)}
                required
              />
            </InputCover>
          </>
        )}
        <HiddedBtn ref={btnRef}></HiddedBtn>
      </InputsDiv>
      <ExamDetails>
        <Div>
          <Info className="text-sm">
            {error === 2 || error === 3 ? (
              <p className="error">
                {error === 2
                  ? "Please choose any one"
                  : "Please fill exam details"}
                .
              </p>
            ) : (
              "Have You Appeared or Scheduled For any Entrance Exams?"
            )}
          </Info>
          <BoxContainer>
            <CheckboxWrapper>
              <Checkbox
                checked={checkbox === "no"}
                type="checkbox"
                onChange={() => handleCheckbox("no")}
              />
              No
            </CheckboxWrapper>
            <CheckboxWrapper>
              <Checkbox
                checked={checkbox === "yes"}
                type="checkbox"
                onChange={() => handleCheckbox("yes")}
              />
              Yes
            </CheckboxWrapper>
            <CheckboxWrapper>
              <Checkbox
                checked={checkbox === "booked"}
                type="checkbox"
                onChange={() => handleCheckbox("booked")}
              />
              Booked
            </CheckboxWrapper>
          </BoxContainer>
        </Div>
        {array.length > 0 && (
          <ExamInfos>
            {checkbox === "yes" &&
              array.map((item) => (
                <ExamScore>
                  <Name>&#9679; Please enter the exam name.</Name>
                  <InputCover className="w-15">
                    <Input
                      className="exam-info"
                      value={item?.name}
                      type="text"
                      placeholder="Name"
                      onChange={(e) => handleInputchange(e, item?.id, "name")}
                    />
                  </InputCover>
                  <Name>&#9679; Please enter the score obtained</Name>
                  <InputCover className="w-15">
                    <Input
                      className="exam-info"
                      value={item?.score}
                      type="number"
                      placeholder="Score"
                      onChange={(e) => handleInputchange(e, item?.id, "score")}
                    />
                  </InputCover>
                </ExamScore>
              ))}

            {checkbox === "booked" &&
              array.map((item) => (
                <ExamDate>
                  <Name>&#9679; Please enter the exam name.</Name>
                  <InputCover className="w-15">
                    <Input
                      className="exam-info"
                      value={item?.name}
                      type="text"
                      placeholder="Name"
                      onChange={(e) => handleInputchange(e, item?.id, "name")}
                    />
                  </InputCover>
                  <Name>&#9679; Please enter the exam date</Name>
                  <InputCover className="w-15 date_cover">
                    <Icon>
                      <img src={date} alt="" />
                    </Icon>
                    <DateShow>{item?.date}</DateShow>
                    <Input
                      id="date_input"
                      className="date exam-info"
                      type="date"
                      onChange={(e) => handleDatechange(e, item?.id)}
                    />
                  </InputCover>
                </ExamDate>
              ))}
            <AddMore onClick={() => AddItem(checkbox)}>+ Add More</AddMore>
          </ExamInfos>
        )}
      </ExamDetails>
      <Container>
        {/* <BackBtn>Back</BackBtn> */}
        {loader ? (
          <SubmiBtn>
            <ButtonLoader />
          </SubmiBtn>
        ) : (
          <SubmiBtn onClick={handleBtnClick}>Submit</SubmiBtn>
        )}
      </Container>
    </>
  );
}

export default Phase2;

const InputsDiv = styled.form`
  display: grid;
  gap: 24px;
  grid-template-areas:
    "institute courses"
    "percentage exp";

  @media screen and (max-width: 768px) {
    grid-template-areas:
      "institute institute"
      "courses courses"
      "percentage exp";
  }

  @media screen and (max-width: 490px) {
    grid-template-areas:
      "institute institute"
      "courses courses"
      "percentage percentage"
      "exp exp";
  }
`;

const InputCover = styled.div`
  border: 1.5px solid #cfd3d4;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  background-color: #fff;

  &.date_cover {
    padding: 10px 5px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  &.w-70 {
    width: 70%;
  }

  &.current_status {
    min-height: 60px;
  }

  &.w-15 {
    width: 18%;
  }

  &.institute {
    grid-area: institute;
  }

  &.courses {
    grid-area: courses;
  }

  &.percentage {
    grid-area: percentage;
  }

  &.exp {
    grid-area: exp;
  }

  @media screen and (max-width: 680px) {
    &.w-15 {
      width: 80%;
    }
  }

  @media screen and (max-width: 490px) {
    &.w-15 {
      width: 100%;
    }
  }
`;

const Heading = styled.div`
  color: #565757;
  font-size: 21px;
  font-style: normal;
  line-height: normal;
  font-family: "ubuntu-medium";
`;

const Label = styled.label`
  color: #00aec0;
  font-size: 13px;
  font-style: normal;
  line-height: normal;
  position: relative;
  cursor: pointer;

  span {
    color: red;
  }

  &.role-select {
    &::after {
      content: "";
      position: absolute;
      top: 5px;
      right: 0;
      background-image: url(${downArrow});
      background-size: contain;
      width: 25px;
      height: 30px;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }

  &.cv {
    &::after {
      content: "";
      position: absolute;
      top: 5px;
      right: 0;
      background-image: url(${pdf});
      background-size: contain;
      background-repeat: no-repeat;
      width: 25px;
      height: 30px;
      cursor: pointer;
    }
  }

  &.cv-label {
    color: #abafb1;
    width: max-content;
    border-bottom: 1px solid #abafb1;
    cursor: pointer;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  gap: 5px;

  &.phone {
    grid-area: phone;
  }
`;

const Input = styled.input`
  border: none;
  outline: none;
  padding: 0;
  &::placeholder {
    color: #abafb1;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    font-family: "ubuntu-regular";
    padding: 0;
  }

  &.date {
    &::-webkit-calendar-picker-indicator {
      opacity: 0;
      position: absolute;
      right: 5px;
      z-index: 2;
      height: 22px;
      width: 22px;
    }
    &::-webkit-datetime-edit {
      opacity: 0;
    }
    width: 20%;
  }

  @media screen and (max-width: 680px) {
    &.exam-info {
      width: 60%;
    }
  }
`;

const Select = styled.select`
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #abafb1;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  font-family: "ubuntu-regular";
  position: absolute;
  left: 0;
  right: 0;
  padding-left: 15px;
  padding-top: 25px;
  background-color: transparent;

  option:hover {
    background-color: red;
  }
`;

const Option = styled.option`
  color: #000000;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  font-family: "ubuntu-regular";
  background-color: #ffffff;
  padding: 20px !important;
`;

const FileInput = styled.input`
  display: none !important;
`;

const SubmiBtn = styled.button`
  width: 20%;
  background-color: rgba(0, 174, 192, 1);
  color: #fff;
  text-align: center;
  font-size: 16px;
  line-height: normal;
  border: none;
  outline: none;
  padding: 14px 10px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 50%;
  }

  @media screen and (max-width: 375px) {
    width: 100%;
  }
`;

const BackBtn = styled.button`
  width: 20%;
  background-color: #fff;
  color: rgba(0, 174, 192, 1);
  text-align: center;
  font-size: 16px;
  line-height: normal;
  border: 1px solid rgba(0, 174, 192, 1);
  outline: none;
  padding: 14px 10px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 50%;
  }

  @media screen and (max-width: 375px) {
    width: 100%;
  }
`;

const Box = styled.div`
  width: 30%;
  border: 1.5px solid #cfd3d4;
  display: flex;
  gap: 5px;
  box-sizing: border-box;
  padding: 5px;

  img {
    width: 30px;
    object-fit: contain;
    height: auto;
  }

  select {
    width: 100%;
    border: none;
    outline: none;
  }
`;

const Container = styled.div`
  margin-top: 35px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

const Info = styled.p`
  font-size: 16px;
  color: #000;

  &.text-sm {
    font-size: 14px;
    color: rgba(86, 87, 87, 1);
  }

  span {
    color: rgba(0, 174, 192, 1);
  }

  p {
    &.error {
      color: red;
    }
  }
`;

const ExamDetails = styled.div`
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 25px;
`;

const Div = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 680px) {
    flex-direction: column;
    gap: 15px;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const BoxContainer = styled.form`
  display: flex;
  gap: 25px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Checkbox = styled.input`
  height: 20px;
  width: 20px;
  margin-right: 8px;

  &:checked {
    background-color: red;
  }
`;

const Active = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

const ActiveTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 15px;
  color: rgba(86, 87, 87, 1);

  ul {
    padding: 0px 15px;
  }
`;

const Inner = styled.div``;

const Score = styled.div`
  height: 50px;
  width: 60px;
  border: 1px solid #bebebe;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  input {
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    text-align: center;

    &::placeholder {
      color: #abafb1;
      font-size: 12px;
      font-style: normal;
      line-height: normal;
      font-family: "ubuntu-regular";
      padding: 0;
    }
  }
`;

const ExamInfos = styled.div`
  margin-top: 15px;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 100px;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: #fff8f8;
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 174, 192, 1);
    width: 10px;
  }

  @media screen and (max-width: 680px) {
    max-height: 250px;
    width: 90%;
    gap: 10px;
  }
`;

const ExamScore = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;

  @media screen and (max-width: 680px) {
    flex-direction: column;
  }
`;

const ExamDate = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;

  @media screen and (max-width: 680px) {
    flex-direction: column;
  }
`;

const Name = styled.p`
  color: rgba(86, 87, 87, 1);
  padding: 0;
  margin: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;

  @media screen and (max-width: 680px) {
    width: max-content;
    text-align: left;
  }
`;

const AddMore = styled.div`
  margin-top: 14px;
  color: rgba(0, 174, 192, 1);
  font-size: 12px;
  float: right;
  text-align: end;
  cursor: pointer;
`;

const Icon = styled.label`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 5px;
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }

  &.delete {
    position: unset;
    grid-area: delete;
  }
`;

const DateShow = styled.div`
  color: #abafb1;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  font-family: "ubuntu-regular";
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HiddedBtn = styled.button`
  display: none;
`;
