import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";

import bg from "../../../../../assets/images/courseSearch/bg.png";
import img from "../../../../../assets/images/jobs/image-1.png";
import img1 from "../../../../../assets/images/jobs/img-2.png";
import img2 from "../../../../../assets/images/jobs/img-3.png";
import img3 from "../../../../../assets/images/jobs/img-4.png";

import search from "../../../../../assets/images/courseSearch/icons/searchIcon.svg";

function Banner({handleChange,searchedItem}) {
  const [slide, setslide] = useState(false); 
  const imgs = [img,img1,img2,img3]
  
  const settings = {
    dots:false,
    arrows:false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  useEffect(() => {
    setTimeout(() => {
      setslide(true);
    }, 300);
    return () => {
      setslide(false);
    };
  }, []);

  return (
    <BannerDiv>
      <BottomLayer>
        <MiddleLayer className={slide && "slide"}>
          <TopLayer className={slide && "slide"}>
            {/* <ImageBlck/> */}
            <Slider {...settings}>
              {
                imgs.map((item)=>(
                  <Item img={item} className="item">
                    {/* <img src={item} alt="" /> */}
                  </Item>
                ))
              }
            </Slider>
          </TopLayer>
        </MiddleLayer>
      </BottomLayer>
      <BannerInner>
        <Heading>Explore Your Global Education Opportunities</Heading>
        <SubHeading>Explore Your Global Education Opportunities</SubHeading>
        <SearchBar>
          <CoverIcon>
            <Icon>
              <img src={search} alt="search-icon" />
            </Icon>
          </CoverIcon>
          <SearchInput onChange={(e)=>handleChange(e)} type="text" value={searchedItem} placeholder="Search your Job..." />
        </SearchBar>
      </BannerInner>
    </BannerDiv>
  );
}

export default Banner;

const BannerDiv = styled.div`
  /* background: url(${bg}); */
  height: 460px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  @media all and (max-width: 768px) {
    height: 475px;
  }
  @media all and (max-width: 580px) {
    height: 375px;
  }
`;

const SearchBar = styled.div`
  display: flex;
  width: 50%;

  @media all and (max-width: 900px) {
    width: 55%;
  }
  @media all and (max-width: 580px) {
    width: 100%;
    height: 50px;
  }
`;
const SearchInput = styled.input`
  border: none;
  width: 100%;
  outline: none;
  &::placeholder {
    color: #c1c1c1;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    font-family: "ubuntu-regular";

    @media (max-width: 580px) {
      padding-left: 10px;
    }
  }
`;
const Icon = styled.div`
  width: 80%;
  height: 80%;
  transition: 0.4s ease;
  color: #575657;
  &.active {
    transition: 0.4s ease;
    color: #006492;
  }
  img {
    display: block;
    width: 100%;
    margin: 0 auto;
    object-fit: cover;
    width: auto;
  }
`;

const BannerInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 85%;
  margin: 0 auto;
  margin-top: 5%;
  position: absolute;
  left: 6%;

  @media (max-width: 580px) {
    width: 80%;
    margin-top: 20%;
    gap: 20px;
  }

  @media (max-width: 425px) {
    width: 90%;
  }
`;

const Heading = styled.h2`
  color: #fff;
  font-size: 39px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (max-width: 1450px) {
    font-size: 32px;
  }

  @media (max-width: 1200px) {
    font-size: 28px;
  }

  @media (max-width: 1080px) {
    font-size: 25px;
  }
  @media (max-width: 900px) {
   font-size: 28px;
  }

  @media (max-width: 580px) {
    font-size: 24px;
  }
`;

const SubHeading = styled.p`
  color: #f8f8f8;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px;
`;

const CoverIcon = styled.div`
  height: 55px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #fff;
  @media all and (max-width: 768px) {
    height: 40px;
  }

  @media all and (max-width: 580px) {
    height: 100%;
  }
  img {
    height: 100%;
    width: auto;
    object-fit: cover;
  }
`;

const BottomLayer = styled.div`
  position: relative;
  inset: 0;
  background-color: #00aec0;
  height: 100%;
  width: 100%;
  z-index: -2;

  &::after {
    position: absolute;
    content: "";
    top: 0;
    height: 20%;
    opacity: 0.6;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.75) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    width: 100%;
  }
`;

const MiddleLayer = styled.div`
  position: relative;
  inset: 0;
  background-color: #161c35;
  height: 100%;
  width: 100%;
  z-index: -1;

  &.slide {
    background-color: #00aec0;
    animation: background-fade 0.7s linear;
  }

  @keyframes background-fade {
    0% {
      background: linear-gradient(
        90deg,
        rgba(0, 174, 192, 1) 0%,
        rgba(22, 28, 53, 1) 100%
      );
    }
    25% {
      background: linear-gradient(
        90deg,
        rgba(0, 174, 192, 1) 25%,
        rgba(22, 28, 53, 1) 75%
      );
    }
    50% {
      background: linear-gradient(
        90deg,
        rgba(0, 174, 192, 1) 50%,
        rgba(22, 28, 53, 1) 50%
      );
    }
    75% {
      background: linear-gradient(
        90deg,
        rgba(0, 174, 192, 1) 75%,
        rgba(22, 28, 53, 1) 25%
      );
    }
    100% {
      background: linear-gradient(
        90deg,
        rgba(0, 174, 192, 1) 100%,
        rgba(22, 28, 53, 1) 0%
      );
    }
  }
`;

const TopLayer = styled.div`
  width: 70%;
  height: 100%;
  transition: all 0.6s linear;
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  /* background-image: url(${img});
      background-repeat:no-repeat;
      background-size: cover; */
  transform: translateX(-25%);

  img {
    height: 100%;
    /* transform: skewX(150deg); */
  }

  &.slide {
    transform: translateX(75%);
  }

  .slick-slide{
    div:not(.item){
      height: 100% !important;
    }
  }

  @media screen and (max-width:900px){
  &.slide {
    transform: none;
  }
  transform:none;
  width: 100%;
  clip-path: none;
  }
`;

const ImageBlck = styled.div`
  display: block;
  height: 100%;
  width: 100%;
  /* background-image: url(${img}); */
  background-repeat: no-repeat;
  background-size: cover;
`;

const Item = styled.div`
  display: block !important;
  height: 470px;
  width: 100%;
  background-image: url(${(props)=>props?.img && props?.img});
  background-repeat: no-repeat;
  background-size: cover;
`;
